var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
const HlsJsPlayerLoader = {
  get: async () => {
    const { HlsJsPlayer } = await import("./hls.js");
    return HlsJsPlayer;
  },
  isSupported: (_) => true
};
var ArrayUtils;
((ArrayUtils2) => {
  function sortBy(arr, $fn = (s1, s2) => order2 * String(s1).localeCompare(String(s2)), order2 = 1) {
    const fn2 = $fn;
    return [...arr].sort(fn2);
  }
  ArrayUtils2.sortBy = sortBy;
  function remove(array, item) {
    const index = array.indexOf(item);
    if (index > -1) {
      return array.splice(index, 1)[0];
    } else {
      return null;
    }
  }
  ArrayUtils2.remove = remove;
})(ArrayUtils || (ArrayUtils = {}));
class BrowserUtils {
  static get isMobile() {
    if (!this.windowExists()) {
      return false;
    }
    return !!(navigator && navigator.userAgent && /Mobi/.test(navigator.userAgent));
  }
  static get isEdge() {
    return /edge/i.test(navigator.userAgent);
  }
  static get isIE() {
    return Boolean(window.ActiveXObject) || /trident.*rv:1\d/i.test(navigator.userAgent);
  }
  static get isChrome() {
    if (!this.windowExists()) {
      return false;
    }
    return !!(navigator && navigator.userAgent && /Chrome/.test(navigator.userAgent));
  }
  static get isAndroid() {
    if (!this.windowExists()) {
      return false;
    }
    return Boolean(navigator && navigator.userAgent && /Android/.test(navigator.userAgent));
  }
  static get isIOS() {
    if (!this.windowExists()) {
      return false;
    }
    return Boolean(navigator && navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent));
  }
  static get isTouchSupported() {
    if (!this.windowExists()) {
      return false;
    }
    return Boolean("ontouchstart" in window || navigator && navigator.userAgent && (navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0));
  }
  static get isIframe() {
    return window.location !== window.parent.location;
  }
  static windowExists() {
    return typeof window !== "undefined";
  }
}
var ConversionUtils;
((ConversionUtils2) => {
  ConversionUtils2.bpsToRoundedMbps = (bps, roundedTo = 2) => {
    const mbps = bps / Math.pow(10, 6);
    return Math.round(mbps * Math.pow(10, roundedTo)) / Math.pow(10, roundedTo);
  };
  ConversionUtils2.convertRemToPixels = (rem) => {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  };
  function timeDurationToPercent(currentTime, duration) {
    if (duration !== 0) {
      return currentTime / duration * 100;
    } else {
      return 0;
    }
  }
  ConversionUtils2.timeDurationToPercent = timeDurationToPercent;
  function secondsToHMS(seconds) {
    const pad = (num) => (10 ** 2 + Math.floor(num)).toString().substring(1);
    seconds = Math.round(seconds);
    let display = "";
    const h = Math.trunc(seconds / 3600) % 24;
    if (h) {
      display += `${pad(h)}:`;
    }
    const m2 = Math.trunc(seconds / 60) % 60;
    display += `${pad(m2)}:`;
    const s = Math.trunc(seconds % 60);
    display += `${pad(s)}`;
    return display;
  }
  ConversionUtils2.secondsToHMS = secondsToHMS;
})(ConversionUtils || (ConversionUtils = {}));
var FunctionUtils;
((FunctionUtils2) => {
  FunctionUtils2.defer = (fn2, ...args) => setTimeout(fn2, 1, ...args);
  FunctionUtils2.debounce = (fn2, ms) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        timeoutId = 0;
        fn2(...args);
      }, ms);
    };
  };
  function throttle(func, limit) {
    let inThrottle;
    let lastResult;
    return function() {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
        lastResult = func.apply(context, args);
      }
      return lastResult;
    };
  }
  FunctionUtils2.throttle = throttle;
})(FunctionUtils || (FunctionUtils = {}));
let lastTime;
function stringToColor(str) {
  let hash2 = 0;
  if (str.length === 0) {
    return hash2;
  }
  for (let i = 0; i < str.length; i++) {
    hash2 = str.charCodeAt(i) + ((hash2 << 5) - hash2);
    hash2 = hash2 & hash2;
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = hash2 >> i * 8 & 255;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}
function Logger(namespace) {
  const color = stringToColor(namespace);
  return (first, ...args) => {
    if (lastTime === void 0) {
      lastTime = performance.now();
    }
    const colorArgs = [`color: ${color}`, "color: orange", `color: ${color}`];
    const diff = Math.trunc(performance.now() - lastTime);
    lastTime = performance.now();
    console.log(`%c${namespace}%c ${first} %c${diff}ms`, ...colorArgs, ...args);
  };
}
var MimetypeUtils;
((MimetypeUtils2) => {
  function mimeTypeForUrl(url) {
    const extension = (url.split("?")[0].match(/.*\.(.*)$/) || [])[1];
    return MimetypeUtils2.MIMETYPES[extension.toLowerCase()] || [];
  }
  MimetypeUtils2.mimeTypeForUrl = mimeTypeForUrl;
  function playbackSupports(url, mimetype) {
    const lowerCaseMimeType = mimetype.map((m2) => m2.toLowerCase());
    return mimeTypeForUrl(url).some((mime) => lowerCaseMimeType.includes(mime));
  }
  MimetypeUtils2.playbackSupports = playbackSupports;
  function isHls(url) {
    return playbackSupports(url, [
      "application/vnd.apple.mpegurl",
      "application/x-mpegURL"
    ]);
  }
  MimetypeUtils2.isHls = isHls;
  function isDash(url) {
    return playbackSupports(url, ["application/dash+xml"]);
  }
  MimetypeUtils2.isDash = isDash;
  MimetypeUtils2.MIMETYPES = {
    mp4: [
      "avc1.42E01E",
      "avc1.58A01E",
      "avc1.4D401E",
      "avc1.64001E",
      "mp4v.20.8",
      "mp4v.20.240",
      "mp4a.40.2"
    ].map((codec) => {
      return 'video/mp4; codecs="' + codec + ', mp4a.40.2"';
    }),
    ogg: [
      'video/ogg; codecs="theora, vorbis"',
      'video/ogg; codecs="dirac"',
      'video/ogg; codecs="theora, speex"'
    ],
    ogv: [
      'video/ogg; codecs="theora, vorbis"',
      'video/ogg; codecs="dirac"',
      'video/ogg; codecs="theora, speex"'
    ],
    "3gpp": ['video/3gpp; codecs="mp4v.20.8, samr"'],
    "3gp": ['video/3gpp; codecs="mp4v.20.8, samr"'],
    webm: ['video/webm; codecs="vp8, vorbis"'],
    mkv: ['video/x-matroska; codecs="theora, vorbis"'],
    m3u8: ["application/x-mpegurl"],
    mpd: ["application/dash+xml"]
  };
  MimetypeUtils2.AUDIO_MIMETYPES = {
    wav: ["audio/wav"],
    mp3: ["audio/mp3", 'audio/mpeg;codecs="mp3"'],
    aac: ['audio/mp4;codecs="mp4a.40.5"'],
    oga: ["audio/ogg"]
  };
})(MimetypeUtils || (MimetypeUtils = {}));
var ObjectUtils;
((ObjectUtils2) => {
  ObjectUtils2.isObject = (item) => {
    return item === Object(item) && !Array.isArray(item);
  };
  ObjectUtils2.shallowEqual = (item, other) => {
    const keys1 = Object.keys(item);
    const keys2 = Object.keys(other);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (item[key] !== other[key]) {
        return false;
      }
    }
    return true;
  };
  ObjectUtils2.deepCopy = (target) => {
    if (target === null) {
      return target;
    }
    if (target instanceof Date) {
      return new Date(target.getTime());
    }
    if (typeof target === "object") {
      if (typeof target[Symbol.iterator] === "function") {
        const cp = [];
        if (target.length > 0) {
          for (const arrayMember of target) {
            cp.push(ObjectUtils2.deepCopy(arrayMember));
          }
        }
        return cp;
      } else {
        const targetKeys = Object.keys(target);
        const cp = {};
        if (targetKeys.length > 0) {
          for (const key of targetKeys) {
            cp[key] = ObjectUtils2.deepCopy(target[key]);
          }
        }
        return cp;
      }
    }
    return target;
  };
})(ObjectUtils || (ObjectUtils = {}));
const windowShim = {
  HTMLElement: function HTMLElement2() {
    return {
      addEventListener: () => {
      },
      removeEventListener: () => {
      },
      dispatchEvent: () => {
      }
    };
  },
  customElements: {
    get: () => {
    },
    define: () => {
    },
    whenDefined: () => {
    }
  },
  CustomEvent: () => {
  }
};
const isServer = typeof window === "undefined" || typeof window.customElements === "undefined";
const Window = isServer ? windowShim : window;
var StringUtils;
((StringUtils2) => {
  StringUtils2.isString = (str) => {
    return typeof str === "string";
  };
})(StringUtils || (StringUtils = {}));
function generateUUID() {
  const firstPart = Math.random() * 46656 | 0;
  const secondPart = Math.random() * 46656 | 0;
  const first = ("000" + firstPart.toString(36)).slice(-3);
  const second = ("000" + secondPart.toString(36)).slice(-3);
  return first + second;
}
class VxdkError extends Error {
  constructor(input, error) {
    super();
    __publicField(this, "code", 9999);
    __publicField(this, "underlyingError");
    if (StringUtils.isString(input)) {
      this.message = input;
    } else {
      this.code = input;
    }
    this.underlyingError = error;
  }
}
var PLAYBACK_EVENT = /* @__PURE__ */ ((PLAYBACK_EVENT2) => {
  PLAYBACK_EVENT2["LOADSTART"] = "playback.loadstart";
  PLAYBACK_EVENT2["LOADEDMETADATA"] = "playback.loadedmetadata";
  PLAYBACK_EVENT2["PLAY"] = "playback.play";
  PLAYBACK_EVENT2["PAUSE"] = "playback.pause";
  PLAYBACK_EVENT2["PLAYING"] = "playback.playing";
  PLAYBACK_EVENT2["ENDED"] = "playback.ended";
  PLAYBACK_EVENT2["TIMEUPDATE"] = "playback.timeupdate";
  PLAYBACK_EVENT2["DURATION_CHANGED"] = "playback.duration.changed";
  PLAYBACK_EVENT2["WAITING"] = "playback.waiting";
  PLAYBACK_EVENT2["DESTROY"] = "playback.destroy";
  PLAYBACK_EVENT2["ERROR"] = "playback.error";
  PLAYBACK_EVENT2["VOLUME_CHANGED"] = "playback.volume.changed";
  PLAYBACK_EVENT2["BUFFERED_CHANGED"] = "playback.buffered.changed";
  PLAYBACK_EVENT2["PLAYBACKRATE_CHANGE"] = "playback.playbackrate.change";
  PLAYBACK_EVENT2["AUTOLEVEL_CHANGED"] = "playback.autoLevel.changed";
  PLAYBACK_EVENT2["LEVELS_LOADED"] = "playback.levels.loaded";
  PLAYBACK_EVENT2["LEVEL_CHANGED"] = "playback.level.changed";
  return PLAYBACK_EVENT2;
})(PLAYBACK_EVENT || {});
var CONTEXT_EVENT = /* @__PURE__ */ ((CONTEXT_EVENT2) => {
  CONTEXT_EVENT2["INITIALIZING"] = "context.initializing";
  CONTEXT_EVENT2["PLUGINS_LOADED"] = "context.plugins.loaded";
  CONTEXT_EVENT2["PLUGINS_CONNECTED"] = "context.plugins.connected";
  CONTEXT_EVENT2["PLUGINS_DISCONNECTED"] = "context.plugins.disconnected";
  CONTEXT_EVENT2["PLAYBACK_ADAPTER_LOADED"] = "context.playback.adapter.loaded";
  CONTEXT_EVENT2["STORAGE_LOADED"] = "context.storage.loaded";
  CONTEXT_EVENT2["READY"] = "context.ready";
  CONTEXT_EVENT2["DIMENSION_CHANGED"] = "context.dimension.changed";
  CONTEXT_EVENT2["FULLSCREEN_CHANGED"] = "context.fullscreen.changed";
  CONTEXT_EVENT2["PIP_CHANGED"] = "context.pip.changed";
  CONTEXT_EVENT2["DESTROY"] = "context.destroy";
  CONTEXT_EVENT2["STATE_CHANGED"] = "context.state.changed";
  CONTEXT_EVENT2["OPTIONS_CHANGED"] = "context.options.changed";
  return CONTEXT_EVENT2;
})(CONTEXT_EVENT || {});
var BRIDGE_EVENT = /* @__PURE__ */ ((BRIDGE_EVENT2) => {
  BRIDGE_EVENT2["PLAYBACK_CHANGED"] = "bridge.playback.changed";
  return BRIDGE_EVENT2;
})(BRIDGE_EVENT || {});
class Hook {
  constructor(module) {
    __publicField(this, "beforeHooks", []);
    __publicField(this, "afterHooks", []);
    __publicField(this, "origFunctions", {});
    __publicField(this, "runHookedFunction", (name) => (...args) => {
      const filterFn = (h) => h.name === name;
      const selectedBeforeHooks = this.beforeHooks.filter(filterFn);
      const selectedAfterHooks = this.afterHooks.filter(filterFn);
      let beforeIndex = -1;
      let afterIndex = -1;
      const runOrigFunction = () => this.origFunctions[name].call(this.module, ...args);
      const runAfterHook = () => {
        const hook = selectedAfterHooks[afterIndex += 1];
        if (!hook)
          return;
        hook.callback.call(null, ...args);
        runAfterHook();
      };
      const runBeforeHook = () => {
        const hook = selectedBeforeHooks[beforeIndex += 1];
        if (!hook) {
          runOrigFunction();
          runAfterHook();
          return;
        }
        let proceed = false;
        const next = () => proceed = true;
        hook.callback.call(null, next, ...args);
        if (proceed)
          runBeforeHook();
      };
      runBeforeHook();
    });
    this.module = module;
  }
  before(name, callback) {
    this.hookFunction(name);
    this.beforeHooks.push({
      name,
      callback
    });
  }
  removeBefore(name, callback) {
    this.beforeHooks = this.beforeHooks.filter((cb) => {
      return cb.name !== name || cb.callback !== callback;
    });
  }
  after(name, callback) {
    this.hookFunction(name);
    this.afterHooks.push({
      name,
      callback
    });
  }
  removeAfter(name, callback) {
    this.afterHooks = this.afterHooks.filter((cb) => {
      return cb.name !== name || cb.callback !== callback;
    });
  }
  hookFunction(name) {
    if (typeof this.module[name] !== "function") {
      throw new Error(`The method "${name}" does not exist in ${this}`);
    }
    if (this.origFunctions[name]) {
      return;
    }
    this.origFunctions[name] = this.module[name];
    this.module[name] = this.runHookedFunction(name);
  }
}
var PluginType = /* @__PURE__ */ ((PluginType2) => {
  PluginType2["PLUGIN"] = "PLUGIN";
  PluginType2["PLAYBACK"] = "PLAYBACK";
  PluginType2["UI_PLUGIN"] = "UI_PLUGIN";
  return PluginType2;
})(PluginType || {});
class Module {
  constructor() {
    __publicField(this, "hooks", new Hook(this));
  }
  connectedCallback() {
  }
  disconnectedCallback() {
  }
  get logger() {
    return Logger(this.name);
  }
}
class Plugin extends Module {
  constructor(ctx) {
    super();
    __publicField(this, "type", "PLUGIN");
    __publicField(this, "getConfig", () => {
      var _a;
      return (_a = this.ctx.options.plugins[this.name]) != null ? _a : {};
    });
    this.ctx = ctx;
    this.registerEvents();
  }
  get storage() {
    return this.ctx.storage;
  }
  get controller() {
    return this.ctx.controller;
  }
  get bridge() {
    return this.ctx.bridge;
  }
  get on() {
    return this.ctx.on.bind(this.ctx);
  }
  get off() {
    return this.ctx.off.bind(this.ctx);
  }
  get once() {
    return this.ctx.once.bind(this.ctx);
  }
  get events() {
    return [];
  }
  registerEvents() {
    this.ctx.registerEvents.bind(this.ctx)(this.events);
  }
  get emit() {
    return this.ctx.emit.bind(this.ctx);
  }
  get emitIfChanged() {
    return this.ctx.emitIfChanged.bind(this.ctx);
  }
}
var PlaybackType = /* @__PURE__ */ ((PlaybackType2) => {
  PlaybackType2[PlaybackType2["VOD"] = 0] = "VOD";
  PlaybackType2[PlaybackType2["LIVE"] = 1] = "LIVE";
  return PlaybackType2;
})(PlaybackType || {});
class Playback extends Plugin {
  constructor() {
    super(...arguments);
    __publicField(this, "type", PluginType.PLAYBACK);
  }
  static canPlay(_) {
    console.error(`${this.constructor.name}.canPlay is not implemented`);
    return false;
  }
}
class HTML5Player extends Playback {
  constructor(ctx) {
    super(ctx);
    __publicField(this, "name", "HTML5Player");
    __publicField(this, "_bufferedPercentage", 0);
    __publicField(this, "playbackType", PlaybackType.VOD);
    __publicField(this, "_nativeEl");
    this._nativeEl = document.createElement("video");
    this.controller.getRootElement().appendChild(this._nativeEl);
    const options = this.controller.getOptions();
    this._nativeEl.src = options.source;
    this._nativeEl.crossOrigin = options.crossOrigin;
    this._nativeEl.playsInline = options.playsInline;
    this._nativeEl.muted = options.muted;
    this._nativeEl.volume = options.muted ? 0 : options.volume;
    this._nativeEl.preload = options.preload;
    this._nativeEl.controls = options.nativeControls;
  }
  load(startTime) {
    this.setSource(this.controller.getOptions().source);
    if (startTime) {
      this._nativeEl.currentTime = startTime;
    }
    this._nativeEl.load();
  }
  connectedCallback() {
    this._nativeEl.addEventListener("playing", () => {
      this.emit(PLAYBACK_EVENT.PLAYING);
    });
    this._nativeEl.addEventListener("ended", () => {
      this.emit(PLAYBACK_EVENT.ENDED);
    });
    this._nativeEl.addEventListener("waiting", () => {
      this.emit(PLAYBACK_EVENT.WAITING);
    });
    this._nativeEl.addEventListener("pause", () => {
      this.emit(PLAYBACK_EVENT.PAUSE);
    });
    this._nativeEl.addEventListener("volumechange", () => {
      this.emit(PLAYBACK_EVENT.VOLUME_CHANGED);
    });
    this._nativeEl.addEventListener("loadeddata", () => {
      this._monitorProgress();
      this.emit(PLAYBACK_EVENT.LOADEDMETADATA);
      this.emit(PLAYBACK_EVENT.DURATION_CHANGED);
    });
    this._nativeEl.addEventListener("loadstart", () => {
      this.emit(PLAYBACK_EVENT.LOADSTART);
    });
    this._nativeEl.addEventListener("progress", () => this._monitorProgress());
    this._nativeEl.addEventListener("ratechange", () => {
      this.emit(PLAYBACK_EVENT.PLAYBACKRATE_CHANGE);
    });
    this._nativeEl.addEventListener("timeupdate", () => {
      this.emit(PLAYBACK_EVENT.TIMEUPDATE);
    });
    this._nativeEl.addEventListener("loadedmetadata", () => {
      this.emit(PLAYBACK_EVENT.DURATION_CHANGED);
    });
  }
  disconnectedCallback() {
    if (this._nativeEl) {
      this._nativeEl.pause();
      this._nativeEl.removeAttribute("src");
      this._nativeEl.remove();
    }
  }
  get nativeEl() {
    return this._nativeEl;
  }
  setSource(src) {
    this._nativeEl.src = src;
  }
  play() {
    this.emit(PLAYBACK_EVENT.PLAY);
    return this._nativeEl.play();
  }
  replay() {
    this.seekTo(0);
    return this.play();
  }
  pause() {
    this.emit(PLAYBACK_EVENT.PAUSE);
    this._nativeEl.pause();
  }
  stop() {
    this.pause();
    this._nativeEl.removeAttribute("src");
    this._nativeEl.load();
  }
  get autoLevelEnabled() {
    return false;
  }
  seekTo(time2) {
    this._nativeEl.currentTime = time2;
  }
  seekPercentage(percentage) {
    const time2 = this._nativeEl.duration * (percentage / 100);
    this.seekTo(time2);
  }
  get volume() {
    return this._nativeEl.volume;
  }
  setVolume(volume) {
    this._nativeEl.volume = volume;
    this._nativeEl.muted = volume === 0;
  }
  get duration() {
    return this._nativeEl.duration;
  }
  get isLive() {
    return this.playbackType === PlaybackType.LIVE;
  }
  get isMuted() {
    return this._nativeEl.muted;
  }
  mute() {
    this._nativeEl.muted = true;
  }
  unmute() {
    this._nativeEl.muted = false;
  }
  get playbackRate() {
    return this._nativeEl.playbackRate;
  }
  setPlaybackRate(playbackRate) {
    this._nativeEl.playbackRate = playbackRate;
  }
  get currentTime() {
    return this._nativeEl.currentTime;
  }
  get bufferedPercentage() {
    return this._bufferedPercentage;
  }
  get isPlaying() {
    return !this._nativeEl.paused && !this._nativeEl.ended;
  }
  get hasEnded() {
    return this._nativeEl.ended;
  }
  _monitorProgress() {
    const buffered = this._nativeEl.buffered;
    const time2 = this._nativeEl.currentTime;
    let percentage;
    for (let range2 = 0; range2 < buffered.length; range2 += 1) {
      if (buffered.start(range2) <= time2 && buffered.end(range2) > time2) {
        percentage = buffered.end(range2) / this._nativeEl.duration;
        if (percentage !== void 0) {
          this._bufferedPercentage = percentage;
          this.emit(PLAYBACK_EVENT.BUFFERED_CHANGED);
        }
        break;
      }
    }
  }
  get currentLevel() {
    throw new VxdkError("NOT IMPLEMENTED ");
  }
  get levels() {
    console.error("NOT IMPLEMENTED ");
    return [];
  }
  selectLevel(_) {
    console.error("NOT IMPLEMENTED ");
  }
  selectAudioLanguage(_) {
    console.error("NOT IMPLEMENTED ");
  }
  syncWithLive() {
    console.error("NOT IMPLEMENTED ");
  }
  static canPlay(source) {
    const mimeTypes = MimetypeUtils.mimeTypeForUrl(source);
    const media = document.createElement("video");
    return !!mimeTypes.filter((mediaType) => !!media.canPlayType(mediaType).replace(/no/, ""))[0];
  }
}
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
}
var loadjs_umd = { exports: {} };
(function(module, exports) {
  (function(root, factory) {
    {
      module.exports = factory();
    }
  })(commonjsGlobal, function() {
    var devnull = function() {
    }, bundleIdCache = {}, bundleResultCache = {}, bundleCallbackQueue = {};
    function subscribe(bundleIds, callbackFn) {
      bundleIds = bundleIds.push ? bundleIds : [bundleIds];
      var depsNotFound = [], i = bundleIds.length, numWaiting = i, fn2, bundleId, r, q;
      fn2 = function(bundleId2, pathsNotFound) {
        if (pathsNotFound.length)
          depsNotFound.push(bundleId2);
        numWaiting--;
        if (!numWaiting)
          callbackFn(depsNotFound);
      };
      while (i--) {
        bundleId = bundleIds[i];
        r = bundleResultCache[bundleId];
        if (r) {
          fn2(bundleId, r);
          continue;
        }
        q = bundleCallbackQueue[bundleId] = bundleCallbackQueue[bundleId] || [];
        q.push(fn2);
      }
    }
    function publish(bundleId, pathsNotFound) {
      if (!bundleId)
        return;
      var q = bundleCallbackQueue[bundleId];
      bundleResultCache[bundleId] = pathsNotFound;
      if (!q)
        return;
      while (q.length) {
        q[0](bundleId, pathsNotFound);
        q.splice(0, 1);
      }
    }
    function executeCallbacks(args, depsNotFound) {
      if (args.call)
        args = { success: args };
      if (depsNotFound.length)
        (args.error || devnull)(depsNotFound);
      else
        (args.success || devnull)(args);
    }
    function loadFile(path, callbackFn, args, numTries) {
      var doc = document, async = args.async, maxTries = (args.numRetries || 0) + 1, beforeCallbackFn = args.before || devnull, pathname = path.replace(/[\?|#].*$/, ""), pathStripped = path.replace(/^(css|img)!/, ""), isLegacyIECss, e;
      numTries = numTries || 0;
      if (/(^css!|\.css$)/.test(pathname)) {
        e = doc.createElement("link");
        e.rel = "stylesheet";
        e.href = pathStripped;
        isLegacyIECss = "hideFocus" in e;
        if (isLegacyIECss && e.relList) {
          isLegacyIECss = 0;
          e.rel = "preload";
          e.as = "style";
        }
      } else if (/(^img!|\.(png|gif|jpg|svg|webp)$)/.test(pathname)) {
        e = doc.createElement("img");
        e.src = pathStripped;
      } else {
        e = doc.createElement("script");
        e.src = path;
        e.async = async === void 0 ? true : async;
      }
      e.onload = e.onerror = e.onbeforeload = function(ev) {
        var result = ev.type[0];
        if (isLegacyIECss) {
          try {
            if (!e.sheet.cssText.length)
              result = "e";
          } catch (x) {
            if (x.code != 18)
              result = "e";
          }
        }
        if (result == "e") {
          numTries += 1;
          if (numTries < maxTries) {
            return loadFile(path, callbackFn, args, numTries);
          }
        } else if (e.rel == "preload" && e.as == "style") {
          return e.rel = "stylesheet";
        }
        callbackFn(path, result, ev.defaultPrevented);
      };
      if (beforeCallbackFn(path, e) !== false)
        doc.head.appendChild(e);
    }
    function loadFiles(paths, callbackFn, args) {
      paths = paths.push ? paths : [paths];
      var numWaiting = paths.length, x = numWaiting, pathsNotFound = [], fn2, i;
      fn2 = function(path, result, defaultPrevented) {
        if (result == "e")
          pathsNotFound.push(path);
        if (result == "b") {
          if (defaultPrevented)
            pathsNotFound.push(path);
          else
            return;
        }
        numWaiting--;
        if (!numWaiting)
          callbackFn(pathsNotFound);
      };
      for (i = 0; i < x; i++)
        loadFile(paths[i], fn2, args);
    }
    function loadjs2(paths, arg1, arg2) {
      var bundleId, args;
      if (arg1 && arg1.trim)
        bundleId = arg1;
      args = (bundleId ? arg2 : arg1) || {};
      if (bundleId) {
        if (bundleId in bundleIdCache) {
          throw "LoadJS";
        } else {
          bundleIdCache[bundleId] = true;
        }
      }
      function loadFn(resolve, reject) {
        loadFiles(paths, function(pathsNotFound) {
          executeCallbacks(args, pathsNotFound);
          if (resolve) {
            executeCallbacks({ success: resolve, error: reject }, pathsNotFound);
          }
          publish(bundleId, pathsNotFound);
        }, args);
      }
      if (args.returnPromise)
        return new Promise(loadFn);
      else
        loadFn();
    }
    loadjs2.ready = function ready(deps, args) {
      subscribe(deps, function(depsNotFound) {
        executeCallbacks(args, depsNotFound);
      });
      return loadjs2;
    };
    loadjs2.done = function done(bundleId) {
      publish(bundleId, []);
    };
    loadjs2.reset = function reset() {
      bundleIdCache = {};
      bundleResultCache = {};
      bundleCallbackQueue = {};
    };
    loadjs2.isDefined = function isDefined(bundleId) {
      return bundleId in bundleIdCache;
    };
    return loadjs2;
  });
})(loadjs_umd);
var loadjs = loadjs_umd.exports;
async function waitUntil(condition) {
  return new Promise((resolve) => {
    if (condition()) {
      resolve(condition());
    } else {
      setTimeout(() => {
        resolve(waitUntil(condition));
      }, 50);
    }
  });
}
async function eventPromise(eventEmitter, eventType) {
  return new Promise((resolve) => {
    eventEmitter.addEventListener(eventType, resolve);
  });
}
const iframeContainer = "_iframeContainer_1d9us_1";
var youtube_module = {
  iframeContainer
};
function parseId(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}
const defaultYoutubeOptions = {
  width: "640px",
  height: "360px",
  playerVars: {
    autoplay: 0,
    controls: 0,
    showinfo: 0,
    modestbranding: 1,
    iv_load_policy: 3,
    autohide: 1,
    rel: 0,
    playsinline: 1,
    disablekb: 1,
    origin: window.location.origin
  }
};
class YoutubePlayer extends Playback {
  constructor(ctx) {
    super(ctx);
    __publicField(this, "name", "YoutubePlayer");
    __publicField(this, "$iframeContainer");
    __publicField(this, "playbackType", PlaybackType.VOD);
    __publicField(this, "_onReadyQueue", []);
    __publicField(this, "_ytPlayerHolder", null);
    __publicField(this, "_ready", false);
    __publicField(this, "_timeUpdateInterval", null);
    const playerContainer = document.createElement("div");
    playerContainer.setAttribute("id", "iframeContainer");
    this.controller.getRootElement().appendChild(playerContainer);
    this.$iframeContainer = playerContainer;
  }
  load(startTime) {
    const videoId = parseId(this.controller.getOptions().source);
    if (!videoId) {
      throw new Error("Invalid youtube id");
    }
    this._ytPlayerHolder = new window.YT.Player(this.$iframeContainer, __spreadProps(__spreadValues({}, defaultYoutubeOptions), {
      videoId,
      playerVars: __spreadProps(__spreadValues({}, defaultYoutubeOptions.playerVars), {
        start: startTime
      })
    }));
    this.emit(PLAYBACK_EVENT.LOADSTART);
    eventPromise(this._ytPlayerHolder, "onReady").then(() => {
      console.log("ON READY");
      this._ready = true;
      this.emit(PLAYBACK_EVENT.DURATION_CHANGED);
      this.emit(PLAYBACK_EVENT.LEVELS_LOADED);
      this.emit(PLAYBACK_EVENT.VOLUME_CHANGED);
      this.bindEvents();
      this._onReadyQueue.forEach((fn2) => fn2());
      this._onReadyQueue = [];
    });
  }
  checkIfReadyOrRun(fn2) {
    if (this._ready)
      return fn2();
    this._onReadyQueue.push(fn2);
    return;
  }
  get nativeEl() {
    return this.$iframeContainer;
  }
  get ytPlayer() {
    if (!this._ytPlayerHolder) {
      throw new Error("Youtube player is not ready");
    } else {
      return this._ytPlayerHolder;
    }
  }
  bindEvents() {
    this.ytPlayer.addEventListener("onStateChange", (event) => {
      switch (event.data) {
        case YT.PlayerState.PLAYING:
          this.emit(PLAYBACK_EVENT.PLAYING);
          break;
        case YT.PlayerState.PAUSED:
          this.emit(PLAYBACK_EVENT.PAUSE);
          break;
        case YT.PlayerState.ENDED:
          this.emit(PLAYBACK_EVENT.ENDED);
          break;
        case YT.PlayerState.BUFFERING:
          this.emit(PLAYBACK_EVENT.WAITING);
          break;
      }
      this.ytPlayer.addEventListener("onPlaybackRateChange", (_) => {
        this.emit(PLAYBACK_EVENT.PLAYBACKRATE_CHANGE);
      });
      this.ytPlayer.addEventListener("onPlaybackQualityChange", (_) => {
        this.emit(PLAYBACK_EVENT.LEVEL_CHANGED);
      });
      this._timeUpdateInterval = setInterval(() => {
        this.emit(PLAYBACK_EVENT.TIMEUPDATE);
      }, 25);
    });
  }
  connectedCallback() {
  }
  disconnectedCallback() {
    if (this._ytPlayerHolder) {
      this._ytPlayerHolder.destroy();
    }
    if (this._timeUpdateInterval) {
      clearInterval(this._timeUpdateInterval);
    }
  }
  setSource(src, startTime) {
    this.checkIfReadyOrRun(() => {
      this.ytPlayer.loadVideoByUrl(src, startTime);
    });
  }
  play() {
    this.checkIfReadyOrRun(() => {
      this.emit(PLAYBACK_EVENT.PLAY);
      this.ytPlayer.playVideo();
    });
  }
  replay() {
    this.checkIfReadyOrRun(() => {
      this.seekTo(0);
      return this.play();
    });
  }
  pause() {
    this.checkIfReadyOrRun(() => {
      this.ytPlayer.pauseVideo();
    });
  }
  stop() {
    this.checkIfReadyOrRun(() => {
      this.ytPlayer.stopVideo();
    });
  }
  get autoLevelEnabled() {
    return false;
  }
  seekTo(time2) {
    this.checkIfReadyOrRun(() => {
      this.ytPlayer.seekTo(time2, true);
    });
  }
  seekPercentage(percentage) {
    this.checkIfReadyOrRun(() => {
      const time2 = this.ytPlayer.getDuration() * (percentage / 100);
      this.seekTo(time2);
    });
  }
  get volume() {
    if (!this._ready)
      return 0;
    return this.ytPlayer.getVolume() / 100;
  }
  setVolume(volume) {
    this.checkIfReadyOrRun(() => {
      this.ytPlayer.setVolume(volume * 100);
      this._triggerVolumeChanged();
    });
  }
  get duration() {
    if (!this._ready)
      return 0;
    return this.ytPlayer.getDuration();
  }
  get isLive() {
    return this.playbackType === PlaybackType.LIVE;
  }
  _triggerVolumeChanged() {
    setTimeout(() => {
      this.emit(PLAYBACK_EVENT.VOLUME_CHANGED);
    }, 50);
  }
  get isMuted() {
    if (!this._ready)
      return false;
    return this.ytPlayer.isMuted();
  }
  mute() {
    this.checkIfReadyOrRun(() => {
      this.ytPlayer.mute();
      this._triggerVolumeChanged();
    });
  }
  unmute() {
    this.checkIfReadyOrRun(() => {
      this.ytPlayer.unMute();
      this._triggerVolumeChanged();
    });
  }
  get playbackRate() {
    if (!this._ready)
      return 1;
    return this.ytPlayer.getPlaybackRate();
  }
  setPlaybackRate(playbackRate) {
    this.checkIfReadyOrRun(() => {
      this.ytPlayer.setPlaybackRate(playbackRate);
    });
  }
  get currentTime() {
    if (!this._ready)
      return 0;
    return this.ytPlayer.getCurrentTime();
  }
  get bufferedPercentage() {
    if (!this._ready)
      return 0;
    return this.ytPlayer.getVideoLoadedFraction() * 100;
  }
  get isPaused() {
    return !!([
      YT.PlayerState.UNSTARTED,
      YT.PlayerState.ENDED,
      YT.PlayerState.PAUSED,
      YT.PlayerState.CUED
    ].indexOf(this.ytPlayer.getPlayerState()) > YT.PlayerState.UNSTARTED);
  }
  get isPlaying() {
    return !this.isPaused;
  }
  get hasEnded() {
    return this.ytPlayer.getPlayerState() === YT.PlayerState.ENDED;
  }
  get currentLevel() {
    if (!this._ready)
      return {};
    const level = this.ytPlayer.getPlaybackQuality();
    return { id: 0, name: level, hd: this._checkLevelIsHD(level) };
  }
  _checkLevelIsHD(level) {
    if (level === "hd1080" || level === "hd720" || level === "highres") {
      return true;
    } else {
      return false;
    }
  }
  get levels() {
    const listLevels = this.ytPlayer.getAvailableQualityLevels();
    return listLevels.map((name, index) => {
      return { id: index, name, hd: this._checkLevelIsHD(name) };
    });
  }
  selectLevel(level) {
    const youtubeLevel = level === "auto" ? "default" : level.name;
    this.ytPlayer.setPlaybackQuality(youtubeLevel);
  }
  selectAudioLanguage(_) {
    console.error("NOT IMPLEMENTED ");
  }
  syncWithLive() {
    console.error("NOT IMPLEMENTED ");
  }
  static canPlay(source) {
    return parseId(source) ? true : false;
  }
}
const YoutubePlayerLoader = {
  get: async () => {
    await loadYoutubeAPI();
    return YoutubePlayer;
  },
  isSupported: ({ options }) => {
    return !!parseId(options.source);
  }
};
async function loadYoutubeAPI() {
  if (typeof YT === "undefined") {
    await loadjs("https://www.youtube.com/iframe_api", {
      returnPromise: true
    });
  }
  await waitUntil(() => window["YT"]);
  return;
}
function isHTML5Player(playback) {
  return playback.nativeEl instanceof HTMLVideoElement;
}
const sharedConfig = {};
const equalFn = (a, b) => a === b;
const $PROXY = Symbol("solid-proxy");
const signalOptions = {
  equals: equalFn
};
let runEffects = runQueue;
const NOTPENDING = {};
const STALE = 1;
const PENDING = 2;
const UNOWNED = {
  owned: null,
  cleanups: null,
  context: null,
  owner: null
};
var Owner = null;
let Transition = null;
let Listener = null;
let Pending = null;
let Updates = null;
let Effects = null;
let ExecCount = 0;
function createRoot(fn2, detachedOwner) {
  const listener = Listener, owner = Owner, root = fn2.length === 0 && true ? UNOWNED : {
    owned: null,
    cleanups: null,
    context: null,
    owner: detachedOwner || owner
  };
  Owner = root;
  Listener = null;
  try {
    return runUpdates(() => fn2(() => cleanNode(root)), true);
  } finally {
    Listener = listener;
    Owner = owner;
  }
}
function createSignal(value, options) {
  options = options ? Object.assign({}, signalOptions, options) : signalOptions;
  const s = {
    value,
    observers: null,
    observerSlots: null,
    pending: NOTPENDING,
    comparator: options.equals || void 0
  };
  const setter = (value2) => {
    if (typeof value2 === "function") {
      value2 = value2(s.pending !== NOTPENDING ? s.pending : s.value);
    }
    return writeSignal(s, value2);
  };
  return [readSignal.bind(s), setter];
}
function createComputed(fn2, value, options) {
  const c = createComputation(fn2, value, true, STALE);
  updateComputation(c);
}
function createRenderEffect(fn2, value, options) {
  const c = createComputation(fn2, value, false, STALE);
  updateComputation(c);
}
function createEffect(fn2, value, options) {
  runEffects = runUserEffects;
  const c = createComputation(fn2, value, false, STALE);
  c.user = true;
  Effects ? Effects.push(c) : queueMicrotask(() => updateComputation(c));
}
function createMemo(fn2, value, options) {
  options = options ? Object.assign({}, signalOptions, options) : signalOptions;
  const c = createComputation(fn2, value, true, 0);
  c.pending = NOTPENDING;
  c.observers = null;
  c.observerSlots = null;
  c.comparator = options.equals || void 0;
  updateComputation(c);
  return readSignal.bind(c);
}
function batch(fn2) {
  if (Pending)
    return fn2();
  let result;
  const q = Pending = [];
  try {
    result = fn2();
  } finally {
    Pending = null;
  }
  runUpdates(() => {
    for (let i = 0; i < q.length; i += 1) {
      const data = q[i];
      if (data.pending !== NOTPENDING) {
        const pending = data.pending;
        data.pending = NOTPENDING;
        writeSignal(data, pending);
      }
    }
  }, false);
  return result;
}
function untrack(fn2) {
  let result, listener = Listener;
  Listener = null;
  result = fn2();
  Listener = listener;
  return result;
}
function onMount(fn2) {
  createEffect(() => untrack(fn2));
}
function onCleanup(fn2) {
  if (Owner === null)
    ;
  else if (Owner.cleanups === null)
    Owner.cleanups = [fn2];
  else
    Owner.cleanups.push(fn2);
  return fn2;
}
function getListener() {
  return Listener;
}
function getOwner() {
  return Owner;
}
function runWithOwner(o, fn2) {
  const prev = Owner;
  Owner = o;
  try {
    return runUpdates(fn2, true);
  } finally {
    Owner = prev;
  }
}
function createContext(defaultValue) {
  const id = Symbol("context");
  return {
    id,
    Provider: createProvider(id),
    defaultValue
  };
}
function useContext(context) {
  let ctx;
  return (ctx = lookup(Owner, context.id)) !== void 0 ? ctx : context.defaultValue;
}
function children(fn2) {
  const children2 = createMemo(fn2);
  return createMemo(() => resolveChildren(children2()));
}
function readSignal() {
  const runningTransition = Transition;
  if (this.sources && (this.state || runningTransition)) {
    const updates = Updates;
    Updates = null;
    this.state === STALE || runningTransition ? updateComputation(this) : lookUpstream(this);
    Updates = updates;
  }
  if (Listener) {
    const sSlot = this.observers ? this.observers.length : 0;
    if (!Listener.sources) {
      Listener.sources = [this];
      Listener.sourceSlots = [sSlot];
    } else {
      Listener.sources.push(this);
      Listener.sourceSlots.push(sSlot);
    }
    if (!this.observers) {
      this.observers = [Listener];
      this.observerSlots = [Listener.sources.length - 1];
    } else {
      this.observers.push(Listener);
      this.observerSlots.push(Listener.sources.length - 1);
    }
  }
  return this.value;
}
function writeSignal(node, value, isComp) {
  if (Pending) {
    if (node.pending === NOTPENDING)
      Pending.push(node);
    node.pending = value;
    return value;
  }
  if (node.comparator) {
    if (node.comparator(node.value, value))
      return value;
  }
  let TransitionRunning = false;
  node.value = value;
  if (node.observers && node.observers.length) {
    runUpdates(() => {
      for (let i = 0; i < node.observers.length; i += 1) {
        const o = node.observers[i];
        if (TransitionRunning && Transition.disposed.has(o))
          ;
        if (TransitionRunning && !o.tState || !TransitionRunning && !o.state) {
          if (o.pure)
            Updates.push(o);
          else
            Effects.push(o);
          if (o.observers)
            markDownstream(o);
        }
        if (TransitionRunning)
          ;
        else
          o.state = STALE;
      }
      if (Updates.length > 1e6) {
        Updates = [];
        if (false)
          ;
        throw new Error();
      }
    }, false);
  }
  return value;
}
function updateComputation(node) {
  if (!node.fn)
    return;
  cleanNode(node);
  const owner = Owner, listener = Listener, time2 = ExecCount;
  Listener = Owner = node;
  runComputation(node, node.value, time2);
  Listener = listener;
  Owner = owner;
}
function runComputation(node, value, time2) {
  let nextValue;
  try {
    nextValue = node.fn(value);
  } catch (err) {
    handleError(err);
  }
  if (!node.updatedAt || node.updatedAt <= time2) {
    if (node.observers && node.observers.length) {
      writeSignal(node, nextValue);
    } else
      node.value = nextValue;
    node.updatedAt = time2;
  }
}
function createComputation(fn2, init, pure, state = STALE, options) {
  const c = {
    fn: fn2,
    state,
    updatedAt: null,
    owned: null,
    sources: null,
    sourceSlots: null,
    cleanups: null,
    value: init,
    owner: Owner,
    context: null,
    pure
  };
  if (Owner === null)
    ;
  else if (Owner !== UNOWNED) {
    {
      if (!Owner.owned)
        Owner.owned = [c];
      else
        Owner.owned.push(c);
    }
  }
  return c;
}
function runTop(node) {
  const runningTransition = Transition;
  if (node.state === 0 || runningTransition)
    return;
  if (node.state === PENDING || runningTransition)
    return lookUpstream(node);
  if (node.suspense && untrack(node.suspense.inFallback))
    return node.suspense.effects.push(node);
  const ancestors = [node];
  while ((node = node.owner) && (!node.updatedAt || node.updatedAt < ExecCount)) {
    if (node.state || runningTransition)
      ancestors.push(node);
  }
  for (let i = ancestors.length - 1; i >= 0; i--) {
    node = ancestors[i];
    if (node.state === STALE || runningTransition) {
      updateComputation(node);
    } else if (node.state === PENDING || runningTransition) {
      const updates = Updates;
      Updates = null;
      lookUpstream(node, ancestors[0]);
      Updates = updates;
    }
  }
}
function runUpdates(fn2, init) {
  if (Updates)
    return fn2();
  let wait = false;
  if (!init)
    Updates = [];
  if (Effects)
    wait = true;
  else
    Effects = [];
  ExecCount++;
  try {
    return fn2();
  } catch (err) {
    handleError(err);
  } finally {
    completeUpdates(wait);
  }
}
function completeUpdates(wait) {
  if (Updates) {
    runQueue(Updates);
    Updates = null;
  }
  if (wait)
    return;
  if (Effects.length)
    batch(() => {
      runEffects(Effects);
      Effects = null;
    });
  else {
    Effects = null;
  }
}
function runQueue(queue) {
  for (let i = 0; i < queue.length; i++)
    runTop(queue[i]);
}
function runUserEffects(queue) {
  let i, userLength = 0;
  for (i = 0; i < queue.length; i++) {
    const e = queue[i];
    if (!e.user)
      runTop(e);
    else
      queue[userLength++] = e;
  }
  const resume = queue.length;
  for (i = 0; i < userLength; i++)
    runTop(queue[i]);
  for (i = resume; i < queue.length; i++)
    runTop(queue[i]);
}
function lookUpstream(node, ignore) {
  const runningTransition = Transition;
  node.state = 0;
  for (let i = 0; i < node.sources.length; i += 1) {
    const source = node.sources[i];
    if (source.sources) {
      if (source.state === STALE || runningTransition) {
        if (source !== ignore)
          runTop(source);
      } else if (source.state === PENDING || runningTransition)
        lookUpstream(source, ignore);
    }
  }
}
function markDownstream(node) {
  const runningTransition = Transition;
  for (let i = 0; i < node.observers.length; i += 1) {
    const o = node.observers[i];
    if (!o.state || runningTransition) {
      o.state = PENDING;
      if (o.pure)
        Updates.push(o);
      else
        Effects.push(o);
      o.observers && markDownstream(o);
    }
  }
}
function cleanNode(node) {
  let i;
  if (node.sources) {
    while (node.sources.length) {
      const source = node.sources.pop(), index = node.sourceSlots.pop(), obs = source.observers;
      if (obs && obs.length) {
        const n = obs.pop(), s = source.observerSlots.pop();
        if (index < obs.length) {
          n.sourceSlots[s] = index;
          obs[index] = n;
          source.observerSlots[index] = s;
        }
      }
    }
  }
  if (node.owned) {
    for (i = 0; i < node.owned.length; i++)
      cleanNode(node.owned[i]);
    node.owned = null;
  }
  if (node.cleanups) {
    for (i = 0; i < node.cleanups.length; i++)
      node.cleanups[i]();
    node.cleanups = null;
  }
  node.state = 0;
  node.context = null;
}
function handleError(err) {
  throw err;
}
function lookup(owner, key) {
  return owner ? owner.context && owner.context[key] !== void 0 ? owner.context[key] : lookup(owner.owner, key) : void 0;
}
function resolveChildren(children2) {
  if (typeof children2 === "function" && !children2.length)
    return resolveChildren(children2());
  if (Array.isArray(children2)) {
    const results = [];
    for (let i = 0; i < children2.length; i++) {
      const result = resolveChildren(children2[i]);
      Array.isArray(result) ? results.push.apply(results, result) : results.push(result);
    }
    return results;
  }
  return children2;
}
function createProvider(id) {
  return function provider(props) {
    let res;
    createComputed(() => res = untrack(() => {
      Owner.context = {
        [id]: props.value
      };
      return children(() => props.children);
    }));
    return res;
  };
}
const FALLBACK = Symbol("fallback");
function dispose(d2) {
  for (let i = 0; i < d2.length; i++)
    d2[i]();
}
function mapArray(list, mapFn, options = {}) {
  let items = [], mapped = [], disposers = [], len = 0, indexes = mapFn.length > 1 ? [] : null;
  onCleanup(() => dispose(disposers));
  return () => {
    let newItems = list() || [], i, j;
    return untrack(() => {
      let newLen = newItems.length, newIndices, newIndicesNext, temp, tempdisposers, tempIndexes, start2, end2, newEnd, item;
      if (newLen === 0) {
        if (len !== 0) {
          dispose(disposers);
          disposers = [];
          items = [];
          mapped = [];
          len = 0;
          indexes && (indexes = []);
        }
        if (options.fallback) {
          items = [FALLBACK];
          mapped[0] = createRoot((disposer) => {
            disposers[0] = disposer;
            return options.fallback();
          });
          len = 1;
        }
      } else if (len === 0) {
        mapped = new Array(newLen);
        for (j = 0; j < newLen; j++) {
          items[j] = newItems[j];
          mapped[j] = createRoot(mapper);
        }
        len = newLen;
      } else {
        temp = new Array(newLen);
        tempdisposers = new Array(newLen);
        indexes && (tempIndexes = new Array(newLen));
        for (start2 = 0, end2 = Math.min(len, newLen); start2 < end2 && items[start2] === newItems[start2]; start2++)
          ;
        for (end2 = len - 1, newEnd = newLen - 1; end2 >= start2 && newEnd >= start2 && items[end2] === newItems[newEnd]; end2--, newEnd--) {
          temp[newEnd] = mapped[end2];
          tempdisposers[newEnd] = disposers[end2];
          indexes && (tempIndexes[newEnd] = indexes[end2]);
        }
        newIndices = /* @__PURE__ */ new Map();
        newIndicesNext = new Array(newEnd + 1);
        for (j = newEnd; j >= start2; j--) {
          item = newItems[j];
          i = newIndices.get(item);
          newIndicesNext[j] = i === void 0 ? -1 : i;
          newIndices.set(item, j);
        }
        for (i = start2; i <= end2; i++) {
          item = items[i];
          j = newIndices.get(item);
          if (j !== void 0 && j !== -1) {
            temp[j] = mapped[i];
            tempdisposers[j] = disposers[i];
            indexes && (tempIndexes[j] = indexes[i]);
            j = newIndicesNext[j];
            newIndices.set(item, j);
          } else
            disposers[i]();
        }
        for (j = start2; j < newLen; j++) {
          if (j in temp) {
            mapped[j] = temp[j];
            disposers[j] = tempdisposers[j];
            if (indexes) {
              indexes[j] = tempIndexes[j];
              indexes[j](j);
            }
          } else
            mapped[j] = createRoot(mapper);
        }
        mapped = mapped.slice(0, len = newLen);
        items = newItems.slice(0);
      }
      return mapped;
    });
    function mapper(disposer) {
      disposers[j] = disposer;
      if (indexes) {
        const [s, set] = createSignal(j);
        indexes[j] = set;
        return mapFn(newItems[j], s);
      }
      return mapFn(newItems[j]);
    }
  };
}
function createComponent(Comp, props) {
  return untrack(() => Comp(props));
}
function trueFn() {
  return true;
}
const propTraps = {
  get(_, property, receiver) {
    if (property === $PROXY)
      return receiver;
    return _.get(property);
  },
  has(_, property) {
    return _.has(property);
  },
  set: trueFn,
  deleteProperty: trueFn,
  getOwnPropertyDescriptor(_, property) {
    return {
      configurable: true,
      enumerable: true,
      get() {
        return _.get(property);
      },
      set: trueFn,
      deleteProperty: trueFn
    };
  },
  ownKeys(_) {
    return _.keys();
  }
};
function resolveSource(s) {
  return typeof s === "function" ? s() : s;
}
function mergeProps(...sources) {
  return new Proxy({
    get(property) {
      for (let i = sources.length - 1; i >= 0; i--) {
        const v = resolveSource(sources[i])[property];
        if (v !== void 0)
          return v;
      }
    },
    has(property) {
      for (let i = sources.length - 1; i >= 0; i--) {
        if (property in resolveSource(sources[i]))
          return true;
      }
      return false;
    },
    keys() {
      const keys = [];
      for (let i = 0; i < sources.length; i++)
        keys.push(...Object.keys(resolveSource(sources[i])));
      return [...new Set(keys)];
    }
  }, propTraps);
}
function For(props) {
  const fallback = "fallback" in props && {
    fallback: () => props.fallback
  };
  return createMemo(mapArray(() => props.each, props.children, fallback ? fallback : void 0));
}
function Show(props) {
  let strictEqual = false;
  const condition = createMemo(() => props.when, void 0, {
    equals: (a, b) => strictEqual ? a === b : !a === !b
  });
  return createMemo(() => {
    const c = condition();
    if (c) {
      const child = props.children;
      return (strictEqual = typeof child === "function" && child.length > 0) ? untrack(() => child(c)) : child;
    }
    return props.fallback;
  });
}
function Switch(props) {
  let strictEqual = false;
  const conditions = children(() => props.children), evalConditions = createMemo(() => {
    let conds = conditions();
    if (!Array.isArray(conds))
      conds = [conds];
    for (let i = 0; i < conds.length; i++) {
      const c = conds[i].when;
      if (c)
        return [i, c, conds[i]];
    }
    return [-1];
  }, void 0, {
    equals: (a, b) => a[0] === b[0] && (strictEqual ? a[1] === b[1] : !a[1] === !b[1]) && a[2] === b[2]
  });
  return createMemo(() => {
    const [index, when, cond] = evalConditions();
    if (index < 0)
      return props.fallback;
    const c = cond.children;
    return (strictEqual = typeof c === "function" && c.length > 0) ? untrack(() => c(when)) : c;
  });
}
function Match(props) {
  return props;
}
const booleans = ["allowfullscreen", "async", "autofocus", "autoplay", "checked", "controls", "default", "disabled", "formnovalidate", "hidden", "indeterminate", "ismap", "loop", "multiple", "muted", "nomodule", "novalidate", "open", "playsinline", "readonly", "required", "reversed", "seamless", "selected"];
const Properties = /* @__PURE__ */ new Set(["className", "value", "readOnly", "formNoValidate", "isMap", "noModule", "playsInline", ...booleans]);
const ChildProperties = /* @__PURE__ */ new Set(["innerHTML", "textContent", "innerText", "children"]);
const Aliases = {
  className: "class",
  htmlFor: "for"
};
const PropAliases = {
  class: "className",
  formnovalidate: "formNoValidate",
  ismap: "isMap",
  nomodule: "noModule",
  playsinline: "playsInline",
  readonly: "readOnly"
};
const DelegatedEvents = /* @__PURE__ */ new Set(["beforeinput", "click", "dblclick", "contextmenu", "focusin", "focusout", "input", "keydown", "keyup", "mousedown", "mousemove", "mouseout", "mouseover", "mouseup", "pointerdown", "pointermove", "pointerout", "pointerover", "pointerup", "touchend", "touchmove", "touchstart"]);
const SVGNamespace = {
  xlink: "http://www.w3.org/1999/xlink",
  xml: "http://www.w3.org/XML/1998/namespace"
};
function memo(fn2, equals) {
  return createMemo(fn2, void 0, !equals ? {
    equals
  } : void 0);
}
function reconcileArrays(parentNode, a, b) {
  let bLength = b.length, aEnd = a.length, bEnd = bLength, aStart = 0, bStart = 0, after = a[aEnd - 1].nextSibling, map = null;
  while (aStart < aEnd || bStart < bEnd) {
    if (a[aStart] === b[bStart]) {
      aStart++;
      bStart++;
      continue;
    }
    while (a[aEnd - 1] === b[bEnd - 1]) {
      aEnd--;
      bEnd--;
    }
    if (aEnd === aStart) {
      const node = bEnd < bLength ? bStart ? b[bStart - 1].nextSibling : b[bEnd - bStart] : after;
      while (bStart < bEnd)
        parentNode.insertBefore(b[bStart++], node);
    } else if (bEnd === bStart) {
      while (aStart < aEnd) {
        if (!map || !map.has(a[aStart]))
          a[aStart].remove();
        aStart++;
      }
    } else if (a[aStart] === b[bEnd - 1] && b[bStart] === a[aEnd - 1]) {
      const node = a[--aEnd].nextSibling;
      parentNode.insertBefore(b[bStart++], a[aStart++].nextSibling);
      parentNode.insertBefore(b[--bEnd], node);
      a[aEnd] = b[bEnd];
    } else {
      if (!map) {
        map = /* @__PURE__ */ new Map();
        let i = bStart;
        while (i < bEnd)
          map.set(b[i], i++);
      }
      const index = map.get(a[aStart]);
      if (index != null) {
        if (bStart < index && index < bEnd) {
          let i = aStart, sequence = 1, t;
          while (++i < aEnd && i < bEnd) {
            if ((t = map.get(a[i])) == null || t !== index + sequence)
              break;
            sequence++;
          }
          if (sequence > index - bStart) {
            const node = a[aStart];
            while (bStart < index)
              parentNode.insertBefore(b[bStart++], node);
          } else
            parentNode.replaceChild(b[bStart++], a[aStart++]);
        } else
          aStart++;
      } else
        a[aStart++].remove();
    }
  }
}
const $$EVENTS = "_$DX_DELEGATE";
function render(code, element, init) {
  let disposer;
  createRoot((dispose2) => {
    disposer = dispose2;
    element === document ? code() : insert(element, code(), element.firstChild ? null : void 0, init);
  });
  return () => {
    disposer();
    element.textContent = "";
  };
}
function template$1(html, check, isSVG) {
  const t = document.createElement("template");
  t.innerHTML = html;
  let node = t.content.firstChild;
  if (isSVG)
    node = node.firstChild;
  return node;
}
function delegateEvents(eventNames, document2 = window.document) {
  const e = document2[$$EVENTS] || (document2[$$EVENTS] = /* @__PURE__ */ new Set());
  for (let i = 0, l2 = eventNames.length; i < l2; i++) {
    const name = eventNames[i];
    if (!e.has(name)) {
      e.add(name);
      document2.addEventListener(name, eventHandler);
    }
  }
}
function setAttribute(node, name, value) {
  if (value == null)
    node.removeAttribute(name);
  else
    node.setAttribute(name, value);
}
function setAttributeNS(node, namespace, name, value) {
  if (value == null)
    node.removeAttributeNS(namespace, name);
  else
    node.setAttributeNS(namespace, name, value);
}
function addEventListener(node, name, handler, delegate) {
  if (delegate) {
    if (Array.isArray(handler)) {
      node[`$$${name}`] = handler[0];
      node[`$$${name}Data`] = handler[1];
    } else
      node[`$$${name}`] = handler;
  } else if (Array.isArray(handler)) {
    node.addEventListener(name, (e) => handler[0](handler[1], e));
  } else
    node.addEventListener(name, handler);
}
function classList(node, value, prev = {}) {
  const classKeys = Object.keys(value || {}), prevKeys = Object.keys(prev);
  let i, len;
  for (i = 0, len = prevKeys.length; i < len; i++) {
    const key = prevKeys[i];
    if (!key || key === "undefined" || value[key])
      continue;
    toggleClassKey(node, key, false);
    delete prev[key];
  }
  for (i = 0, len = classKeys.length; i < len; i++) {
    const key = classKeys[i], classValue = !!value[key];
    if (!key || key === "undefined" || prev[key] === classValue || !classValue)
      continue;
    toggleClassKey(node, key, true);
    prev[key] = classValue;
  }
  return prev;
}
function style(node, value, prev = {}) {
  const nodeStyle = node.style;
  const prevString = typeof prev === "string";
  if (value == null && prevString || typeof value === "string")
    return nodeStyle.cssText = value;
  prevString && (nodeStyle.cssText = void 0, prev = {});
  value || (value = {});
  let v, s;
  for (s in prev) {
    value[s] == null && nodeStyle.removeProperty(s);
    delete prev[s];
  }
  for (s in value) {
    v = value[s];
    if (v !== prev[s]) {
      nodeStyle.setProperty(s, v);
      prev[s] = v;
    }
  }
  return prev;
}
function spread(node, accessor, isSVG, skipChildren) {
  if (typeof accessor === "function") {
    createRenderEffect((current) => spreadExpression(node, accessor(), current, isSVG, skipChildren));
  } else
    spreadExpression(node, accessor, void 0, isSVG, skipChildren);
}
function insert(parent, accessor, marker, initial) {
  if (marker !== void 0 && !initial)
    initial = [];
  if (typeof accessor !== "function")
    return insertExpression(parent, accessor, initial, marker);
  createRenderEffect((current) => insertExpression(parent, accessor(), current, marker), initial);
}
function assign(node, props, isSVG, skipChildren, prevProps = {}, skipRef = false) {
  props || (props = {});
  for (const prop in prevProps) {
    if (!(prop in props)) {
      if (prop === "children")
        continue;
      assignProp(node, prop, null, prevProps[prop], isSVG, skipRef);
    }
  }
  for (const prop in props) {
    if (prop === "children") {
      if (!skipChildren)
        insertExpression(node, props.children);
      continue;
    }
    const value = props[prop];
    prevProps[prop] = assignProp(node, prop, value, prevProps[prop], isSVG, skipRef);
  }
}
function toPropertyName(name) {
  return name.toLowerCase().replace(/-([a-z])/g, (_, w) => w.toUpperCase());
}
function toggleClassKey(node, key, value) {
  const classNames = key.trim().split(/\s+/);
  for (let i = 0, nameLen = classNames.length; i < nameLen; i++)
    node.classList.toggle(classNames[i], value);
}
function assignProp(node, prop, value, prev, isSVG, skipRef) {
  let isCE, isProp, isChildProp;
  if (prop === "style")
    return style(node, value, prev);
  if (prop === "classList")
    return classList(node, value, prev);
  if (value === prev)
    return prev;
  if (prop === "ref") {
    if (!skipRef) {
      value(node);
    }
  } else if (prop.slice(0, 3) === "on:") {
    node.addEventListener(prop.slice(3), value);
  } else if (prop.slice(0, 10) === "oncapture:") {
    node.addEventListener(prop.slice(10), value, true);
  } else if (prop.slice(0, 2) === "on") {
    const name = prop.slice(2).toLowerCase();
    const delegate = DelegatedEvents.has(name);
    addEventListener(node, name, value, delegate);
    delegate && delegateEvents([name]);
  } else if ((isChildProp = ChildProperties.has(prop)) || !isSVG && (PropAliases[prop] || (isProp = Properties.has(prop))) || (isCE = node.nodeName.includes("-"))) {
    if (isCE && !isProp && !isChildProp)
      node[toPropertyName(prop)] = value;
    else
      node[PropAliases[prop] || prop] = value;
  } else {
    const ns = isSVG && prop.indexOf(":") > -1 && SVGNamespace[prop.split(":")[0]];
    if (ns)
      setAttributeNS(node, ns, prop, value);
    else
      setAttribute(node, Aliases[prop] || prop, value);
  }
  return value;
}
function eventHandler(e) {
  const key = `$$${e.type}`;
  let node = e.composedPath && e.composedPath()[0] || e.target;
  if (e.target !== node) {
    Object.defineProperty(e, "target", {
      configurable: true,
      value: node
    });
  }
  Object.defineProperty(e, "currentTarget", {
    configurable: true,
    get() {
      return node || document;
    }
  });
  while (node !== null) {
    const handler = node[key];
    if (handler && !node.disabled) {
      const data = node[`${key}Data`];
      data !== void 0 ? handler(data, e) : handler(e);
      if (e.cancelBubble)
        return;
    }
    node = node.host && node.host !== node && node.host instanceof Node ? node.host : node.parentNode;
  }
}
function spreadExpression(node, props, prevProps = {}, isSVG, skipChildren) {
  props || (props = {});
  if (!skipChildren && "children" in props) {
    createRenderEffect(() => prevProps.children = insertExpression(node, props.children, prevProps.children));
  }
  props.ref && props.ref(node);
  createRenderEffect(() => assign(node, props, isSVG, true, prevProps, true));
  return prevProps;
}
function insertExpression(parent, value, current, marker, unwrapArray) {
  if (sharedConfig.context && !current)
    current = [...parent.childNodes];
  while (typeof current === "function")
    current = current();
  if (value === current)
    return current;
  const t = typeof value, multi = marker !== void 0;
  parent = multi && current[0] && current[0].parentNode || parent;
  if (t === "string" || t === "number") {
    if (sharedConfig.context)
      return current;
    if (t === "number")
      value = value.toString();
    if (multi) {
      let node = current[0];
      if (node && node.nodeType === 3) {
        node.data = value;
      } else
        node = document.createTextNode(value);
      current = cleanChildren(parent, current, marker, node);
    } else {
      if (current !== "" && typeof current === "string") {
        current = parent.firstChild.data = value;
      } else
        current = parent.textContent = value;
    }
  } else if (value == null || t === "boolean") {
    if (sharedConfig.context)
      return current;
    current = cleanChildren(parent, current, marker);
  } else if (t === "function") {
    createRenderEffect(() => {
      let v = value();
      while (typeof v === "function")
        v = v();
      current = insertExpression(parent, v, current, marker);
    });
    return () => current;
  } else if (Array.isArray(value)) {
    const array = [];
    if (normalizeIncomingArray(array, value, unwrapArray)) {
      createRenderEffect(() => current = insertExpression(parent, array, current, marker, true));
      return () => current;
    }
    if (sharedConfig.context) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].parentNode)
          return current = array;
      }
    }
    if (array.length === 0) {
      current = cleanChildren(parent, current, marker);
      if (multi)
        return current;
    } else if (Array.isArray(current)) {
      if (current.length === 0) {
        appendNodes(parent, array, marker);
      } else
        reconcileArrays(parent, current, array);
    } else {
      current && cleanChildren(parent);
      appendNodes(parent, array);
    }
    current = array;
  } else if (value instanceof Node) {
    if (sharedConfig.context && value.parentNode)
      return current = multi ? [value] : value;
    if (Array.isArray(current)) {
      if (multi)
        return current = cleanChildren(parent, current, marker, value);
      cleanChildren(parent, current, null, value);
    } else if (current == null || current === "" || !parent.firstChild) {
      parent.appendChild(value);
    } else
      parent.replaceChild(value, parent.firstChild);
    current = value;
  } else
    ;
  return current;
}
function normalizeIncomingArray(normalized, array, unwrap2) {
  let dynamic = false;
  for (let i = 0, len = array.length; i < len; i++) {
    let item = array[i], t;
    if (item instanceof Node) {
      normalized.push(item);
    } else if (item == null || item === true || item === false)
      ;
    else if (Array.isArray(item)) {
      dynamic = normalizeIncomingArray(normalized, item) || dynamic;
    } else if ((t = typeof item) === "string") {
      normalized.push(document.createTextNode(item));
    } else if (t === "function") {
      if (unwrap2) {
        while (typeof item === "function")
          item = item();
        dynamic = normalizeIncomingArray(normalized, Array.isArray(item) ? item : [item]) || dynamic;
      } else {
        normalized.push(item);
        dynamic = true;
      }
    } else
      normalized.push(document.createTextNode(item.toString()));
  }
  return dynamic;
}
function appendNodes(parent, array, marker) {
  for (let i = 0, len = array.length; i < len; i++)
    parent.insertBefore(array[i], marker);
}
function cleanChildren(parent, current, marker, replacement) {
  if (marker === void 0)
    return parent.textContent = "";
  const node = replacement || document.createTextNode("");
  if (current.length) {
    let inserted = false;
    for (let i = current.length - 1; i >= 0; i--) {
      const el = current[i];
      if (node !== el) {
        const isParent = el.parentNode === parent;
        if (!inserted && !i)
          isParent ? parent.replaceChild(node, el) : parent.insertBefore(node, marker);
        else
          isParent && el.remove();
      } else
        inserted = true;
    }
  } else
    parent.insertBefore(node, marker);
  return [node];
}
const SVG_NAMESPACE = "http://www.w3.org/2000/svg";
function createElement(tagName, isSVG = false) {
  return isSVG ? document.createElementNS(SVG_NAMESPACE, tagName) : document.createElement(tagName);
}
function Portal(props) {
  const {
    useShadow
  } = props, marker = document.createTextNode(""), mount = props.mount || document.body;
  function renderPortal() {
    if (sharedConfig.context) {
      const [s, set] = createSignal(false);
      queueMicrotask(() => set(true));
      return () => s() && props.children;
    } else
      return () => props.children;
  }
  if (mount instanceof HTMLHeadElement) {
    const [clean, setClean] = createSignal(false);
    const cleanup = () => setClean(true);
    createRoot((dispose2) => insert(mount, () => !clean() ? renderPortal()() : dispose2(), null));
    onCleanup(() => {
      if (sharedConfig.context)
        queueMicrotask(cleanup);
      else
        cleanup();
    });
  } else {
    const container2 = createElement(props.isSVG ? "g" : "div", props.isSVG), renderRoot = useShadow && container2.attachShadow ? container2.attachShadow({
      mode: "open"
    }) : container2;
    Object.defineProperty(container2, "host", {
      get() {
        return marker.parentNode;
      }
    });
    insert(renderRoot, renderPortal());
    mount.appendChild(container2);
    props.ref && props.ref(container2);
    onCleanup(() => mount.removeChild(container2));
  }
  return marker;
}
let UIPluginLocation;
(function(UIPluginLocation2) {
  UIPluginLocation2["CONTAINER"] = "CONTAINER";
  UIPluginLocation2["CONTROL_BAR"] = "CONTROL_BAR";
  UIPluginLocation2["PORTAL"] = "PORTAL";
})(UIPluginLocation || (UIPluginLocation = {}));
class UIPlugin extends Plugin {
  constructor() {
    super(...arguments);
    __publicField(this, "type", PluginType.UI_PLUGIN);
    __publicField(this, "order", 0);
    __publicField(this, "minSupportedVersion", "0.0.0");
  }
}
class UIContainerPlugin extends UIPlugin {
  constructor() {
    super(...arguments);
    __publicField(this, "renderLocation", UIPluginLocation.CONTAINER);
  }
}
class UIControlBarPlugin extends UIPlugin {
  constructor() {
    super(...arguments);
    __publicField(this, "renderLocation", UIPluginLocation.CONTROL_BAR);
  }
}
const $RAW = Symbol("store-raw"), $NODE = Symbol("store-node"), $NAME = Symbol("store-name");
function wrap$1(value, name) {
  let p2 = value[$PROXY];
  if (!p2) {
    Object.defineProperty(value, $PROXY, {
      value: p2 = new Proxy(value, proxyTraps$1)
    });
    const keys = Object.keys(value), desc = Object.getOwnPropertyDescriptors(value);
    for (let i = 0, l2 = keys.length; i < l2; i++) {
      const prop = keys[i];
      if (desc[prop].get) {
        const get = desc[prop].get.bind(p2);
        Object.defineProperty(value, prop, {
          get
        });
      }
    }
  }
  return p2;
}
function isWrappable(obj) {
  return obj != null && typeof obj === "object" && (obj[$PROXY] || !obj.__proto__ || obj.__proto__ === Object.prototype || Array.isArray(obj));
}
function unwrap(item, set = /* @__PURE__ */ new Set()) {
  let result, unwrapped, v, prop;
  if (result = item != null && item[$RAW])
    return result;
  if (!isWrappable(item) || set.has(item))
    return item;
  if (Array.isArray(item)) {
    if (Object.isFrozen(item))
      item = item.slice(0);
    else
      set.add(item);
    for (let i = 0, l2 = item.length; i < l2; i++) {
      v = item[i];
      if ((unwrapped = unwrap(v, set)) !== v)
        item[i] = unwrapped;
    }
  } else {
    if (Object.isFrozen(item))
      item = Object.assign({}, item);
    else
      set.add(item);
    const keys = Object.keys(item), desc = Object.getOwnPropertyDescriptors(item);
    for (let i = 0, l2 = keys.length; i < l2; i++) {
      prop = keys[i];
      if (desc[prop].get)
        continue;
      v = item[prop];
      if ((unwrapped = unwrap(v, set)) !== v)
        item[prop] = unwrapped;
    }
  }
  return item;
}
function getDataNodes(target) {
  let nodes = target[$NODE];
  if (!nodes)
    Object.defineProperty(target, $NODE, {
      value: nodes = {}
    });
  return nodes;
}
function proxyDescriptor(target, property) {
  const desc = Reflect.getOwnPropertyDescriptor(target, property);
  if (!desc || desc.get || !desc.configurable || property === $PROXY || property === $NODE || property === $NAME)
    return desc;
  delete desc.value;
  delete desc.writable;
  desc.get = () => target[$PROXY][property];
  return desc;
}
function ownKeys$1(target) {
  if (getListener()) {
    const nodes = getDataNodes(target);
    (nodes._ || (nodes._ = createDataNode()))();
  }
  return Reflect.ownKeys(target);
}
function createDataNode() {
  const [s, set] = createSignal(void 0, {
    equals: false,
    internal: true
  });
  s.$ = set;
  return s;
}
const proxyTraps$1 = {
  get(target, property, receiver) {
    if (property === $RAW)
      return target;
    if (property === $PROXY)
      return receiver;
    const value = target[property];
    if (property === $NODE || property === "__proto__")
      return value;
    const wrappable = isWrappable(value);
    if (getListener() && (typeof value !== "function" || target.hasOwnProperty(property))) {
      let nodes, node;
      if (wrappable && (nodes = getDataNodes(value))) {
        node = nodes._ || (nodes._ = createDataNode());
        node();
      }
      nodes = getDataNodes(target);
      node = nodes[property] || (nodes[property] = createDataNode());
      node();
    }
    return wrappable ? wrap$1(value) : value;
  },
  set() {
    return true;
  },
  deleteProperty() {
    return true;
  },
  ownKeys: ownKeys$1,
  getOwnPropertyDescriptor: proxyDescriptor
};
function setProperty(state, property, value) {
  if (state[property] === value)
    return;
  const array = Array.isArray(state);
  const len = state.length;
  const isUndefined = value === void 0;
  const notify = array || isUndefined === property in state;
  if (isUndefined) {
    delete state[property];
  } else
    state[property] = value;
  let nodes = getDataNodes(state), node;
  (node = nodes[property]) && node.$();
  if (array && state.length !== len)
    (node = nodes.length) && node.$();
  notify && (node = nodes._) && node.$();
}
function mergeStoreNode(state, value) {
  const keys = Object.keys(value);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    setProperty(state, key, value[key]);
  }
}
function updatePath(current, path, traversed = []) {
  let part, prev = current;
  if (path.length > 1) {
    part = path.shift();
    const partType = typeof part, isArray2 = Array.isArray(current);
    if (Array.isArray(part)) {
      for (let i = 0; i < part.length; i++) {
        updatePath(current, [part[i]].concat(path), traversed);
      }
      return;
    } else if (isArray2 && partType === "function") {
      for (let i = 0; i < current.length; i++) {
        if (part(current[i], i))
          updatePath(current, [i].concat(path), traversed);
      }
      return;
    } else if (isArray2 && partType === "object") {
      const {
        from = 0,
        to = current.length - 1,
        by = 1
      } = part;
      for (let i = from; i <= to; i += by) {
        updatePath(current, [i].concat(path), traversed);
      }
      return;
    } else if (path.length > 1) {
      updatePath(current[part], path, [part].concat(traversed));
      return;
    }
    prev = current[part];
    traversed = [part].concat(traversed);
  }
  let value = path[0];
  if (typeof value === "function") {
    value = value(prev, traversed);
    if (value === prev)
      return;
  }
  if (part === void 0 && value == void 0)
    return;
  value = unwrap(value);
  if (part === void 0 || isWrappable(prev) && isWrappable(value) && !Array.isArray(value)) {
    mergeStoreNode(prev, value);
  } else
    setProperty(current, part, value);
}
function createStore(store, options) {
  const unwrappedStore = unwrap(store || {});
  const wrappedStore = wrap$1(unwrappedStore);
  function setStore(...args) {
    batch(() => updatePath(unwrappedStore, args));
  }
  return [wrappedStore, setStore];
}
function applyState(target, parent, property, merge, key) {
  const previous = parent[property];
  if (target === previous)
    return;
  if (!isWrappable(target) || !isWrappable(previous) || key && target[key] !== previous[key]) {
    target !== previous && setProperty(parent, property, target);
    return;
  }
  if (Array.isArray(target)) {
    if (target.length && previous.length && (!merge || key && target[0][key] != null)) {
      let i, j, start2, end2, newEnd, item, newIndicesNext, keyVal;
      for (start2 = 0, end2 = Math.min(previous.length, target.length); start2 < end2 && (previous[start2] === target[start2] || key && previous[start2][key] === target[start2][key]); start2++) {
        applyState(target[start2], previous, start2, merge, key);
      }
      const temp = new Array(target.length), newIndices = /* @__PURE__ */ new Map();
      for (end2 = previous.length - 1, newEnd = target.length - 1; end2 >= start2 && newEnd >= start2 && (previous[end2] === target[newEnd] || key && previous[end2][key] === target[newEnd][key]); end2--, newEnd--) {
        temp[newEnd] = previous[end2];
      }
      if (start2 > newEnd || start2 > end2) {
        for (j = start2; j <= newEnd; j++)
          setProperty(previous, j, target[j]);
        for (; j < target.length; j++) {
          setProperty(previous, j, temp[j]);
          applyState(target[j], previous, j, merge, key);
        }
        if (previous.length > target.length)
          setProperty(previous, "length", target.length);
        return;
      }
      newIndicesNext = new Array(newEnd + 1);
      for (j = newEnd; j >= start2; j--) {
        item = target[j];
        keyVal = key ? item[key] : item;
        i = newIndices.get(keyVal);
        newIndicesNext[j] = i === void 0 ? -1 : i;
        newIndices.set(keyVal, j);
      }
      for (i = start2; i <= end2; i++) {
        item = previous[i];
        keyVal = key ? item[key] : item;
        j = newIndices.get(keyVal);
        if (j !== void 0 && j !== -1) {
          temp[j] = previous[i];
          j = newIndicesNext[j];
          newIndices.set(keyVal, j);
        }
      }
      for (j = start2; j < target.length; j++) {
        if (j in temp) {
          setProperty(previous, j, temp[j]);
          applyState(target[j], previous, j, merge, key);
        } else
          setProperty(previous, j, target[j]);
      }
    } else {
      for (let i = 0, len = target.length; i < len; i++) {
        applyState(target[i], previous, i, merge, key);
      }
    }
    if (previous.length > target.length)
      setProperty(previous, "length", target.length);
    return;
  }
  const targetKeys = Object.keys(target);
  for (let i = 0, len = targetKeys.length; i < len; i++) {
    applyState(target[targetKeys[i]], previous, targetKeys[i], merge, key);
  }
  const previousKeys = Object.keys(previous);
  for (let i = 0, len = previousKeys.length; i < len; i++) {
    if (target[previousKeys[i]] === void 0)
      setProperty(previous, previousKeys[i], void 0);
  }
}
function reconcile(value, options = {}) {
  const {
    merge,
    key = "id"
  } = options, v = unwrap(value);
  return (state) => {
    if (!isWrappable(state) || !isWrappable(v))
      return v;
    applyState(v, {
      state
    }, "state", merge, key);
    return state;
  };
}
function deepReadObject(obj, path, defaultValue) {
  const value = path.trim().split(".").reduce((a, b) => a ? a[b] : void 0, obj);
  return value !== void 0 ? value : defaultValue;
}
function template(str, params, reg = /{{(.*?)}}/g) {
  return str.replace(reg, (_, key) => deepReadObject(params, key, ""));
}
function createI18nContext(init = {}, lang = navigator.language in init ? navigator.language : Object.keys(init)[0]) {
  const [locale, setLocale] = createSignal(lang);
  const [dict2, setDict] = createStore(init);
  const translate = (key, params, defaultValue) => {
    const val = deepReadObject(dict2[locale()], key, defaultValue || "");
    if (typeof val === "function")
      return val(params);
    if (typeof val === "string")
      return template(val, params || {});
    return val;
  };
  const actions = {
    add(lang2, table) {
      setDict(lang2, (t) => Object.assign(t || {}, table));
    },
    locale: (lang2) => lang2 ? setLocale(lang2) : locale(),
    dict: (lang2) => deepReadObject(dict2, lang2)
  };
  return [translate, actions];
}
const I18nContext = createContext();
const I18nProvider = (props) => {
  const value = createI18nContext(props.dict, props.locale);
  return createComponent(I18nContext.Provider, {
    value,
    get children() {
      return props.children;
    }
  });
};
const useI18n = () => useContext(I18nContext);
const button$4 = "_button_14s0b_1";
const button_active = "_button_active_14s0b_16";
var styles$g = {
  button: button$4,
  button_active
};
const _tmpl$$A = /* @__PURE__ */ template$1(`<button type="button"></button>`);
const Button = (props) => {
  return (() => {
    const _el$ = _tmpl$$A.cloneNode(true);
    addEventListener(_el$, "click", props.onClick, true);
    const _ref$ = props.ref;
    typeof _ref$ === "function" ? _ref$(_el$) : props.ref = _el$;
    insert(_el$, () => props.children);
    createRenderEffect((_p$) => {
      const _v$ = props.testId, _v$2 = `${styles$g.button || ""} ${props.className || ""}`, _v$3 = {
        [styles$g.button_active]: props.isActive
      }, _v$4 = props.label;
      _v$ !== _p$._v$ && setAttribute(_el$, "data-testid", _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && (_el$.className = _p$._v$2 = _v$2);
      _p$._v$3 = classList(_el$, _v$3, _p$._v$3);
      _v$4 !== _p$._v$4 && setAttribute(_el$, "aria-label", _p$._v$4 = _v$4);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0,
      _v$3: void 0,
      _v$4: void 0
    });
    return _el$;
  })();
};
delegateEvents(["click"]);
var top = "top";
var bottom = "bottom";
var right = "right";
var left = "left";
var auto = "auto";
var basePlacements = [top, bottom, right, left];
var start = "start";
var end = "end";
var clippingParents = "clippingParents";
var viewport = "viewport";
var popper$1 = "popper";
var reference = "reference";
var variationPlacements = /* @__PURE__ */ basePlacements.reduce(function(acc, placement) {
  return acc.concat([placement + "-" + start, placement + "-" + end]);
}, []);
var placements = /* @__PURE__ */ [].concat(basePlacements, [auto]).reduce(function(acc, placement) {
  return acc.concat([placement, placement + "-" + start, placement + "-" + end]);
}, []);
var beforeRead = "beforeRead";
var read = "read";
var afterRead = "afterRead";
var beforeMain = "beforeMain";
var main = "main";
var afterMain = "afterMain";
var beforeWrite = "beforeWrite";
var write = "write";
var afterWrite = "afterWrite";
var modifierPhases = [beforeRead, read, afterRead, beforeMain, main, afterMain, beforeWrite, write, afterWrite];
function getNodeName(element) {
  return element ? (element.nodeName || "").toLowerCase() : null;
}
function getWindow(node) {
  if (node == null) {
    return window;
  }
  if (node.toString() !== "[object Window]") {
    var ownerDocument = node.ownerDocument;
    return ownerDocument ? ownerDocument.defaultView || window : window;
  }
  return node;
}
function isElement$1(node) {
  var OwnElement = getWindow(node).Element;
  return node instanceof OwnElement || node instanceof Element;
}
function isHTMLElement(node) {
  var OwnElement = getWindow(node).HTMLElement;
  return node instanceof OwnElement || node instanceof HTMLElement;
}
function isShadowRoot(node) {
  if (typeof ShadowRoot === "undefined") {
    return false;
  }
  var OwnElement = getWindow(node).ShadowRoot;
  return node instanceof OwnElement || node instanceof ShadowRoot;
}
function applyStyles(_ref) {
  var state = _ref.state;
  Object.keys(state.elements).forEach(function(name) {
    var style2 = state.styles[name] || {};
    var attributes = state.attributes[name] || {};
    var element = state.elements[name];
    if (!isHTMLElement(element) || !getNodeName(element)) {
      return;
    }
    Object.assign(element.style, style2);
    Object.keys(attributes).forEach(function(name2) {
      var value = attributes[name2];
      if (value === false) {
        element.removeAttribute(name2);
      } else {
        element.setAttribute(name2, value === true ? "" : value);
      }
    });
  });
}
function effect$2(_ref2) {
  var state = _ref2.state;
  var initialStyles = {
    popper: {
      position: state.options.strategy,
      left: "0",
      top: "0",
      margin: "0"
    },
    arrow: {
      position: "absolute"
    },
    reference: {}
  };
  Object.assign(state.elements.popper.style, initialStyles.popper);
  state.styles = initialStyles;
  if (state.elements.arrow) {
    Object.assign(state.elements.arrow.style, initialStyles.arrow);
  }
  return function() {
    Object.keys(state.elements).forEach(function(name) {
      var element = state.elements[name];
      var attributes = state.attributes[name] || {};
      var styleProperties = Object.keys(state.styles.hasOwnProperty(name) ? state.styles[name] : initialStyles[name]);
      var style2 = styleProperties.reduce(function(style3, property) {
        style3[property] = "";
        return style3;
      }, {});
      if (!isHTMLElement(element) || !getNodeName(element)) {
        return;
      }
      Object.assign(element.style, style2);
      Object.keys(attributes).forEach(function(attribute) {
        element.removeAttribute(attribute);
      });
    });
  };
}
var applyStyles$1 = {
  name: "applyStyles",
  enabled: true,
  phase: "write",
  fn: applyStyles,
  effect: effect$2,
  requires: ["computeStyles"]
};
function getBasePlacement(placement) {
  return placement.split("-")[0];
}
var max = Math.max;
var min = Math.min;
var round$1 = Math.round;
function getBoundingClientRect(element, includeScale) {
  if (includeScale === void 0) {
    includeScale = false;
  }
  var rect = element.getBoundingClientRect();
  var scaleX = 1;
  var scaleY = 1;
  if (isHTMLElement(element) && includeScale) {
    var offsetHeight = element.offsetHeight;
    var offsetWidth = element.offsetWidth;
    if (offsetWidth > 0) {
      scaleX = round$1(rect.width) / offsetWidth || 1;
    }
    if (offsetHeight > 0) {
      scaleY = round$1(rect.height) / offsetHeight || 1;
    }
  }
  return {
    width: rect.width / scaleX,
    height: rect.height / scaleY,
    top: rect.top / scaleY,
    right: rect.right / scaleX,
    bottom: rect.bottom / scaleY,
    left: rect.left / scaleX,
    x: rect.left / scaleX,
    y: rect.top / scaleY
  };
}
function getLayoutRect(element) {
  var clientRect = getBoundingClientRect(element);
  var width = element.offsetWidth;
  var height = element.offsetHeight;
  if (Math.abs(clientRect.width - width) <= 1) {
    width = clientRect.width;
  }
  if (Math.abs(clientRect.height - height) <= 1) {
    height = clientRect.height;
  }
  return {
    x: element.offsetLeft,
    y: element.offsetTop,
    width,
    height
  };
}
function contains(parent, child) {
  var rootNode = child.getRootNode && child.getRootNode();
  if (parent.contains(child)) {
    return true;
  } else if (rootNode && isShadowRoot(rootNode)) {
    var next = child;
    do {
      if (next && parent.isSameNode(next)) {
        return true;
      }
      next = next.parentNode || next.host;
    } while (next);
  }
  return false;
}
function getComputedStyle$1(element) {
  return getWindow(element).getComputedStyle(element);
}
function isTableElement(element) {
  return ["table", "td", "th"].indexOf(getNodeName(element)) >= 0;
}
function getDocumentElement(element) {
  return ((isElement$1(element) ? element.ownerDocument : element.document) || window.document).documentElement;
}
function getParentNode(element) {
  if (getNodeName(element) === "html") {
    return element;
  }
  return element.assignedSlot || element.parentNode || (isShadowRoot(element) ? element.host : null) || getDocumentElement(element);
}
function getTrueOffsetParent(element) {
  if (!isHTMLElement(element) || getComputedStyle$1(element).position === "fixed") {
    return null;
  }
  return element.offsetParent;
}
function getContainingBlock(element) {
  var isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") !== -1;
  var isIE = navigator.userAgent.indexOf("Trident") !== -1;
  if (isIE && isHTMLElement(element)) {
    var elementCss = getComputedStyle$1(element);
    if (elementCss.position === "fixed") {
      return null;
    }
  }
  var currentNode = getParentNode(element);
  if (isShadowRoot(currentNode)) {
    currentNode = currentNode.host;
  }
  while (isHTMLElement(currentNode) && ["html", "body"].indexOf(getNodeName(currentNode)) < 0) {
    var css = getComputedStyle$1(currentNode);
    if (css.transform !== "none" || css.perspective !== "none" || css.contain === "paint" || ["transform", "perspective"].indexOf(css.willChange) !== -1 || isFirefox && css.willChange === "filter" || isFirefox && css.filter && css.filter !== "none") {
      return currentNode;
    } else {
      currentNode = currentNode.parentNode;
    }
  }
  return null;
}
function getOffsetParent(element) {
  var window2 = getWindow(element);
  var offsetParent = getTrueOffsetParent(element);
  while (offsetParent && isTableElement(offsetParent) && getComputedStyle$1(offsetParent).position === "static") {
    offsetParent = getTrueOffsetParent(offsetParent);
  }
  if (offsetParent && (getNodeName(offsetParent) === "html" || getNodeName(offsetParent) === "body" && getComputedStyle$1(offsetParent).position === "static")) {
    return window2;
  }
  return offsetParent || getContainingBlock(element) || window2;
}
function getMainAxisFromPlacement(placement) {
  return ["top", "bottom"].indexOf(placement) >= 0 ? "x" : "y";
}
function within(min$1, value, max$1) {
  return max(min$1, min(value, max$1));
}
function withinMaxClamp(min2, value, max2) {
  var v = within(min2, value, max2);
  return v > max2 ? max2 : v;
}
function getFreshSideObject() {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
}
function mergePaddingObject(paddingObject) {
  return Object.assign({}, getFreshSideObject(), paddingObject);
}
function expandToHashMap(value, keys) {
  return keys.reduce(function(hashMap, key) {
    hashMap[key] = value;
    return hashMap;
  }, {});
}
var toPaddingObject = function toPaddingObject2(padding, state) {
  padding = typeof padding === "function" ? padding(Object.assign({}, state.rects, {
    placement: state.placement
  })) : padding;
  return mergePaddingObject(typeof padding !== "number" ? padding : expandToHashMap(padding, basePlacements));
};
function arrow(_ref) {
  var _state$modifiersData$;
  var state = _ref.state, name = _ref.name, options = _ref.options;
  var arrowElement = state.elements.arrow;
  var popperOffsets2 = state.modifiersData.popperOffsets;
  var basePlacement = getBasePlacement(state.placement);
  var axis = getMainAxisFromPlacement(basePlacement);
  var isVertical = [left, right].indexOf(basePlacement) >= 0;
  var len = isVertical ? "height" : "width";
  if (!arrowElement || !popperOffsets2) {
    return;
  }
  var paddingObject = toPaddingObject(options.padding, state);
  var arrowRect = getLayoutRect(arrowElement);
  var minProp = axis === "y" ? top : left;
  var maxProp = axis === "y" ? bottom : right;
  var endDiff = state.rects.reference[len] + state.rects.reference[axis] - popperOffsets2[axis] - state.rects.popper[len];
  var startDiff = popperOffsets2[axis] - state.rects.reference[axis];
  var arrowOffsetParent = getOffsetParent(arrowElement);
  var clientSize = arrowOffsetParent ? axis === "y" ? arrowOffsetParent.clientHeight || 0 : arrowOffsetParent.clientWidth || 0 : 0;
  var centerToReference = endDiff / 2 - startDiff / 2;
  var min2 = paddingObject[minProp];
  var max2 = clientSize - arrowRect[len] - paddingObject[maxProp];
  var center = clientSize / 2 - arrowRect[len] / 2 + centerToReference;
  var offset2 = within(min2, center, max2);
  var axisProp = axis;
  state.modifiersData[name] = (_state$modifiersData$ = {}, _state$modifiersData$[axisProp] = offset2, _state$modifiersData$.centerOffset = offset2 - center, _state$modifiersData$);
}
function effect$1(_ref2) {
  var state = _ref2.state, options = _ref2.options;
  var _options$element = options.element, arrowElement = _options$element === void 0 ? "[data-popper-arrow]" : _options$element;
  if (arrowElement == null) {
    return;
  }
  if (typeof arrowElement === "string") {
    arrowElement = state.elements.popper.querySelector(arrowElement);
    if (!arrowElement) {
      return;
    }
  }
  if (!contains(state.elements.popper, arrowElement)) {
    return;
  }
  state.elements.arrow = arrowElement;
}
var arrow$1 = {
  name: "arrow",
  enabled: true,
  phase: "main",
  fn: arrow,
  effect: effect$1,
  requires: ["popperOffsets"],
  requiresIfExists: ["preventOverflow"]
};
function getVariation(placement) {
  return placement.split("-")[1];
}
var unsetSides = {
  top: "auto",
  right: "auto",
  bottom: "auto",
  left: "auto"
};
function roundOffsetsByDPR(_ref) {
  var x = _ref.x, y2 = _ref.y;
  var win = window;
  var dpr = win.devicePixelRatio || 1;
  return {
    x: round$1(x * dpr) / dpr || 0,
    y: round$1(y2 * dpr) / dpr || 0
  };
}
function mapToStyles(_ref2) {
  var _Object$assign2;
  var popper2 = _ref2.popper, popperRect = _ref2.popperRect, placement = _ref2.placement, variation = _ref2.variation, offsets = _ref2.offsets, position = _ref2.position, gpuAcceleration = _ref2.gpuAcceleration, adaptive = _ref2.adaptive, roundOffsets = _ref2.roundOffsets, isFixed = _ref2.isFixed;
  var _offsets$x = offsets.x, x = _offsets$x === void 0 ? 0 : _offsets$x, _offsets$y = offsets.y, y2 = _offsets$y === void 0 ? 0 : _offsets$y;
  var _ref3 = typeof roundOffsets === "function" ? roundOffsets({
    x,
    y: y2
  }) : {
    x,
    y: y2
  };
  x = _ref3.x;
  y2 = _ref3.y;
  var hasX = offsets.hasOwnProperty("x");
  var hasY = offsets.hasOwnProperty("y");
  var sideX = left;
  var sideY = top;
  var win = window;
  if (adaptive) {
    var offsetParent = getOffsetParent(popper2);
    var heightProp = "clientHeight";
    var widthProp = "clientWidth";
    if (offsetParent === getWindow(popper2)) {
      offsetParent = getDocumentElement(popper2);
      if (getComputedStyle$1(offsetParent).position !== "static" && position === "absolute") {
        heightProp = "scrollHeight";
        widthProp = "scrollWidth";
      }
    }
    offsetParent = offsetParent;
    if (placement === top || (placement === left || placement === right) && variation === end) {
      sideY = bottom;
      var offsetY = isFixed && offsetParent === win && win.visualViewport ? win.visualViewport.height : offsetParent[heightProp];
      y2 -= offsetY - popperRect.height;
      y2 *= gpuAcceleration ? 1 : -1;
    }
    if (placement === left || (placement === top || placement === bottom) && variation === end) {
      sideX = right;
      var offsetX = isFixed && offsetParent === win && win.visualViewport ? win.visualViewport.width : offsetParent[widthProp];
      x -= offsetX - popperRect.width;
      x *= gpuAcceleration ? 1 : -1;
    }
  }
  var commonStyles = Object.assign({
    position
  }, adaptive && unsetSides);
  var _ref4 = roundOffsets === true ? roundOffsetsByDPR({
    x,
    y: y2
  }) : {
    x,
    y: y2
  };
  x = _ref4.x;
  y2 = _ref4.y;
  if (gpuAcceleration) {
    var _Object$assign;
    return Object.assign({}, commonStyles, (_Object$assign = {}, _Object$assign[sideY] = hasY ? "0" : "", _Object$assign[sideX] = hasX ? "0" : "", _Object$assign.transform = (win.devicePixelRatio || 1) <= 1 ? "translate(" + x + "px, " + y2 + "px)" : "translate3d(" + x + "px, " + y2 + "px, 0)", _Object$assign));
  }
  return Object.assign({}, commonStyles, (_Object$assign2 = {}, _Object$assign2[sideY] = hasY ? y2 + "px" : "", _Object$assign2[sideX] = hasX ? x + "px" : "", _Object$assign2.transform = "", _Object$assign2));
}
function computeStyles(_ref5) {
  var state = _ref5.state, options = _ref5.options;
  var _options$gpuAccelerat = options.gpuAcceleration, gpuAcceleration = _options$gpuAccelerat === void 0 ? true : _options$gpuAccelerat, _options$adaptive = options.adaptive, adaptive = _options$adaptive === void 0 ? true : _options$adaptive, _options$roundOffsets = options.roundOffsets, roundOffsets = _options$roundOffsets === void 0 ? true : _options$roundOffsets;
  var commonStyles = {
    placement: getBasePlacement(state.placement),
    variation: getVariation(state.placement),
    popper: state.elements.popper,
    popperRect: state.rects.popper,
    gpuAcceleration,
    isFixed: state.options.strategy === "fixed"
  };
  if (state.modifiersData.popperOffsets != null) {
    state.styles.popper = Object.assign({}, state.styles.popper, mapToStyles(Object.assign({}, commonStyles, {
      offsets: state.modifiersData.popperOffsets,
      position: state.options.strategy,
      adaptive,
      roundOffsets
    })));
  }
  if (state.modifiersData.arrow != null) {
    state.styles.arrow = Object.assign({}, state.styles.arrow, mapToStyles(Object.assign({}, commonStyles, {
      offsets: state.modifiersData.arrow,
      position: "absolute",
      adaptive: false,
      roundOffsets
    })));
  }
  state.attributes.popper = Object.assign({}, state.attributes.popper, {
    "data-popper-placement": state.placement
  });
}
var computeStyles$1 = {
  name: "computeStyles",
  enabled: true,
  phase: "beforeWrite",
  fn: computeStyles,
  data: {}
};
var passive = {
  passive: true
};
function effect(_ref) {
  var state = _ref.state, instance = _ref.instance, options = _ref.options;
  var _options$scroll = options.scroll, scroll = _options$scroll === void 0 ? true : _options$scroll, _options$resize = options.resize, resize = _options$resize === void 0 ? true : _options$resize;
  var window2 = getWindow(state.elements.popper);
  var scrollParents = [].concat(state.scrollParents.reference, state.scrollParents.popper);
  if (scroll) {
    scrollParents.forEach(function(scrollParent) {
      scrollParent.addEventListener("scroll", instance.update, passive);
    });
  }
  if (resize) {
    window2.addEventListener("resize", instance.update, passive);
  }
  return function() {
    if (scroll) {
      scrollParents.forEach(function(scrollParent) {
        scrollParent.removeEventListener("scroll", instance.update, passive);
      });
    }
    if (resize) {
      window2.removeEventListener("resize", instance.update, passive);
    }
  };
}
var eventListeners = {
  name: "eventListeners",
  enabled: true,
  phase: "write",
  fn: function fn() {
  },
  effect,
  data: {}
};
var hash$1 = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
};
function getOppositePlacement(placement) {
  return placement.replace(/left|right|bottom|top/g, function(matched) {
    return hash$1[matched];
  });
}
var hash = {
  start: "end",
  end: "start"
};
function getOppositeVariationPlacement(placement) {
  return placement.replace(/start|end/g, function(matched) {
    return hash[matched];
  });
}
function getWindowScroll(node) {
  var win = getWindow(node);
  var scrollLeft = win.pageXOffset;
  var scrollTop = win.pageYOffset;
  return {
    scrollLeft,
    scrollTop
  };
}
function getWindowScrollBarX(element) {
  return getBoundingClientRect(getDocumentElement(element)).left + getWindowScroll(element).scrollLeft;
}
function getViewportRect(element) {
  var win = getWindow(element);
  var html = getDocumentElement(element);
  var visualViewport = win.visualViewport;
  var width = html.clientWidth;
  var height = html.clientHeight;
  var x = 0;
  var y2 = 0;
  if (visualViewport) {
    width = visualViewport.width;
    height = visualViewport.height;
    if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      x = visualViewport.offsetLeft;
      y2 = visualViewport.offsetTop;
    }
  }
  return {
    width,
    height,
    x: x + getWindowScrollBarX(element),
    y: y2
  };
}
function getDocumentRect(element) {
  var _element$ownerDocumen;
  var html = getDocumentElement(element);
  var winScroll = getWindowScroll(element);
  var body = (_element$ownerDocumen = element.ownerDocument) == null ? void 0 : _element$ownerDocumen.body;
  var width = max(html.scrollWidth, html.clientWidth, body ? body.scrollWidth : 0, body ? body.clientWidth : 0);
  var height = max(html.scrollHeight, html.clientHeight, body ? body.scrollHeight : 0, body ? body.clientHeight : 0);
  var x = -winScroll.scrollLeft + getWindowScrollBarX(element);
  var y2 = -winScroll.scrollTop;
  if (getComputedStyle$1(body || html).direction === "rtl") {
    x += max(html.clientWidth, body ? body.clientWidth : 0) - width;
  }
  return {
    width,
    height,
    x,
    y: y2
  };
}
function isScrollParent(element) {
  var _getComputedStyle = getComputedStyle$1(element), overflow = _getComputedStyle.overflow, overflowX = _getComputedStyle.overflowX, overflowY = _getComputedStyle.overflowY;
  return /auto|scroll|overlay|hidden/.test(overflow + overflowY + overflowX);
}
function getScrollParent(node) {
  if (["html", "body", "#document"].indexOf(getNodeName(node)) >= 0) {
    return node.ownerDocument.body;
  }
  if (isHTMLElement(node) && isScrollParent(node)) {
    return node;
  }
  return getScrollParent(getParentNode(node));
}
function listScrollParents(element, list) {
  var _element$ownerDocumen;
  if (list === void 0) {
    list = [];
  }
  var scrollParent = getScrollParent(element);
  var isBody = scrollParent === ((_element$ownerDocumen = element.ownerDocument) == null ? void 0 : _element$ownerDocumen.body);
  var win = getWindow(scrollParent);
  var target = isBody ? [win].concat(win.visualViewport || [], isScrollParent(scrollParent) ? scrollParent : []) : scrollParent;
  var updatedList = list.concat(target);
  return isBody ? updatedList : updatedList.concat(listScrollParents(getParentNode(target)));
}
function rectToClientRect(rect) {
  return Object.assign({}, rect, {
    left: rect.x,
    top: rect.y,
    right: rect.x + rect.width,
    bottom: rect.y + rect.height
  });
}
function getInnerBoundingClientRect(element) {
  var rect = getBoundingClientRect(element);
  rect.top = rect.top + element.clientTop;
  rect.left = rect.left + element.clientLeft;
  rect.bottom = rect.top + element.clientHeight;
  rect.right = rect.left + element.clientWidth;
  rect.width = element.clientWidth;
  rect.height = element.clientHeight;
  rect.x = rect.left;
  rect.y = rect.top;
  return rect;
}
function getClientRectFromMixedType(element, clippingParent) {
  return clippingParent === viewport ? rectToClientRect(getViewportRect(element)) : isElement$1(clippingParent) ? getInnerBoundingClientRect(clippingParent) : rectToClientRect(getDocumentRect(getDocumentElement(element)));
}
function getClippingParents(element) {
  var clippingParents2 = listScrollParents(getParentNode(element));
  var canEscapeClipping = ["absolute", "fixed"].indexOf(getComputedStyle$1(element).position) >= 0;
  var clipperElement = canEscapeClipping && isHTMLElement(element) ? getOffsetParent(element) : element;
  if (!isElement$1(clipperElement)) {
    return [];
  }
  return clippingParents2.filter(function(clippingParent) {
    return isElement$1(clippingParent) && contains(clippingParent, clipperElement) && getNodeName(clippingParent) !== "body";
  });
}
function getClippingRect(element, boundary, rootBoundary) {
  var mainClippingParents = boundary === "clippingParents" ? getClippingParents(element) : [].concat(boundary);
  var clippingParents2 = [].concat(mainClippingParents, [rootBoundary]);
  var firstClippingParent = clippingParents2[0];
  var clippingRect = clippingParents2.reduce(function(accRect, clippingParent) {
    var rect = getClientRectFromMixedType(element, clippingParent);
    accRect.top = max(rect.top, accRect.top);
    accRect.right = min(rect.right, accRect.right);
    accRect.bottom = min(rect.bottom, accRect.bottom);
    accRect.left = max(rect.left, accRect.left);
    return accRect;
  }, getClientRectFromMixedType(element, firstClippingParent));
  clippingRect.width = clippingRect.right - clippingRect.left;
  clippingRect.height = clippingRect.bottom - clippingRect.top;
  clippingRect.x = clippingRect.left;
  clippingRect.y = clippingRect.top;
  return clippingRect;
}
function computeOffsets(_ref) {
  var reference2 = _ref.reference, element = _ref.element, placement = _ref.placement;
  var basePlacement = placement ? getBasePlacement(placement) : null;
  var variation = placement ? getVariation(placement) : null;
  var commonX = reference2.x + reference2.width / 2 - element.width / 2;
  var commonY = reference2.y + reference2.height / 2 - element.height / 2;
  var offsets;
  switch (basePlacement) {
    case top:
      offsets = {
        x: commonX,
        y: reference2.y - element.height
      };
      break;
    case bottom:
      offsets = {
        x: commonX,
        y: reference2.y + reference2.height
      };
      break;
    case right:
      offsets = {
        x: reference2.x + reference2.width,
        y: commonY
      };
      break;
    case left:
      offsets = {
        x: reference2.x - element.width,
        y: commonY
      };
      break;
    default:
      offsets = {
        x: reference2.x,
        y: reference2.y
      };
  }
  var mainAxis = basePlacement ? getMainAxisFromPlacement(basePlacement) : null;
  if (mainAxis != null) {
    var len = mainAxis === "y" ? "height" : "width";
    switch (variation) {
      case start:
        offsets[mainAxis] = offsets[mainAxis] - (reference2[len] / 2 - element[len] / 2);
        break;
      case end:
        offsets[mainAxis] = offsets[mainAxis] + (reference2[len] / 2 - element[len] / 2);
        break;
    }
  }
  return offsets;
}
function detectOverflow(state, options) {
  if (options === void 0) {
    options = {};
  }
  var _options = options, _options$placement = _options.placement, placement = _options$placement === void 0 ? state.placement : _options$placement, _options$boundary = _options.boundary, boundary = _options$boundary === void 0 ? clippingParents : _options$boundary, _options$rootBoundary = _options.rootBoundary, rootBoundary = _options$rootBoundary === void 0 ? viewport : _options$rootBoundary, _options$elementConte = _options.elementContext, elementContext = _options$elementConte === void 0 ? popper$1 : _options$elementConte, _options$altBoundary = _options.altBoundary, altBoundary = _options$altBoundary === void 0 ? false : _options$altBoundary, _options$padding = _options.padding, padding = _options$padding === void 0 ? 0 : _options$padding;
  var paddingObject = mergePaddingObject(typeof padding !== "number" ? padding : expandToHashMap(padding, basePlacements));
  var altContext = elementContext === popper$1 ? reference : popper$1;
  var popperRect = state.rects.popper;
  var element = state.elements[altBoundary ? altContext : elementContext];
  var clippingClientRect = getClippingRect(isElement$1(element) ? element : element.contextElement || getDocumentElement(state.elements.popper), boundary, rootBoundary);
  var referenceClientRect = getBoundingClientRect(state.elements.reference);
  var popperOffsets2 = computeOffsets({
    reference: referenceClientRect,
    element: popperRect,
    strategy: "absolute",
    placement
  });
  var popperClientRect = rectToClientRect(Object.assign({}, popperRect, popperOffsets2));
  var elementClientRect = elementContext === popper$1 ? popperClientRect : referenceClientRect;
  var overflowOffsets = {
    top: clippingClientRect.top - elementClientRect.top + paddingObject.top,
    bottom: elementClientRect.bottom - clippingClientRect.bottom + paddingObject.bottom,
    left: clippingClientRect.left - elementClientRect.left + paddingObject.left,
    right: elementClientRect.right - clippingClientRect.right + paddingObject.right
  };
  var offsetData = state.modifiersData.offset;
  if (elementContext === popper$1 && offsetData) {
    var offset2 = offsetData[placement];
    Object.keys(overflowOffsets).forEach(function(key) {
      var multiply = [right, bottom].indexOf(key) >= 0 ? 1 : -1;
      var axis = [top, bottom].indexOf(key) >= 0 ? "y" : "x";
      overflowOffsets[key] += offset2[axis] * multiply;
    });
  }
  return overflowOffsets;
}
function computeAutoPlacement(state, options) {
  if (options === void 0) {
    options = {};
  }
  var _options = options, placement = _options.placement, boundary = _options.boundary, rootBoundary = _options.rootBoundary, padding = _options.padding, flipVariations = _options.flipVariations, _options$allowedAutoP = _options.allowedAutoPlacements, allowedAutoPlacements = _options$allowedAutoP === void 0 ? placements : _options$allowedAutoP;
  var variation = getVariation(placement);
  var placements$1 = variation ? flipVariations ? variationPlacements : variationPlacements.filter(function(placement2) {
    return getVariation(placement2) === variation;
  }) : basePlacements;
  var allowedPlacements = placements$1.filter(function(placement2) {
    return allowedAutoPlacements.indexOf(placement2) >= 0;
  });
  if (allowedPlacements.length === 0) {
    allowedPlacements = placements$1;
  }
  var overflows = allowedPlacements.reduce(function(acc, placement2) {
    acc[placement2] = detectOverflow(state, {
      placement: placement2,
      boundary,
      rootBoundary,
      padding
    })[getBasePlacement(placement2)];
    return acc;
  }, {});
  return Object.keys(overflows).sort(function(a, b) {
    return overflows[a] - overflows[b];
  });
}
function getExpandedFallbackPlacements(placement) {
  if (getBasePlacement(placement) === auto) {
    return [];
  }
  var oppositePlacement = getOppositePlacement(placement);
  return [getOppositeVariationPlacement(placement), oppositePlacement, getOppositeVariationPlacement(oppositePlacement)];
}
function flip(_ref) {
  var state = _ref.state, options = _ref.options, name = _ref.name;
  if (state.modifiersData[name]._skip) {
    return;
  }
  var _options$mainAxis = options.mainAxis, checkMainAxis = _options$mainAxis === void 0 ? true : _options$mainAxis, _options$altAxis = options.altAxis, checkAltAxis = _options$altAxis === void 0 ? true : _options$altAxis, specifiedFallbackPlacements = options.fallbackPlacements, padding = options.padding, boundary = options.boundary, rootBoundary = options.rootBoundary, altBoundary = options.altBoundary, _options$flipVariatio = options.flipVariations, flipVariations = _options$flipVariatio === void 0 ? true : _options$flipVariatio, allowedAutoPlacements = options.allowedAutoPlacements;
  var preferredPlacement = state.options.placement;
  var basePlacement = getBasePlacement(preferredPlacement);
  var isBasePlacement = basePlacement === preferredPlacement;
  var fallbackPlacements = specifiedFallbackPlacements || (isBasePlacement || !flipVariations ? [getOppositePlacement(preferredPlacement)] : getExpandedFallbackPlacements(preferredPlacement));
  var placements2 = [preferredPlacement].concat(fallbackPlacements).reduce(function(acc, placement2) {
    return acc.concat(getBasePlacement(placement2) === auto ? computeAutoPlacement(state, {
      placement: placement2,
      boundary,
      rootBoundary,
      padding,
      flipVariations,
      allowedAutoPlacements
    }) : placement2);
  }, []);
  var referenceRect = state.rects.reference;
  var popperRect = state.rects.popper;
  var checksMap = /* @__PURE__ */ new Map();
  var makeFallbackChecks = true;
  var firstFittingPlacement = placements2[0];
  for (var i = 0; i < placements2.length; i++) {
    var placement = placements2[i];
    var _basePlacement = getBasePlacement(placement);
    var isStartVariation = getVariation(placement) === start;
    var isVertical = [top, bottom].indexOf(_basePlacement) >= 0;
    var len = isVertical ? "width" : "height";
    var overflow = detectOverflow(state, {
      placement,
      boundary,
      rootBoundary,
      altBoundary,
      padding
    });
    var mainVariationSide = isVertical ? isStartVariation ? right : left : isStartVariation ? bottom : top;
    if (referenceRect[len] > popperRect[len]) {
      mainVariationSide = getOppositePlacement(mainVariationSide);
    }
    var altVariationSide = getOppositePlacement(mainVariationSide);
    var checks = [];
    if (checkMainAxis) {
      checks.push(overflow[_basePlacement] <= 0);
    }
    if (checkAltAxis) {
      checks.push(overflow[mainVariationSide] <= 0, overflow[altVariationSide] <= 0);
    }
    if (checks.every(function(check) {
      return check;
    })) {
      firstFittingPlacement = placement;
      makeFallbackChecks = false;
      break;
    }
    checksMap.set(placement, checks);
  }
  if (makeFallbackChecks) {
    var numberOfChecks = flipVariations ? 3 : 1;
    var _loop = function _loop2(_i2) {
      var fittingPlacement = placements2.find(function(placement2) {
        var checks2 = checksMap.get(placement2);
        if (checks2) {
          return checks2.slice(0, _i2).every(function(check) {
            return check;
          });
        }
      });
      if (fittingPlacement) {
        firstFittingPlacement = fittingPlacement;
        return "break";
      }
    };
    for (var _i = numberOfChecks; _i > 0; _i--) {
      var _ret = _loop(_i);
      if (_ret === "break")
        break;
    }
  }
  if (state.placement !== firstFittingPlacement) {
    state.modifiersData[name]._skip = true;
    state.placement = firstFittingPlacement;
    state.reset = true;
  }
}
var flip$1 = {
  name: "flip",
  enabled: true,
  phase: "main",
  fn: flip,
  requiresIfExists: ["offset"],
  data: {
    _skip: false
  }
};
function getSideOffsets(overflow, rect, preventedOffsets) {
  if (preventedOffsets === void 0) {
    preventedOffsets = {
      x: 0,
      y: 0
    };
  }
  return {
    top: overflow.top - rect.height - preventedOffsets.y,
    right: overflow.right - rect.width + preventedOffsets.x,
    bottom: overflow.bottom - rect.height + preventedOffsets.y,
    left: overflow.left - rect.width - preventedOffsets.x
  };
}
function isAnySideFullyClipped(overflow) {
  return [top, right, bottom, left].some(function(side) {
    return overflow[side] >= 0;
  });
}
function hide(_ref) {
  var state = _ref.state, name = _ref.name;
  var referenceRect = state.rects.reference;
  var popperRect = state.rects.popper;
  var preventedOffsets = state.modifiersData.preventOverflow;
  var referenceOverflow = detectOverflow(state, {
    elementContext: "reference"
  });
  var popperAltOverflow = detectOverflow(state, {
    altBoundary: true
  });
  var referenceClippingOffsets = getSideOffsets(referenceOverflow, referenceRect);
  var popperEscapeOffsets = getSideOffsets(popperAltOverflow, popperRect, preventedOffsets);
  var isReferenceHidden = isAnySideFullyClipped(referenceClippingOffsets);
  var hasPopperEscaped = isAnySideFullyClipped(popperEscapeOffsets);
  state.modifiersData[name] = {
    referenceClippingOffsets,
    popperEscapeOffsets,
    isReferenceHidden,
    hasPopperEscaped
  };
  state.attributes.popper = Object.assign({}, state.attributes.popper, {
    "data-popper-reference-hidden": isReferenceHidden,
    "data-popper-escaped": hasPopperEscaped
  });
}
var hide$1 = {
  name: "hide",
  enabled: true,
  phase: "main",
  requiresIfExists: ["preventOverflow"],
  fn: hide
};
function distanceAndSkiddingToXY(placement, rects, offset2) {
  var basePlacement = getBasePlacement(placement);
  var invertDistance = [left, top].indexOf(basePlacement) >= 0 ? -1 : 1;
  var _ref = typeof offset2 === "function" ? offset2(Object.assign({}, rects, {
    placement
  })) : offset2, skidding = _ref[0], distance = _ref[1];
  skidding = skidding || 0;
  distance = (distance || 0) * invertDistance;
  return [left, right].indexOf(basePlacement) >= 0 ? {
    x: distance,
    y: skidding
  } : {
    x: skidding,
    y: distance
  };
}
function offset(_ref2) {
  var state = _ref2.state, options = _ref2.options, name = _ref2.name;
  var _options$offset = options.offset, offset2 = _options$offset === void 0 ? [0, 0] : _options$offset;
  var data = placements.reduce(function(acc, placement) {
    acc[placement] = distanceAndSkiddingToXY(placement, state.rects, offset2);
    return acc;
  }, {});
  var _data$state$placement = data[state.placement], x = _data$state$placement.x, y2 = _data$state$placement.y;
  if (state.modifiersData.popperOffsets != null) {
    state.modifiersData.popperOffsets.x += x;
    state.modifiersData.popperOffsets.y += y2;
  }
  state.modifiersData[name] = data;
}
var offset$1 = {
  name: "offset",
  enabled: true,
  phase: "main",
  requires: ["popperOffsets"],
  fn: offset
};
function popperOffsets(_ref) {
  var state = _ref.state, name = _ref.name;
  state.modifiersData[name] = computeOffsets({
    reference: state.rects.reference,
    element: state.rects.popper,
    strategy: "absolute",
    placement: state.placement
  });
}
var popperOffsets$1 = {
  name: "popperOffsets",
  enabled: true,
  phase: "read",
  fn: popperOffsets,
  data: {}
};
function getAltAxis(axis) {
  return axis === "x" ? "y" : "x";
}
function preventOverflow(_ref) {
  var state = _ref.state, options = _ref.options, name = _ref.name;
  var _options$mainAxis = options.mainAxis, checkMainAxis = _options$mainAxis === void 0 ? true : _options$mainAxis, _options$altAxis = options.altAxis, checkAltAxis = _options$altAxis === void 0 ? false : _options$altAxis, boundary = options.boundary, rootBoundary = options.rootBoundary, altBoundary = options.altBoundary, padding = options.padding, _options$tether = options.tether, tether = _options$tether === void 0 ? true : _options$tether, _options$tetherOffset = options.tetherOffset, tetherOffset = _options$tetherOffset === void 0 ? 0 : _options$tetherOffset;
  var overflow = detectOverflow(state, {
    boundary,
    rootBoundary,
    padding,
    altBoundary
  });
  var basePlacement = getBasePlacement(state.placement);
  var variation = getVariation(state.placement);
  var isBasePlacement = !variation;
  var mainAxis = getMainAxisFromPlacement(basePlacement);
  var altAxis = getAltAxis(mainAxis);
  var popperOffsets2 = state.modifiersData.popperOffsets;
  var referenceRect = state.rects.reference;
  var popperRect = state.rects.popper;
  var tetherOffsetValue = typeof tetherOffset === "function" ? tetherOffset(Object.assign({}, state.rects, {
    placement: state.placement
  })) : tetherOffset;
  var normalizedTetherOffsetValue = typeof tetherOffsetValue === "number" ? {
    mainAxis: tetherOffsetValue,
    altAxis: tetherOffsetValue
  } : Object.assign({
    mainAxis: 0,
    altAxis: 0
  }, tetherOffsetValue);
  var offsetModifierState = state.modifiersData.offset ? state.modifiersData.offset[state.placement] : null;
  var data = {
    x: 0,
    y: 0
  };
  if (!popperOffsets2) {
    return;
  }
  if (checkMainAxis) {
    var _offsetModifierState$;
    var mainSide = mainAxis === "y" ? top : left;
    var altSide = mainAxis === "y" ? bottom : right;
    var len = mainAxis === "y" ? "height" : "width";
    var offset2 = popperOffsets2[mainAxis];
    var min$1 = offset2 + overflow[mainSide];
    var max$1 = offset2 - overflow[altSide];
    var additive = tether ? -popperRect[len] / 2 : 0;
    var minLen = variation === start ? referenceRect[len] : popperRect[len];
    var maxLen = variation === start ? -popperRect[len] : -referenceRect[len];
    var arrowElement = state.elements.arrow;
    var arrowRect = tether && arrowElement ? getLayoutRect(arrowElement) : {
      width: 0,
      height: 0
    };
    var arrowPaddingObject = state.modifiersData["arrow#persistent"] ? state.modifiersData["arrow#persistent"].padding : getFreshSideObject();
    var arrowPaddingMin = arrowPaddingObject[mainSide];
    var arrowPaddingMax = arrowPaddingObject[altSide];
    var arrowLen = within(0, referenceRect[len], arrowRect[len]);
    var minOffset = isBasePlacement ? referenceRect[len] / 2 - additive - arrowLen - arrowPaddingMin - normalizedTetherOffsetValue.mainAxis : minLen - arrowLen - arrowPaddingMin - normalizedTetherOffsetValue.mainAxis;
    var maxOffset = isBasePlacement ? -referenceRect[len] / 2 + additive + arrowLen + arrowPaddingMax + normalizedTetherOffsetValue.mainAxis : maxLen + arrowLen + arrowPaddingMax + normalizedTetherOffsetValue.mainAxis;
    var arrowOffsetParent = state.elements.arrow && getOffsetParent(state.elements.arrow);
    var clientOffset = arrowOffsetParent ? mainAxis === "y" ? arrowOffsetParent.clientTop || 0 : arrowOffsetParent.clientLeft || 0 : 0;
    var offsetModifierValue = (_offsetModifierState$ = offsetModifierState == null ? void 0 : offsetModifierState[mainAxis]) != null ? _offsetModifierState$ : 0;
    var tetherMin = offset2 + minOffset - offsetModifierValue - clientOffset;
    var tetherMax = offset2 + maxOffset - offsetModifierValue;
    var preventedOffset = within(tether ? min(min$1, tetherMin) : min$1, offset2, tether ? max(max$1, tetherMax) : max$1);
    popperOffsets2[mainAxis] = preventedOffset;
    data[mainAxis] = preventedOffset - offset2;
  }
  if (checkAltAxis) {
    var _offsetModifierState$2;
    var _mainSide = mainAxis === "x" ? top : left;
    var _altSide = mainAxis === "x" ? bottom : right;
    var _offset = popperOffsets2[altAxis];
    var _len = altAxis === "y" ? "height" : "width";
    var _min = _offset + overflow[_mainSide];
    var _max = _offset - overflow[_altSide];
    var isOriginSide = [top, left].indexOf(basePlacement) !== -1;
    var _offsetModifierValue = (_offsetModifierState$2 = offsetModifierState == null ? void 0 : offsetModifierState[altAxis]) != null ? _offsetModifierState$2 : 0;
    var _tetherMin = isOriginSide ? _min : _offset - referenceRect[_len] - popperRect[_len] - _offsetModifierValue + normalizedTetherOffsetValue.altAxis;
    var _tetherMax = isOriginSide ? _offset + referenceRect[_len] + popperRect[_len] - _offsetModifierValue - normalizedTetherOffsetValue.altAxis : _max;
    var _preventedOffset = tether && isOriginSide ? withinMaxClamp(_tetherMin, _offset, _tetherMax) : within(tether ? _tetherMin : _min, _offset, tether ? _tetherMax : _max);
    popperOffsets2[altAxis] = _preventedOffset;
    data[altAxis] = _preventedOffset - _offset;
  }
  state.modifiersData[name] = data;
}
var preventOverflow$1 = {
  name: "preventOverflow",
  enabled: true,
  phase: "main",
  fn: preventOverflow,
  requiresIfExists: ["offset"]
};
function getHTMLElementScroll(element) {
  return {
    scrollLeft: element.scrollLeft,
    scrollTop: element.scrollTop
  };
}
function getNodeScroll(node) {
  if (node === getWindow(node) || !isHTMLElement(node)) {
    return getWindowScroll(node);
  } else {
    return getHTMLElementScroll(node);
  }
}
function isElementScaled(element) {
  var rect = element.getBoundingClientRect();
  var scaleX = round$1(rect.width) / element.offsetWidth || 1;
  var scaleY = round$1(rect.height) / element.offsetHeight || 1;
  return scaleX !== 1 || scaleY !== 1;
}
function getCompositeRect(elementOrVirtualElement, offsetParent, isFixed) {
  if (isFixed === void 0) {
    isFixed = false;
  }
  var isOffsetParentAnElement = isHTMLElement(offsetParent);
  var offsetParentIsScaled = isHTMLElement(offsetParent) && isElementScaled(offsetParent);
  var documentElement = getDocumentElement(offsetParent);
  var rect = getBoundingClientRect(elementOrVirtualElement, offsetParentIsScaled);
  var scroll = {
    scrollLeft: 0,
    scrollTop: 0
  };
  var offsets = {
    x: 0,
    y: 0
  };
  if (isOffsetParentAnElement || !isOffsetParentAnElement && !isFixed) {
    if (getNodeName(offsetParent) !== "body" || isScrollParent(documentElement)) {
      scroll = getNodeScroll(offsetParent);
    }
    if (isHTMLElement(offsetParent)) {
      offsets = getBoundingClientRect(offsetParent, true);
      offsets.x += offsetParent.clientLeft;
      offsets.y += offsetParent.clientTop;
    } else if (documentElement) {
      offsets.x = getWindowScrollBarX(documentElement);
    }
  }
  return {
    x: rect.left + scroll.scrollLeft - offsets.x,
    y: rect.top + scroll.scrollTop - offsets.y,
    width: rect.width,
    height: rect.height
  };
}
function order(modifiers) {
  var map = /* @__PURE__ */ new Map();
  var visited = /* @__PURE__ */ new Set();
  var result = [];
  modifiers.forEach(function(modifier) {
    map.set(modifier.name, modifier);
  });
  function sort(modifier) {
    visited.add(modifier.name);
    var requires = [].concat(modifier.requires || [], modifier.requiresIfExists || []);
    requires.forEach(function(dep) {
      if (!visited.has(dep)) {
        var depModifier = map.get(dep);
        if (depModifier) {
          sort(depModifier);
        }
      }
    });
    result.push(modifier);
  }
  modifiers.forEach(function(modifier) {
    if (!visited.has(modifier.name)) {
      sort(modifier);
    }
  });
  return result;
}
function orderModifiers(modifiers) {
  var orderedModifiers = order(modifiers);
  return modifierPhases.reduce(function(acc, phase) {
    return acc.concat(orderedModifiers.filter(function(modifier) {
      return modifier.phase === phase;
    }));
  }, []);
}
function debounce(fn2) {
  var pending;
  return function() {
    if (!pending) {
      pending = new Promise(function(resolve) {
        Promise.resolve().then(function() {
          pending = void 0;
          resolve(fn2());
        });
      });
    }
    return pending;
  };
}
function mergeByName(modifiers) {
  var merged = modifiers.reduce(function(merged2, current) {
    var existing = merged2[current.name];
    merged2[current.name] = existing ? Object.assign({}, existing, current, {
      options: Object.assign({}, existing.options, current.options),
      data: Object.assign({}, existing.data, current.data)
    }) : current;
    return merged2;
  }, {});
  return Object.keys(merged).map(function(key) {
    return merged[key];
  });
}
var DEFAULT_OPTIONS = {
  placement: "bottom",
  modifiers: [],
  strategy: "absolute"
};
function areValidElements() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return !args.some(function(element) {
    return !(element && typeof element.getBoundingClientRect === "function");
  });
}
function popperGenerator(generatorOptions) {
  if (generatorOptions === void 0) {
    generatorOptions = {};
  }
  var _generatorOptions = generatorOptions, _generatorOptions$def = _generatorOptions.defaultModifiers, defaultModifiers2 = _generatorOptions$def === void 0 ? [] : _generatorOptions$def, _generatorOptions$def2 = _generatorOptions.defaultOptions, defaultOptions = _generatorOptions$def2 === void 0 ? DEFAULT_OPTIONS : _generatorOptions$def2;
  return function createPopper2(reference2, popper2, options) {
    if (options === void 0) {
      options = defaultOptions;
    }
    var state = {
      placement: "bottom",
      orderedModifiers: [],
      options: Object.assign({}, DEFAULT_OPTIONS, defaultOptions),
      modifiersData: {},
      elements: {
        reference: reference2,
        popper: popper2
      },
      attributes: {},
      styles: {}
    };
    var effectCleanupFns = [];
    var isDestroyed = false;
    var instance = {
      state,
      setOptions: function setOptions(setOptionsAction) {
        var options2 = typeof setOptionsAction === "function" ? setOptionsAction(state.options) : setOptionsAction;
        cleanupModifierEffects();
        state.options = Object.assign({}, defaultOptions, state.options, options2);
        state.scrollParents = {
          reference: isElement$1(reference2) ? listScrollParents(reference2) : reference2.contextElement ? listScrollParents(reference2.contextElement) : [],
          popper: listScrollParents(popper2)
        };
        var orderedModifiers = orderModifiers(mergeByName([].concat(defaultModifiers2, state.options.modifiers)));
        state.orderedModifiers = orderedModifiers.filter(function(m2) {
          return m2.enabled;
        });
        runModifierEffects();
        return instance.update();
      },
      forceUpdate: function forceUpdate() {
        if (isDestroyed) {
          return;
        }
        var _state$elements = state.elements, reference3 = _state$elements.reference, popper3 = _state$elements.popper;
        if (!areValidElements(reference3, popper3)) {
          return;
        }
        state.rects = {
          reference: getCompositeRect(reference3, getOffsetParent(popper3), state.options.strategy === "fixed"),
          popper: getLayoutRect(popper3)
        };
        state.reset = false;
        state.placement = state.options.placement;
        state.orderedModifiers.forEach(function(modifier) {
          return state.modifiersData[modifier.name] = Object.assign({}, modifier.data);
        });
        for (var index = 0; index < state.orderedModifiers.length; index++) {
          if (state.reset === true) {
            state.reset = false;
            index = -1;
            continue;
          }
          var _state$orderedModifie = state.orderedModifiers[index], fn2 = _state$orderedModifie.fn, _state$orderedModifie2 = _state$orderedModifie.options, _options = _state$orderedModifie2 === void 0 ? {} : _state$orderedModifie2, name = _state$orderedModifie.name;
          if (typeof fn2 === "function") {
            state = fn2({
              state,
              options: _options,
              name,
              instance
            }) || state;
          }
        }
      },
      update: debounce(function() {
        return new Promise(function(resolve) {
          instance.forceUpdate();
          resolve(state);
        });
      }),
      destroy: function destroy() {
        cleanupModifierEffects();
        isDestroyed = true;
      }
    };
    if (!areValidElements(reference2, popper2)) {
      return instance;
    }
    instance.setOptions(options).then(function(state2) {
      if (!isDestroyed && options.onFirstUpdate) {
        options.onFirstUpdate(state2);
      }
    });
    function runModifierEffects() {
      state.orderedModifiers.forEach(function(_ref3) {
        var name = _ref3.name, _ref3$options = _ref3.options, options2 = _ref3$options === void 0 ? {} : _ref3$options, effect2 = _ref3.effect;
        if (typeof effect2 === "function") {
          var cleanupFn = effect2({
            state,
            name,
            instance,
            options: options2
          });
          var noopFn = function noopFn2() {
          };
          effectCleanupFns.push(cleanupFn || noopFn);
        }
      });
    }
    function cleanupModifierEffects() {
      effectCleanupFns.forEach(function(fn2) {
        return fn2();
      });
      effectCleanupFns = [];
    }
    return instance;
  };
}
var defaultModifiers = [eventListeners, popperOffsets$1, computeStyles$1, applyStyles$1, offset$1, flip$1, preventOverflow$1, arrow$1, hide$1];
var createPopper = /* @__PURE__ */ popperGenerator({
  defaultModifiers
});
var l = Object.defineProperty;
var p = Object.getOwnPropertySymbols;
var m = Object.prototype.hasOwnProperty, g = Object.prototype.propertyIsEnumerable;
var d = (n, t, e) => t in n ? l(n, t, { enumerable: true, configurable: true, writable: true, value: e }) : n[t] = e, u = (n, t) => {
  for (var e in t || (t = {}))
    m.call(t, e) && d(n, e, t[e]);
  if (p)
    for (var e of p(t))
      g.call(t, e) && d(n, e, t[e]);
  return n;
};
function y(n, t, e = {}) {
  let [o, i] = createSignal();
  return createEffect(() => {
    i(void 0);
    let r = n(), s = t();
    if (r && s) {
      let a = createPopper(r, s, {});
      i(a), onCleanup(() => {
        a.destroy();
      });
    }
  }), createEffect(() => {
    var s, a, c;
    let r = o();
    r && r.setOptions({ onFirstUpdate: e.onFirstUpdate, placement: (s = e.placement) != null ? s : "bottom", modifiers: (a = e.modifiers) != null ? a : [], strategy: (c = e.strategy) != null ? c : "absolute" });
  }), () => {
    let r = o();
    if (r)
      return u({}, r);
  };
}
const tooltip = "_tooltip_9kyqw_1";
var styles$f = {
  tooltip
};
const _tmpl$$z = /* @__PURE__ */ template$1(`<div data-testid="tooltip"></div>`), _tmpl$2$5 = /* @__PURE__ */ template$1(`<div data-testid="anchor_tooltip"></div>`);
const Tooltip = (props) => {
  const [isOpen, setOpen] = createSignal(false);
  const [anchor, setAnchor] = createSignal();
  const [popper2, setPopper] = createSignal();
  const popperRef = y(anchor, popper2, props.options);
  let timeout;
  const showTip = () => {
    timeout = setTimeout(() => {
      if (props.disabled)
        return;
      setOpen(true);
    }, props.delay || 100);
  };
  const hideTip = () => {
    if (timeout) {
      clearInterval(timeout);
    }
    setOpen(false);
  };
  const showEvents = ["mouseenter", "focus"];
  const hideEvents = ["mouseleave", "blur", "click"];
  onMount(() => {
    showEvents.forEach((event) => {
      var _a;
      (_a = anchor()) == null ? void 0 : _a.addEventListener(event, showTip);
    });
    hideEvents.forEach((event) => {
      var _a;
      (_a = anchor()) == null ? void 0 : _a.addEventListener(event, hideTip);
    });
  });
  onCleanup(() => {
    var _a;
    showEvents.forEach((event) => {
      var _a2;
      (_a2 = anchor()) == null ? void 0 : _a2.removeEventListener(event, showTip);
    });
    hideEvents.forEach((event) => {
      var _a2;
      (_a2 = anchor()) == null ? void 0 : _a2.removeEventListener(event, hideTip);
    });
    (_a = popperRef()) == null ? void 0 : _a.destroy();
  });
  return (() => {
    const _el$ = _tmpl$2$5.cloneNode(true);
    setAnchor(_el$);
    insert(_el$, () => props.children, null);
    insert(_el$, createComponent(Show, {
      get when() {
        return isOpen();
      },
      get children() {
        const _el$2 = _tmpl$$z.cloneNode(true);
        setPopper(_el$2);
        insert(_el$2, () => props.label);
        createRenderEffect(() => _el$2.className = styles$f.tooltip);
        return _el$2;
      }
    }), null);
    return _el$;
  })();
};
const TooltipIconButton = (props) => {
  props = mergeProps({
    offset: [0, 26]
  }, props);
  const [t] = useI18n();
  return createComponent(Tooltip, {
    get disabled() {
      return BrowserUtils.isMobile;
    },
    get label() {
      return t(props.translateLabelKey);
    },
    get options() {
      return {
        placement: "top",
        modifiers: [{
          name: "offset",
          options: {
            offset: props.offset
          }
        }]
      };
    },
    get children() {
      return createComponent(Button, {
        ref(r$) {
          const _ref$ = props.ref;
          typeof _ref$ === "function" ? _ref$(r$) : props.ref = r$;
        },
        get testId() {
          return props.testId;
        },
        className: "text-white",
        get onClick() {
          return props.onClick;
        },
        get label() {
          return t(props.translateLabelKey);
        },
        get isActive() {
          return props.isActive;
        },
        get children() {
          return props.children;
        }
      });
    }
  });
};
const _tmpl$$y = /* @__PURE__ */ template$1(`<div data-testid="Spacer"></div>`);
const Spacer = (props) => {
  return (() => {
    const _el$ = _tmpl$$y.cloneNode(true);
    createRenderEffect((_p$) => {
      var _a, _b;
      const _v$ = (_a = props.size) != null ? _a : "0.5rem", _v$2 = (_b = props.size) != null ? _b : "0.5rem";
      _v$ !== _p$._v$ && _el$.style.setProperty("width", _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && _el$.style.setProperty("height", _p$._v$2 = _v$2);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0
    });
    return _el$;
  })();
};
const RenderPlugins = (props) => {
  const {
    uiComponentStore
  } = useVxdkContext();
  const components = () => {
    if (props.location === UIPluginLocation.CONTAINER) {
      return uiComponentStore.containerPlugins;
    }
    if (props.location === UIPluginLocation.CONTROL_BAR) {
      return uiComponentStore.controlBarPlugins;
    }
    return [];
  };
  return createComponent(For, {
    get each() {
      return components();
    },
    children: (Plugin2) => [memo((() => {
      const _c$ = memo(() => !!props.spacer, true);
      return () => _c$() && createComponent(Spacer, {});
    })()), createComponent(Plugin2, {})]
  });
};
const ContainerPlugins = () => {
  return createComponent(RenderPlugins, {
    get location() {
      return UIPluginLocation.CONTAINER;
    }
  });
};
const PortalPlugins = () => {
  return createComponent(RenderPlugins, {
    get location() {
      return UIPluginLocation.PORTAL;
    }
  });
};
const ControlBarPlugins = () => {
  return createComponent(RenderPlugins, {
    spacer: true,
    get location() {
      return UIPluginLocation.CONTROL_BAR;
    }
  });
};
const button$3 = {
  play: "Play (k)",
  pause: "Pause (k)",
  mute: "Mute (m)",
  unmute: "Unmute (m)",
  settings: "Settings",
  miniplayer: "Miniplayer",
  fullscreen: "Fullscreen",
  exit_fullscreen: "Exit Fullscreen",
  airplay: "AirPlay"
};
const settings_menu$1 = {
  title: "Settings",
  option: {
    speed: "Speed",
    quality: "Quality"
  }
};
var en = {
  button: button$3,
  settings_menu: settings_menu$1
};
const button$2 = {
  play: "Tocar (k)",
  pause: "Pausar (k)",
  mute: "Mudo (m)",
  unmute: "Habilitar Som (m)",
  settings: "Configura\xE7\xF5es",
  miniplayer: "Miniplayer",
  fullscreen: "Tela Cheia",
  exit_fullscreen: "Tela Normal"
};
const settings_menu = {
  title: "Configura\xE7\xF5es",
  option: {
    speed: "Velocidade",
    quality: "Qualidade"
  }
};
var ptBR = {
  button: button$2,
  settings_menu
};
const resizeObserver = (el, callback) => {
  const resize = new ResizeObserver(callback);
  resize.observe(el);
  return { remove: () => resize.unobserve(el) };
};
class DimensionsPlugin extends Plugin {
  constructor(ctx) {
    super(ctx);
    __publicField(this, "name", "dimensions");
    __publicField(this, "_observer");
    __publicField(this, "_width");
    __publicField(this, "_height");
    const rect = this.controller.getRootElement().getBoundingClientRect();
    this._height = rect.height;
    this._width = rect.width;
    const debouncedOnResizeContainer = FunctionUtils.debounce(this._updateDimensions.bind(this), 250);
    this._observer = resizeObserver(this.controller.getRootElement(), debouncedOnResizeContainer.bind(this));
  }
  connectedCallback() {
    this.on(CONTEXT_EVENT.READY, this._updateDimensions.bind(this));
  }
  disconnectedCallback() {
    this._observer.remove();
  }
  _updateDimensions() {
    const rect = this.controller.getRootElement().getBoundingClientRect();
    this._height = rect.height;
    this._width = rect.width;
    this.emit(CONTEXT_EVENT.DIMENSION_CHANGED);
  }
  get width() {
    return this._width;
  }
  get height() {
    return this._height;
  }
}
const prefixes = ["", "moz", "webkit", "ms"];
var FullscreenUtils;
((FullscreenUtils2) => {
  function fullscreenElement() {
    return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
  }
  FullscreenUtils2.fullscreenElement = fullscreenElement;
  function onChange(callback) {
    prefixes.forEach(function(prefix) {
      document.addEventListener(prefix + "fullscreenchange", () => callback());
    });
  }
  FullscreenUtils2.onChange = onChange;
  function onError(callback) {
    prefixes.forEach(function(prefix) {
      document.addEventListener(prefix + "fullscreenerror", (err) => callback(err));
    });
  }
  FullscreenUtils2.onError = onError;
  function request(el) {
    const elem = el;
    if (elem.requestFullscreen) {
      return elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      if (typeof elem.then === "function")
        return elem.webkitRequestFullscreen();
      elem.webkitRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      return elem.mozRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      return elem.msRequestFullscreen();
    } else if (elem.querySelector && elem.querySelector("video") && elem.querySelector("video").webkitEnterFullScreen) {
      elem.querySelector("video").webkitEnterFullScreen();
    } else if (elem.webkitEnterFullScreen) {
      elem.webkitEnterFullScreen();
    }
  }
  FullscreenUtils2.request = request;
  function isFullscreen() {
    return !!fullscreenElement();
  }
  FullscreenUtils2.isFullscreen = isFullscreen;
  function exit() {
    const doc = document;
    if (doc.exitFullscreen)
      doc.exitFullscreen();
    else if (doc.webkitExitFullscreen)
      doc.webkitExitFullscreen();
    else if (doc.webkitCancelFullScreen)
      doc.webkitCancelFullScreen();
    else if (doc.mozCancelFullScreen)
      doc.mozCancelFullScreen();
    else if (doc.msExitFullscreen)
      doc.msExitFullscreen();
  }
  FullscreenUtils2.exit = exit;
  function isEnabled() {
    return !!(document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled);
  }
  FullscreenUtils2.isEnabled = isEnabled;
})(FullscreenUtils || (FullscreenUtils = {}));
var isFunction$1 = (value) => typeof value === "function";
var isObject$1 = (val) => toString.call(val) === "[object Object]";
var isArray$1 = Array.isArray;
var access = (v) => isFunction$1(v) && !v.length ? v() : v;
var __defProp2 = Object.defineProperty;
var __defProps2 = Object.defineProperties;
var __getOwnPropDescs2 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols2 = Object.getOwnPropertySymbols;
var __hasOwnProp2 = Object.prototype.hasOwnProperty;
var __propIsEnum2 = Object.prototype.propertyIsEnumerable;
var __defNormalProp2 = (obj, key, value) => key in obj ? __defProp2(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues2 = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp2.call(b, prop))
      __defNormalProp2(a, prop, b[prop]);
  if (__getOwnPropSymbols2)
    for (var prop of __getOwnPropSymbols2(b)) {
      if (__propIsEnum2.call(b, prop))
        __defNormalProp2(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps2 = (a, b) => __defProps2(a, __getOwnPropDescs2(b));
var isReactiveObject = (value) => isObject$1(value) || isArray$1(value);
function createProxyCache(obj, get) {
  return new Proxy({}, {
    get: (target, key) => {
      if (key === Symbol.iterator || key === "length")
        return Reflect.get(obj, key);
      const saved = Reflect.get(target, key);
      if (saved)
        return saved;
      const value = get(key);
      Reflect.set(target, key, value);
      return value;
    },
    set: () => false
  });
}
function destructure(source, options) {
  var _a;
  const config = options != null ? options : {};
  const memo2 = (_a = config.memo) != null ? _a : isFunction$1(source);
  const getter = isFunction$1(source) ? (key) => () => source()[key] : (key) => () => source[key];
  const obj = access(source);
  if (config.lazy) {
    const owner = getOwner();
    return createProxyCache(obj, (key) => {
      const calc = getter(key);
      if (config.deep && isReactiveObject(obj[key]))
        return runWithOwner(owner, () => destructure(calc, __spreadProps2(__spreadValues2({}, config), { memo: memo2 })));
      return memo2 ? runWithOwner(owner, () => createMemo(calc, void 0, options)) : calc;
    });
  }
  const result = obj.constructor();
  for (const [key, value] of Object.entries(obj)) {
    const calc = getter(key);
    if (config.deep && isReactiveObject(value))
      result[key] = destructure(calc, __spreadProps2(__spreadValues2({}, config), { memo: memo2 }));
    else
      result[key] = memo2 ? createMemo(calc, void 0, options) : calc;
  }
  return result;
}
function useVxdkState() {
  const { state } = useVxdkContext();
  return destructure(state);
}
const _tmpl$$x = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M10 21v-2H6.41l4.5-4.5-1.41-1.41-4.5 4.5V14H3v7h7m4.5-10.09 4.5-4.5V10h2V3h-7v2h3.59l-4.5 4.5 1.41 1.41Z"/></svg>`);
var EnterFullScreenIcon = (props = {}) => {
  const _el$ = _tmpl$$x.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const _tmpl$$w = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M19.5 3.09 15 7.59V4h-2v7h7V9h-3.59l4.5-4.5-1.41-1.41M4 13v2h3.59l-4.5 4.5 1.41 1.41 4.5-4.5V20h2v-7H4Z"/></svg>`);
var ExitFullScreenIcon = (props = {}) => {
  const _el$ = _tmpl$$w.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const FullScreenButton = () => {
  const vxdk = useVxdk();
  const {
    fullscreen
  } = useVxdkState();
  const label2 = () => fullscreen() ? "button.exit_fullscreen" : "button.fullscreen";
  const icon2 = () => fullscreen() ? createComponent(ExitFullScreenIcon, {}) : createComponent(EnterFullScreenIcon, {});
  const onPress = () => {
    vxdk.toggleFullscreen();
  };
  return createComponent(TooltipIconButton, {
    onClick: onPress,
    get translateLabelKey() {
      return label2();
    },
    offset: [-24, 26],
    get children() {
      return icon2();
    }
  });
};
class FullscreenPlugin extends UIControlBarPlugin {
  constructor() {
    super(...arguments);
    __publicField(this, "name", "fullscreen");
    __publicField(this, "order", 10);
    __publicField(this, "documentPos", null);
    __publicField(this, "render", () => {
      return createComponent(FullScreenButton, {});
    });
  }
  connectedCallback() {
    if (FullscreenUtils.isEnabled()) {
      FullscreenUtils.onChange(() => {
        this._handleDocumentPos(FullscreenUtils.isFullscreen());
        this.emit(CONTEXT_EVENT.FULLSCREEN_CHANGED);
      });
      FullscreenUtils.onError((err) => {
        console.error(err);
      });
    }
  }
  get isFullscreen() {
    return FullscreenUtils.isFullscreen();
  }
  enter() {
    try {
      FullscreenUtils.request(this.controller.getRootElement());
    } catch (err) {
      if (BrowserUtils.isIOS) {
        const playback = this.controller.getPlaybackAdapter();
        playback.nativeEl.webkitEnterFullscreen();
      }
    }
  }
  exit() {
    FullscreenUtils.exit();
  }
  toggleFullscreen() {
    this.isFullscreen ? this.exit() : this.enter();
  }
  _handleDocumentPos(isFullscreen) {
    if (isFullscreen) {
      const x = window.pageXOffset;
      const y2 = window.pageYOffset;
      if (x || y2) {
        this.documentPos = {
          x: x || 0,
          y: y2 || 0
        };
      }
    } else {
      if (!this.documentPos)
        return;
      window.scrollTo(this.documentPos.x, this.documentPos.y);
      this.documentPos = null;
    }
  }
}
const _tmpl$$v = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M19 11h-8v6h8v-6m4 8V5c0-1.12-.9-2-2-2H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2m-2 0H3V4.97h18V19Z"/></svg>`);
var PipIcon = (props = {}) => {
  const _el$ = _tmpl$$v.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const PipButton = (props) => {
  return createComponent(TooltipIconButton, {
    get onClick() {
      return props.onPress;
    },
    translateLabelKey: "button.miniplayer",
    get children() {
      return createComponent(PipIcon, {});
    }
  });
};
class PipPlugin extends UIPlugin {
  constructor(ctx) {
    super(ctx);
    __publicField(this, "name", "pip");
    __publicField(this, "isPip", false);
    __publicField(this, "renderLocation", UIPluginLocation.CONTROL_BAR);
    __publicField(this, "render", () => {
      const _self$ = this;
      return createComponent(PipButton, {
        onPress: () => _self$.togglePip()
      });
    });
    this._mediaElement = ctx.playback.nativeEl;
  }
  connectedCallback() {
    var _a;
    if (!this.isPipSupported)
      return;
    (_a = this._mediaElement) == null ? void 0 : _a.addEventListener("enterpictureinpicture", () => {
      this.isPip = true;
      this.emit(CONTEXT_EVENT.PIP_CHANGED);
    });
    this._mediaElement.addEventListener("leavepictureinpicture", () => {
      this.isPip = false;
      this.emit(CONTEXT_EVENT.PIP_CHANGED);
    });
  }
  get isPipSupported() {
    return "pictureInPictureEnabled" in document;
  }
  async enter() {
    if (!this.isPipSupported)
      return;
    if (this.controller.isFullscreen()) {
      this.controller.exitFullscreen();
    }
    await this._mediaElement.requestPictureInPicture();
  }
  async exit() {
    if (document.pictureInPictureElement) {
      await document.exitPictureInPicture();
    }
  }
  async togglePip() {
    this.isPip ? await this.exit() : await this.enter();
  }
}
const _Controller = class {
  constructor(ctx) {
    __publicField(this, "hook");
    __publicField(this, "load", () => {
      var _a;
      this.ctx.playback.load((_a = this.ctx.options.startTime) != null ? _a : 0);
    });
    __publicField(this, "setSource", (src) => {
      this.ctx.playback.setSource(src);
    });
    __publicField(this, "play", () => {
      this.ctx.playback.play();
    });
    __publicField(this, "togglePlay", () => {
      if (this.isPlaying()) {
        this.pause();
      } else {
        this.play();
      }
    });
    __publicField(this, "replay", () => {
      this.ctx.playback.replay();
    });
    __publicField(this, "isPlaying", () => {
      return this.ctx.playback.isPlaying;
    });
    __publicField(this, "getVersion", () => {
      return this.ctx.version;
    });
    __publicField(this, "pause", () => {
      this.ctx.playback.pause();
    });
    __publicField(this, "stop", () => {
      this.ctx.playback.stop();
    });
    __publicField(this, "seekTo", (time2) => {
      this.ctx.playback.seekTo(time2);
    });
    __publicField(this, "seekToPercentage", (percentage) => {
      const duration = this.getDuration();
      this.seekTo(duration / 100 * percentage);
    });
    __publicField(this, "setVolume", (volume) => {
      if (this.ctx.playback.setVolume) {
        this.ctx.playback.setVolume(volume);
      }
    });
    __publicField(this, "getVolume", () => {
      return this.ctx.playback.volume;
    });
    __publicField(this, "getAutoPlay", () => {
      return this.ctx.options.autoPlay;
    });
    __publicField(this, "toggleMute", () => {
      if (this.ctx.playback.isMuted) {
        this.unmute();
      } else {
        this.mute();
      }
    });
    __publicField(this, "isMuted", () => {
      return this.ctx.playback.isMuted;
    });
    __publicField(this, "mute", () => {
      this.ctx.playback.mute();
    });
    __publicField(this, "unmute", () => {
      this.ctx.playback.unmute();
      if (this.ctx.playback.volume === 0) {
        this.ctx.playback.setVolume(0.1);
      }
    });
    __publicField(this, "setLevel", (level) => {
      this.ctx.playback.selectLevel(level);
    });
    __publicField(this, "selectAudioLanguage", (language) => {
      this.ctx.playback.selectAudioLanguage(language);
    });
    __publicField(this, "setPlaybackRate", (playbackRate) => {
      this.ctx.playback.setPlaybackRate(playbackRate);
    });
    __publicField(this, "getPlaybackRate", () => {
      return this.ctx.playback.playbackRate;
    });
    __publicField(this, "getPlaybackType", () => {
      return this.ctx.playback.playbackType;
    });
    __publicField(this, "getPlaybackAdapter", () => {
      return this.ctx.playback;
    });
    __publicField(this, "getDuration", () => {
      return this.ctx.playback.duration;
    });
    __publicField(this, "getCurrentLevel", () => {
      return this.ctx.playback.currentLevel;
    });
    __publicField(this, "getCurrentTime", () => {
      return this.ctx.playback.currentTime;
    });
    __publicField(this, "isAutoLevelEnabled", () => {
      return this.ctx.playback.autoLevelEnabled;
    });
    __publicField(this, "getLevels", () => {
      return this.ctx.playback.levels;
    });
    __publicField(this, "getBufferedPercentage", () => {
      return this.ctx.playback.bufferedPercentage;
    });
    __publicField(this, "getState", () => {
      return this.ctx.state;
    });
    __publicField(this, "isLive", () => {
      return this.ctx.playback.isLive;
    });
    __publicField(this, "enterFullscreen", () => {
      this.getPlugin(FullscreenPlugin).enter();
    });
    __publicField(this, "exitFullscreen", () => {
      this.getPlugin(FullscreenPlugin).exit();
    });
    __publicField(this, "isFullscreen", () => {
      return this.getPlugin(FullscreenPlugin).isFullscreen;
    });
    __publicField(this, "toggleFullscreen", () => {
      this.getPlugin(FullscreenPlugin).toggleFullscreen();
    });
    __publicField(this, "enterPip", () => {
      this.getPlugin(PipPlugin).enter();
    });
    __publicField(this, "exitPip", () => {
      this.getPlugin(PipPlugin).exit();
    });
    __publicField(this, "isPip", () => {
      return this.getPlugin(PipPlugin).isPip;
    });
    __publicField(this, "togglePip", () => {
      this.getPlugin(PipPlugin).togglePip();
    });
    __publicField(this, "getRootElement", () => {
      return this.ctx.$uiContainer;
    });
    __publicField(this, "getWidth", () => {
      return this.getPlugin(DimensionsPlugin).width;
    });
    __publicField(this, "getHeight", () => {
      return this.getPlugin(DimensionsPlugin).height;
    });
    __publicField(this, "getPlugin", (pluginClass) => {
      return this.ctx.getPlugin(pluginClass);
    });
    __publicField(this, "getPluginByName", (name) => {
      return this.ctx.getPluginByName(name);
    });
    __publicField(this, "getOptions", () => {
      return this.ctx.options.options;
    });
    __publicField(this, "getContext", () => {
      return this.ctx;
    });
    __publicField(this, "destroy", () => {
      this.ctx.destroy();
    });
    this.ctx = ctx;
    this.hook = new Hook(this);
  }
  get on() {
    return this.ctx.on.bind(this.ctx);
  }
  get off() {
    return this.ctx.off.bind(this.ctx);
  }
  get once() {
    return this.ctx.once.bind(this.ctx);
  }
  get emit() {
    return this.ctx.emit.bind(this.ctx);
  }
};
let Controller = _Controller;
__publicField(Controller, "create", (ctx) => {
  return new _Controller(ctx);
});
const cloneState = (state) => {
  return __spreadProps(__spreadValues({}, state), {
    level: __spreadValues({}, state.level),
    levels: state.levels.map((level) => __spreadValues({}, level)),
    audioLanguages: [...state.audioLanguages]
  });
};
const cloneDefaultState = () => {
  return cloneState(VXDKDefaultState);
};
const VXDKDefaultState = {
  waitingForUser: true,
  ready: false,
  started: false,
  ended: false,
  playing: false,
  paused: false,
  buffering: false,
  focused: false,
  live: false,
  hd: false,
  currentTime: 0,
  duration: 0,
  options: {},
  bufferedPercentage: 0,
  volume: 1,
  muted: false,
  fullscreen: false,
  pip: false,
  levels: [],
  level: null,
  levelAutoSwitch: false,
  playbackRate: 1,
  audioLanguages: [],
  width: null,
  height: null
};
function createVxdkState(controller) {
  const [state, setState] = createStore(cloneDefaultState());
  const reconcileState = () => {
    const contextState = controller.getState();
    setState(reconcile(contextState));
  };
  onMount(() => {
    controller.on(CONTEXT_EVENT.STATE_CHANGED, reconcileState);
  });
  onCleanup(() => {
    controller.off(CONTEXT_EVENT.STATE_CHANGED, reconcileState);
  });
  return state;
}
let SettingsNavigation$1;
(function(SettingsNavigation2) {
  SettingsNavigation2[SettingsNavigation2["closed"] = 0] = "closed";
  SettingsNavigation2[SettingsNavigation2["main"] = 1] = "main";
  SettingsNavigation2[SettingsNavigation2["speed"] = 2] = "speed";
  SettingsNavigation2[SettingsNavigation2["quality"] = 3] = "quality";
})(SettingsNavigation$1 || (SettingsNavigation$1 = {}));
const SettingsContext = createContext();
const playbackRateOptions = [0.25, 0.5, 1, 1.25, 1.5, 2];
const SettingsProvider = (props) => {
  const vxdk = useVxdk();
  const {
    playbackRate,
    levels,
    levelAutoSwitch,
    level
  } = useVxdkState();
  const onPlaybackRateChange = (valueKey) => {
    const selected = playbackRateOptions.find((rate) => String(rate) === valueKey);
    vxdk.setPlaybackRate(selected);
  };
  const rateOptions = createMemo(() => {
    const currentRate = playbackRate();
    return playbackRateOptions.map((rate) => {
      return {
        active: rate === currentRate,
        key: String(rate),
        label: rate === 1 ? "Normal" : `${rate}`
      };
    });
  });
  const onQualityChange = (valueKey) => {
    const selected = levels().find((level2) => String(level2.id) === valueKey);
    const selectedOption = selected || "auto";
    vxdk.setLevel(selectedOption);
  };
  const levelOptions = createMemo(() => {
    var _a;
    const currentLevel = level();
    const options = levels().map((level2) => {
      const auto2 = levelAutoSwitch();
      const isActive = (currentLevel == null ? void 0 : currentLevel.id) === level2.id && !auto2;
      return {
        active: isActive,
        key: String(level2.id),
        label: `${level2.height}p`
      };
    });
    options.push({
      active: levelAutoSwitch(),
      key: "auto",
      label: levelAutoSwitch() ? `Auto (${(_a = level()) == null ? void 0 : _a.height}p)` : "Auto"
    });
    return options;
  });
  const rateValue = () => {
    return playbackRate();
  };
  const levelValue = () => {
    var _a;
    const auto2 = levelAutoSwitch();
    const currentLevel = auto2 ? "auto" : (_a = level()) == null ? void 0 : _a.id;
    return currentLevel;
  };
  const levelLabel = () => {
    var _a, _b;
    return levelAutoSwitch() ? `Auto (${(_a = level()) == null ? void 0 : _a.height}p)` : `${(_b = level()) == null ? void 0 : _b.height}p`;
  };
  const store = {
    onPlaybackRateChange,
    rateOptions,
    rateValue,
    onQualityChange,
    levelOptions,
    levelValue,
    levelLabel
  };
  return createComponent(SettingsContext.Provider, {
    value: store,
    get children() {
      return props.children;
    }
  });
};
function useSettings() {
  const settings = useContext(SettingsContext);
  if (!settings) {
    throw new Error("Settings context not found");
  }
  return settings;
}
const container$2 = "_container_1exam_1";
const is_iframe = "_is_iframe_1exam_5";
var styles$e = {
  container: container$2,
  is_iframe,
  "aspect-w-1": "_aspect-w-1_1exam_1",
  "aspect-w-2": "_aspect-w-2_1exam_1",
  "aspect-w-3": "_aspect-w-3_1exam_1",
  "aspect-w-4": "_aspect-w-4_1exam_1",
  "aspect-w-5": "_aspect-w-5_1exam_1",
  "aspect-w-6": "_aspect-w-6_1exam_1",
  "aspect-w-7": "_aspect-w-7_1exam_1",
  "aspect-w-8": "_aspect-w-8_1exam_1",
  "aspect-w-9": "_aspect-w-9_1exam_1",
  "aspect-w-10": "_aspect-w-10_1exam_1",
  "aspect-w-11": "_aspect-w-11_1exam_1",
  "aspect-w-12": "_aspect-w-12_1exam_1",
  "aspect-w-13": "_aspect-w-13_1exam_1",
  "aspect-w-14": "_aspect-w-14_1exam_1",
  "aspect-w-15": "_aspect-w-15_1exam_1",
  "aspect-w-16": "_aspect-w-16_1exam_1"
};
const _tmpl$$u = /* @__PURE__ */ template$1(`<div data-testid="root_container"></div>`);
const dict = {
  en,
  "pt-BR": ptBR
};
const UIContainerContext = createContext({});
const useVxdkContext = () => useContext(UIContainerContext);
const UIContainer = (props) => {
  const state = createVxdkState(props.controller);
  return createComponent(I18nProvider, {
    dict,
    get children() {
      return createComponent(UIContainerContext.Provider, {
        get value() {
          return {
            controller: props.controller,
            state,
            uiComponentStore: props.store
          };
        },
        get children() {
          return createComponent(SettingsProvider, {
            get children() {
              const _el$ = _tmpl$$u.cloneNode(true);
              const _ref$ = props.ref;
              typeof _ref$ === "function" ? _ref$(_el$) : props.ref = _el$;
              insert(_el$, createComponent(ContainerPlugins, {}), null);
              insert(_el$, createComponent(PortalPlugins, {}), null);
              createRenderEffect((_p$) => {
                const _v$ = styles$e.container, _v$2 = {
                  [styles$e.is_iframe]: BrowserUtils.isIframe
                };
                _v$ !== _p$._v$ && (_el$.className = _p$._v$ = _v$);
                _p$._v$2 = classList(_el$, _v$2, _p$._v$2);
                return _p$;
              }, {
                _v$: void 0,
                _v$2: void 0
              });
              return _el$;
            }
          });
        }
      });
    }
  });
};
function useVxdkController(selectFn) {
  const { controller } = useVxdkContext();
  return selectFn(controller);
}
function useVxdk() {
  return useVxdkController(selectController);
}
function selectController(controller) {
  return controller;
}
function useVxdkEvent(eventName, callback, once = false) {
  const controller = useVxdk();
  onMount(() => {
    if (once) {
      controller.once(eventName, () => callback());
    } else {
      controller.on(eventName, () => callback());
    }
  });
  onCleanup(() => {
    !once && controller.off(eventName, () => callback());
  });
  return;
}
function useVxdkPlugin(pluginClass) {
  return useVxdkController(selectPlugin(pluginClass));
}
function selectPlugin(pluginClass) {
  return (controller) => {
    return controller.getPlugin(pluginClass);
  };
}
const _tmpl$$t = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M6 22h12l-6-6m9-13H3a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4v-2H3V5h18v12h-4v2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"/></svg>`);
var AirPlayIcon = (props = {}) => {
  const _el$ = _tmpl$$t.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const AirPlayButton = () => {
  const [show, setShow] = createSignal(false);
  const airplay = useVxdkPlugin(AirPlayPlugin);
  useVxdkEvent(AIRPLAY_EVENTS.AVAILABILITY_CHANGED, () => {
    setShow(airplay.isEnabled());
  });
  return createComponent(Show, {
    get when() {
      return show();
    },
    get children() {
      return createComponent(TooltipIconButton, {
        translateLabelKey: "button.airplay",
        onClick: () => {
          airplay.showPlaybackTargetPicker();
        },
        get children() {
          return createComponent(AirPlayIcon, {});
        }
      });
    }
  });
};
let AIRPLAY_EVENTS;
(function(AIRPLAY_EVENTS2) {
  AIRPLAY_EVENTS2["AVAILABILITY_CHANGED"] = "airplay.availability.changed";
  AIRPLAY_EVENTS2["PLAYBACK_TARGET_CHANGED"] = "airplay.playback.target.changed";
})(AIRPLAY_EVENTS || (AIRPLAY_EVENTS = {}));
const airplayAvailableEvent = "webkitplaybacktargetavailabilitychanged";
const airplayTargetEvent = "webkitcurrentplaybacktargetiswirelesschanged";
class AirPlayPlugin extends UIControlBarPlugin {
  constructor(ctx) {
    super(ctx);
    __publicField(this, "name", "airplay");
    __publicField(this, "_enabled", false);
    __publicField(this, "isEnabled", () => {
      return this._enabled;
    });
    __publicField(this, "render", () => {
      return createComponent(AirPlayButton, {});
    });
  }
  get events() {
    return [AIRPLAY_EVENTS.AVAILABILITY_CHANGED, AIRPLAY_EVENTS.PLAYBACK_TARGET_CHANGED];
  }
  connectedCallback() {
    const availableCb = (event) => {
      this._enabled = event.availability === "available";
      if (this._enabled) {
        this._nativeEl.removeEventListener(airplayAvailableEvent, availableCb);
      }
      this.emit(AIRPLAY_EVENTS.AVAILABILITY_CHANGED);
    };
    this._nativeEl.addEventListener(airplayAvailableEvent, availableCb);
    this._nativeEl.addEventListener(airplayTargetEvent, () => {
      this.emit(AIRPLAY_EVENTS.PLAYBACK_TARGET_CHANGED);
    });
  }
  get _nativeEl() {
    const playback = this.controller.getContext().playback;
    return playback.nativeEl;
  }
  showPlaybackTargetPicker() {
    this._nativeEl.webkitShowPlaybackTargetPicker();
  }
}
const AirPlayPluginLoader = {
  get: () => AirPlayPlugin,
  isSupported: ({ playback }) => isHTML5Player(playback) && Boolean(window.WebKitPlaybackTargetAvailabilityEvent)
};
var canAutoPlay;
((canAutoPlay2) => {
  function setupDefaultValues(options) {
    return Object.assign({
      muted: false,
      timeout: 250,
      inline: false
    }, options);
  }
  canAutoPlay2.setupDefaultValues = setupDefaultValues;
  function startPlayback(options, elementCallback) {
    const { element, source } = elementCallback();
    const { muted, timeout, inline } = options;
    let playResult;
    let timeoutId;
    let sendOutput;
    element.muted = muted != null ? muted : false;
    if (muted === true) {
      element.setAttribute("muted", "muted");
    }
    if (inline === true) {
      element.setAttribute("playsinline", "playsinline");
    }
    element.src = source;
    return new Promise((resolve) => {
      playResult = element.play();
      timeoutId = setTimeout(() => {
        sendOutput(false, new Error(`Timeout ${timeout} ms has been reached`));
      }, timeout);
      sendOutput = (result, error = null) => {
        element.remove();
        element.srcObject = null;
        clearTimeout(timeoutId);
        resolve({ result, error });
      };
      if (playResult !== void 0) {
        playResult.then(() => sendOutput(true)).catch((playError) => sendOutput(false, playError));
      } else {
        sendOutput(true);
      }
    });
  }
  function video(options) {
    options = setupDefaultValues(options);
    return startPlayback(options, () => {
      return {
        element: document.createElement("video"),
        source: URL.createObjectURL(VIDEO)
      };
    });
  }
  canAutoPlay2.video = video;
  function audio(options) {
    options = setupDefaultValues(options);
    return startPlayback(options, () => {
      return {
        element: document.createElement("audio"),
        source: URL.createObjectURL(AUDIO)
      };
    });
  }
  canAutoPlay2.audio = audio;
  const AUDIO = new Blob([
    new Uint8Array([
      255,
      227,
      24,
      196,
      0,
      0,
      0,
      3,
      72,
      1,
      64,
      0,
      0,
      4,
      132,
      16,
      31,
      227,
      192,
      225,
      76,
      255,
      67,
      12,
      255,
      221,
      27,
      255,
      228,
      97,
      73,
      63,
      255,
      195,
      131,
      69,
      192,
      232,
      223,
      255,
      255,
      207,
      102,
      239,
      255,
      255,
      255,
      101,
      158,
      206,
      70,
      20,
      59,
      255,
      254,
      95,
      70,
      149,
      66,
      4,
      16,
      128,
      0,
      2,
      2,
      32,
      240,
      138,
      255,
      36,
      106,
      183,
      255,
      227,
      24,
      196,
      59,
      11,
      34,
      62,
      80,
      49,
      135,
      40,
      0,
      253,
      29,
      191,
      209,
      200,
      141,
      71,
      7,
      255,
      252,
      152,
      74,
      15,
      130,
      33,
      185,
      6,
      63,
      255,
      252,
      195,
      70,
      203,
      86,
      53,
      15,
      255,
      255,
      247,
      103,
      76,
      121,
      64,
      32,
      47,
      255,
      34,
      227,
      194,
      209,
      138,
      76,
      65,
      77,
      69,
      51,
      46,
      57,
      55,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      255,
      227,
      24,
      196,
      73,
      13,
      153,
      210,
      100,
      81,
      135,
      56,
      0,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170,
      170
    ])
  ], { type: "audio/mpeg" });
  const VIDEO = new Blob([
    new Uint8Array([
      0,
      0,
      0,
      28,
      102,
      116,
      121,
      112,
      105,
      115,
      111,
      109,
      0,
      0,
      2,
      0,
      105,
      115,
      111,
      109,
      105,
      115,
      111,
      50,
      109,
      112,
      52,
      49,
      0,
      0,
      0,
      8,
      102,
      114,
      101,
      101,
      0,
      0,
      2,
      239,
      109,
      100,
      97,
      116,
      33,
      16,
      5,
      32,
      164,
      27,
      255,
      192,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      55,
      167,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      112,
      33,
      16,
      5,
      32,
      164,
      27,
      255,
      192,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      55,
      167,
      128,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      112,
      0,
      0,
      2,
      194,
      109,
      111,
      111,
      118,
      0,
      0,
      0,
      108,
      109,
      118,
      104,
      100,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      3,
      232,
      0,
      0,
      0,
      47,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      64,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      3,
      0,
      0,
      1,
      236,
      116,
      114,
      97,
      107,
      0,
      0,
      0,
      92,
      116,
      107,
      104,
      100,
      0,
      0,
      0,
      3,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      2,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      47,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      64,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      36,
      101,
      100,
      116,
      115,
      0,
      0,
      0,
      28,
      101,
      108,
      115,
      116,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      47,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      100,
      109,
      100,
      105,
      97,
      0,
      0,
      0,
      32,
      109,
      100,
      104,
      100,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      172,
      68,
      0,
      0,
      8,
      0,
      85,
      196,
      0,
      0,
      0,
      0,
      0,
      45,
      104,
      100,
      108,
      114,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      115,
      111,
      117,
      110,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      83,
      111,
      117,
      110,
      100,
      72,
      97,
      110,
      100,
      108,
      101,
      114,
      0,
      0,
      0,
      1,
      15,
      109,
      105,
      110,
      102,
      0,
      0,
      0,
      16,
      115,
      109,
      104,
      100,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      36,
      100,
      105,
      110,
      102,
      0,
      0,
      0,
      28,
      100,
      114,
      101,
      102,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      12,
      117,
      114,
      108,
      32,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      211,
      115,
      116,
      98,
      108,
      0,
      0,
      0,
      103,
      115,
      116,
      115,
      100,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      87,
      109,
      112,
      52,
      97,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      2,
      0,
      16,
      0,
      0,
      0,
      0,
      172,
      68,
      0,
      0,
      0,
      0,
      0,
      51,
      101,
      115,
      100,
      115,
      0,
      0,
      0,
      0,
      3,
      128,
      128,
      128,
      34,
      0,
      2,
      0,
      4,
      128,
      128,
      128,
      20,
      64,
      21,
      0,
      0,
      0,
      0,
      1,
      244,
      0,
      0,
      1,
      243,
      249,
      5,
      128,
      128,
      128,
      2,
      18,
      16,
      6,
      128,
      128,
      128,
      1,
      2,
      0,
      0,
      0,
      24,
      115,
      116,
      116,
      115,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      2,
      0,
      0,
      4,
      0,
      0,
      0,
      0,
      28,
      115,
      116,
      115,
      99,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      2,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      28,
      115,
      116,
      115,
      122,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      2,
      0,
      0,
      1,
      115,
      0,
      0,
      1,
      116,
      0,
      0,
      0,
      20,
      115,
      116,
      99,
      111,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      44,
      0,
      0,
      0,
      98,
      117,
      100,
      116,
      97,
      0,
      0,
      0,
      90,
      109,
      101,
      116,
      97,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      33,
      104,
      100,
      108,
      114,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      109,
      100,
      105,
      114,
      97,
      112,
      112,
      108,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      45,
      105,
      108,
      115,
      116,
      0,
      0,
      0,
      37,
      169,
      116,
      111,
      111,
      0,
      0,
      0,
      29,
      100,
      97,
      116,
      97,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      76,
      97,
      118,
      102,
      53,
      54,
      46,
      52,
      48,
      46,
      49,
      48,
      49
    ])
  ], { type: "video/mp4" });
})(canAutoPlay || (canAutoPlay = {}));
class AutoplayPlugin extends Plugin {
  constructor() {
    super(...arguments);
    __publicField(this, "name", "autoplay");
    __publicField(this, "_checked", false);
    __publicField(this, "_data", {
      autoplayAllowed: false,
      autoplayRequiresMuted: false
    });
  }
  get autoplayAllowed() {
    return this._data;
  }
  get autoplayRequiresMuted() {
    return this._data;
  }
  connectedCallback() {
    const data = this.storage.getItem(this.name);
    if (data) {
      this._checked = true;
      this._data = data;
    }
    this.on(PLAYBACK_EVENT.LOADSTART, () => {
      this._checkAutoPlay();
    });
  }
  async _checkAutoPlay() {
    if (this._checked === false) {
      const { result } = await canAutoPlay.video({
        timeout: 500,
        muted: false
      });
      if (result === false) {
        this._data.autoplayAllowed = false;
      } else {
        this._data.autoplayAllowed = true;
      }
      if (result === false) {
        const { result: mutedResult } = await canAutoPlay.video({
          timeout: 500,
          muted: true
        });
        if (mutedResult !== false) {
          this._data.autoplayRequiresMuted = true;
        }
      }
      this._checked = true;
      this.storage.setItem(this.name, this._data);
    }
    this._tryAutoPlay();
  }
  async _tryAutoPlay() {
    try {
      if (this._data.autoplayAllowed) {
        this.controller.play();
        return;
      }
      if (this._data.autoplayRequiresMuted) {
        if (this.controller.getOptions().muted) {
          this.controller.play();
          return;
        } else {
          console.warn("Autoplay requires video to be muted");
          this.controller.mute();
          this.controller.play();
          return;
        }
      }
    } catch (err) {
      console.warn("Autoplay not allowed");
    }
  }
}
const AutoPlayPluginLoader = {
  get: () => AutoplayPlugin,
  isSupported: ({ options }) => options.autoPlay
};
class PersistOptionsPlugin extends Plugin {
  constructor() {
    super(...arguments);
    __publicField(this, "name", "persist-mute");
    __publicField(this, "_isMuted", false);
    __publicField(this, "_playbackRate", 1);
  }
  connectedCallback() {
    const persistedIsMuted = this.storage.getItem("isMuted");
    const persistedPlaybackRate = this.storage.getItem("playbackRate");
    const ctx = this.controller.getContext();
    if (persistedIsMuted === null) {
      this._isMuted = ctx.options.muted;
    } else {
      this._isMuted = persistedIsMuted;
    }
    if (persistedPlaybackRate === null) {
      this._playbackRate = ctx.playback.playbackRate;
    } else {
      this._playbackRate = persistedPlaybackRate;
    }
    this.on(PLAYBACK_EVENT.LOADSTART, () => {
      this.controller.setPlaybackRate(this._playbackRate);
      if (this._isMuted) {
        this.controller.mute();
      } else {
        this.controller.unmute();
      }
    });
    this.on(PLAYBACK_EVENT.VOLUME_CHANGED, () => {
      const isMuted = this.controller.isMuted();
      this._isMuted = isMuted;
      try {
        this.storage.setItem("isMuted", isMuted);
      } catch (error) {
        console.error("Failed to persist isMuted:", error);
      }
    });
    this.on(PLAYBACK_EVENT.PLAYBACKRATE_CHANGE, () => {
      const rate = this.controller.getPlaybackRate();
      this._playbackRate = rate;
      try {
        this.storage.setItem("playbackRate", rate);
      } catch (error) {
        console.error("Failed to persist playbackRate:", error);
      }
    });
  }
}
const PersistOptionsPluginLoader = {
  get: () => PersistOptionsPlugin,
  isSupported: (_) => true
};
const SKIP_CURRENTTIME_OFFSET = 5;
const SKIP_VOLUME_OFFSET = 0.1;
class KeyboardNavigationPlugin extends Plugin {
  constructor() {
    super(...arguments);
    __publicField(this, "name", "keyboard");
    __publicField(this, "_hasFocus", false);
  }
  connectedCallback() {
    window.addEventListener("keydown", this.onKeyDown.bind(this));
    window.addEventListener("mousedown", this.onMouseDown.bind(this));
  }
  disconnectedCallback() {
    window.removeEventListener("keydown", this.onKeyDown.bind(this));
    window.removeEventListener("mousedown", this.onMouseDown.bind(this));
  }
  onMouseDown(event) {
    this._hasFocus = event.target === this.controller.getRootElement() || this.controller.getRootElement().contains(event.target);
  }
  onKeyDown(event) {
    if (this.controller.getOptions().keyboardNavigation !== "focus" || !this._hasFocus) {
      return;
    }
    let prevTime = this.controller.getCurrentTime() - SKIP_CURRENTTIME_OFFSET;
    let nextTime = this.controller.getCurrentTime() + SKIP_CURRENTTIME_OFFSET;
    let nextVolume = this.controller.getVolume() + SKIP_VOLUME_OFFSET;
    let prevVolume = this.controller.getVolume() - SKIP_VOLUME_OFFSET;
    switch (event.which || event.keyCode) {
      case 32:
      case 75:
        if (this.controller.isPlaying()) {
          this.controller.pause();
        } else {
          this.controller.play();
        }
        event.preventDefault();
        break;
      case 37:
        if (prevTime < 0) {
          prevTime = 0;
        }
        this.controller.seekTo(prevTime);
        event.preventDefault();
        break;
      case 39:
        if (nextTime > this.controller.getDuration()) {
          nextTime = this.controller.getDuration();
        }
        this.controller.seekTo(nextTime);
        event.preventDefault();
        break;
      case 38:
        if (nextVolume > 1) {
          nextVolume = 1;
        }
        this.controller.setVolume(nextVolume);
        event.preventDefault();
        break;
      case 40:
        if (prevVolume < 0) {
          prevVolume = 0;
        }
        this.controller.setVolume(prevVolume);
        event.preventDefault();
        break;
      case 77:
        if (this.controller.getVolume() > 0) {
          this.controller.setVolume(0);
        } else {
          this.controller.setVolume(1);
        }
        event.preventDefault();
        break;
      case 70:
        this.controller.toggleFullscreen();
        event.preventDefault();
        break;
      case 67:
        event.preventDefault();
        break;
      case 73:
        event.preventDefault();
        break;
    }
  }
}
const KeyboardNavigationPluginLoader = {
  get: () => KeyboardNavigationPlugin,
  isSupported: ({ options }) => !!options.keyboardNavigation
};
class LoadPlugin extends Plugin {
  constructor() {
    super(...arguments);
    __publicField(this, "name", "loadOnPlay");
    __publicField(this, "hasLoaded", false);
  }
  connectedCallback() {
    const triggerHasLoaded = () => {
      this.hasLoaded = true;
    };
    const onBeforePlay = async (next) => {
      if (!this.hasLoaded) {
        this.controller.load();
        this.hasLoaded = true;
        next();
        this.off(PLAYBACK_EVENT.LOADEDMETADATA, triggerHasLoaded);
        this.controller.hook.removeBefore("play", onBeforePlay);
      } else {
        next();
      }
    };
    this.on(PLAYBACK_EVENT.LOADEDMETADATA, triggerHasLoaded);
    this.controller.hook.before("play", onBeforePlay);
  }
}
class LoopPlugin extends Plugin {
  constructor() {
    super(...arguments);
    __publicField(this, "name", "loop");
    __publicField(this, "loop", false);
  }
  connectedCallback() {
    this.loop = this.getConfig();
    if (this.loop) {
      this.on(PLAYBACK_EVENT.ENDED, () => {
        this.controller.replay();
      });
    }
  }
}
const LoopPluginLoader = {
  get: () => LoopPlugin,
  isSupported: ({ options }) => options.loop
};
const PipPluginLoader = {
  get: () => PipPlugin,
  isSupported: ({ playback }) => {
    return document.pictureInPictureEnabled && isHTML5Player(playback);
  }
};
class StatePlugin extends Plugin {
  constructor(context) {
    super(context);
    __publicField(this, "name", "state");
    __publicField(this, "_state");
    this._state = cloneDefaultState();
  }
  connectedCallback() {
    this.controller.getRootElement().addEventListener("mouseenter", this._updateState((state) => {
      state.focused = true;
    }));
    this.controller.getRootElement().addEventListener("mouseleave", this._updateState((state) => {
      state.focused = false;
    }));
    this.on(CONTEXT_EVENT.READY, this._updateState((state, controller) => {
      state.ready = true;
      state.waitingForUser = true;
      state.options = controller.getOptions();
    }));
    this.on(CONTEXT_EVENT.OPTIONS_CHANGED, this._updateState((state, controller) => {
      state.options = controller.getOptions();
    }));
    this.on(PLAYBACK_EVENT.PLAY, this._updateState((state) => {
      state.paused = false;
    }));
    this.on(PLAYBACK_EVENT.PLAYING, this._updateState((state) => {
      state.waitingForUser = false;
      state.playing = true;
      state.started = true;
      state.buffering = false;
      state.paused = false;
      state.ended = false;
    }));
    this.on(PLAYBACK_EVENT.PAUSE, this._updateState((state) => {
      state.buffering = false;
      state.playing = false;
      state.paused = true;
    }));
    this.on(PLAYBACK_EVENT.LOADSTART, this._updateState((state) => {
      state.muted = this.controller.isMuted();
      state.volume = this.controller.getVolume();
    }));
    this.on(PLAYBACK_EVENT.TIMEUPDATE, this._updateState((state) => {
      state.currentTime = this.controller.getCurrentTime();
    }));
    this.on(PLAYBACK_EVENT.DURATION_CHANGED, this._updateState((state) => {
      state.duration = this.controller.getDuration();
    }));
    this.on(PLAYBACK_EVENT.WAITING, this._updateState((state) => {
      state.buffering = true;
      state.waitingForUser = false;
    }));
    this.on(PLAYBACK_EVENT.ENDED, this._updateState((state) => {
      state.started = false;
      state.playing = false;
      state.ended = true;
    }));
    this.on(PLAYBACK_EVENT.BUFFERED_CHANGED, this._updateState((state, controller) => {
      state.bufferedPercentage = controller.getBufferedPercentage();
    }));
    this.on(PLAYBACK_EVENT.VOLUME_CHANGED, this._updateState((state, controller) => {
      state.muted = controller.isMuted();
      state.volume = controller.getVolume();
    }));
    this.on(CONTEXT_EVENT.FULLSCREEN_CHANGED, this._updateState((state, controller) => {
      state.fullscreen = controller.isFullscreen();
    }));
    this.on(PLAYBACK_EVENT.LEVELS_LOADED, this._updateState((state, controller) => {
      const levels = controller.getLevels();
      state.levels = ArrayUtils.sortBy(levels, (level) => {
        var _a;
        return (_a = level.height) != null ? _a : level.id;
      });
      state.live = controller.isLive();
    }));
    this.on(PLAYBACK_EVENT.AUTOLEVEL_CHANGED, this._updateState((state, controller) => {
      state.levelAutoSwitch = controller.isAutoLevelEnabled();
    }));
    this.on(PLAYBACK_EVENT.LEVEL_CHANGED, this._updateState((state, controller) => {
      const level = controller.getCurrentLevel();
      state.level = level;
      state.hd = level.hd;
    }));
    this.on(PLAYBACK_EVENT.PLAYBACKRATE_CHANGE, this._updateState((state, controller) => {
      state.playbackRate = controller.getPlaybackRate();
    }));
    this.on(CONTEXT_EVENT.PIP_CHANGED, this._updateState((state, controller) => {
      state.pip = controller.isPip();
    }));
    this.on(CONTEXT_EVENT.DIMENSION_CHANGED, this._updateState((state, controller) => {
      state.width = controller.getWidth();
      state.height = controller.getHeight();
    }));
  }
  _updateState(mutateFn) {
    const controller = this.controller;
    return () => {
      const snapshot = cloneState(this._state);
      mutateFn(snapshot, controller);
      this._state = snapshot;
      this.emit(CONTEXT_EVENT.STATE_CHANGED);
    };
  }
  get state() {
    return this._state;
  }
}
let SettingsNavigation;
(function(SettingsNavigation2) {
  SettingsNavigation2[SettingsNavigation2["closed"] = 0] = "closed";
  SettingsNavigation2[SettingsNavigation2["main"] = 1] = "main";
  SettingsNavigation2[SettingsNavigation2["speed"] = 2] = "speed";
  SettingsNavigation2[SettingsNavigation2["quality"] = 3] = "quality";
})(SettingsNavigation || (SettingsNavigation = {}));
const SettingsMenuContext = createContext();
const SettingsMenuProvider = (props) => {
  const [navigation, setNavigation] = createSignal(SettingsNavigation.closed);
  const [isOpen, setIsOpen] = createSignal(false);
  const [inSubMenu, setInSubMenu] = createSignal(false);
  const [inMainMenu, setInMainMenu] = createSignal(false);
  createEffect(() => {
    setIsOpen(navigation() !== SettingsNavigation.closed);
    setInMainMenu(navigation() === SettingsNavigation.main);
    setInSubMenu(isOpen() && navigation() !== SettingsNavigation.main);
  });
  const goBack = () => {
    if (inSubMenu()) {
      setNavigation(SettingsNavigation.main);
    }
  };
  const toggleMenu = () => {
    if (isOpen()) {
      setNavigation(SettingsNavigation.closed);
    } else {
      setNavigation(SettingsNavigation.main);
    }
  };
  const goto = (navigation2) => {
    setNavigation(navigation2);
  };
  const store = {
    navigation,
    isOpen,
    inSubMenu,
    inMainMenu,
    goBack,
    toggleMenu,
    goto
  };
  return createComponent(SettingsMenuContext.Provider, {
    value: store,
    get children() {
      return props.children;
    }
  });
};
function useSettingsMenu() {
  const settingsMenu = useContext(SettingsMenuContext);
  if (!settingsMenu) {
    throw new Error("useSettingsMenu must be used within a SettingsMenuProvider");
  }
  return settingsMenu;
}
const time_display = "_time_display_1lf0p_1";
const time = "_time_1lf0p_1";
const ordinal = "_ordinal_1lf0p_1";
const separator = "_separator_1lf0p_9";
var styles$d = {
  time_display,
  time,
  "slashed-zero": "_slashed-zero_1lf0p_1",
  "lining-nums": "_lining-nums_1lf0p_1",
  "oldstyle-nums": "_oldstyle-nums_1lf0p_1",
  "proportional-nums": "_proportional-nums_1lf0p_1",
  "tabular-nums": "_tabular-nums_1lf0p_1",
  "diagonal-fractions": "_diagonal-fractions_1lf0p_1",
  "stacked-fractions": "_stacked-fractions_1lf0p_1",
  ordinal,
  separator
};
const _tmpl$$s = /* @__PURE__ */ template$1(`<div><time></time><span>/</span><time></time></div>`);
const TimeDisplay = (props) => {
  return (() => {
    const _el$ = _tmpl$$s.cloneNode(true), _el$2 = _el$.firstChild, _el$3 = _el$2.nextSibling, _el$4 = _el$3.nextSibling;
    insert(_el$2, () => props.currentTime);
    insert(_el$4, () => props.duration);
    createRenderEffect((_p$) => {
      const _v$ = styles$d.time_display, _v$2 = styles$d.time, _v$3 = styles$d.separator, _v$4 = styles$d.time;
      _v$ !== _p$._v$ && (_el$.className = _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && (_el$2.className = _p$._v$2 = _v$2);
      _v$3 !== _p$._v$3 && (_el$3.className = _p$._v$3 = _v$3);
      _v$4 !== _p$._v$4 && (_el$4.className = _p$._v$4 = _v$4);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0,
      _v$3: void 0,
      _v$4: void 0
    });
    return _el$;
  })();
};
const _tmpl$$r = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M14 19h4V5h-4M6 19h4V5H6v14Z"/></svg>`);
var PauseIcon = (props = {}) => {
  const _el$ = _tmpl$$r.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const _tmpl$$q = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M8 5.14v14l11-7-11-7Z"/></svg>`);
var PlayIcon = (props = {}) => {
  const _el$ = _tmpl$$q.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const Icon = (props) => {
  props = mergeProps({
    size: 24
  }, props);
  const Icon2 = props.icon;
  return createComponent(Icon2, {
    get className() {
      return props.className;
    },
    get style() {
      return {
        height: `${props.size}px`,
        width: `${props.size}px`
      };
    }
  });
};
const PlayButton = (props) => {
  props = mergeProps({
    size: 24,
    hover: true
  }, props);
  const label2 = () => props.isPlaying ? "button.pause" : "button.play";
  return createComponent(TooltipIconButton, {
    testId: "button_play",
    get onClick() {
      return props.onPress;
    },
    get translateLabelKey() {
      return label2();
    },
    offset: [14, 24],
    get children() {
      return memo(() => !!props.isPlaying, true)() ? createComponent(Icon, {
        icon: PauseIcon,
        get size() {
          return props.size;
        }
      }) : createComponent(Icon, {
        icon: PlayIcon,
        get size() {
          return props.size;
        }
      });
    }
  });
};
function _classCallCheck(e, t) {
  if (!(e instanceof t))
    throw new TypeError("Cannot call a class as a function");
}
function _defineProperties(e, t) {
  for (var n = 0; n < t.length; n++) {
    var r = t[n];
    r.enumerable = r.enumerable || false, r.configurable = true, "value" in r && (r.writable = true), Object.defineProperty(e, r.key, r);
  }
}
function _createClass(e, t, n) {
  return t && _defineProperties(e.prototype, t), n && _defineProperties(e, n), e;
}
function _defineProperty(e, t, n) {
  return t in e ? Object.defineProperty(e, t, { value: n, enumerable: true, configurable: true, writable: true }) : e[t] = n, e;
}
function ownKeys(e, t) {
  var n = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function(t2) {
      return Object.getOwnPropertyDescriptor(e, t2).enumerable;
    })), n.push.apply(n, r);
  }
  return n;
}
function _objectSpread2(e) {
  for (var t = 1; t < arguments.length; t++) {
    var n = arguments[t] != null ? arguments[t] : {};
    t % 2 ? ownKeys(Object(n), true).forEach(function(t2) {
      _defineProperty(e, t2, n[t2]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n)) : ownKeys(Object(n)).forEach(function(t2) {
      Object.defineProperty(e, t2, Object.getOwnPropertyDescriptor(n, t2));
    });
  }
  return e;
}
var defaults = { addCSS: true, thumbWidth: 15, watch: true };
function matches(e, t) {
  return function() {
    return Array.from(document.querySelectorAll(t)).includes(this);
  }.call(e, t);
}
function trigger(e, t) {
  if (e && t) {
    var n = new Event(t, { bubbles: true });
    e.dispatchEvent(n);
  }
}
var getConstructor = function(e) {
  return e != null ? e.constructor : null;
}, instanceOf = function(e, t) {
  return !!(e && t && e instanceof t);
}, isNullOrUndefined = function(e) {
  return e == null;
}, isObject = function(e) {
  return getConstructor(e) === Object;
}, isNumber = function(e) {
  return getConstructor(e) === Number && !Number.isNaN(e);
}, isString = function(e) {
  return getConstructor(e) === String;
}, isBoolean = function(e) {
  return getConstructor(e) === Boolean;
}, isFunction = function(e) {
  return getConstructor(e) === Function;
}, isArray = function(e) {
  return Array.isArray(e);
}, isNodeList = function(e) {
  return instanceOf(e, NodeList);
}, isElement = function(e) {
  return instanceOf(e, Element);
}, isEvent = function(e) {
  return instanceOf(e, Event);
}, isEmpty = function(e) {
  return isNullOrUndefined(e) || (isString(e) || isArray(e) || isNodeList(e)) && !e.length || isObject(e) && !Object.keys(e).length;
}, is = { nullOrUndefined: isNullOrUndefined, object: isObject, number: isNumber, string: isString, boolean: isBoolean, function: isFunction, array: isArray, nodeList: isNodeList, element: isElement, event: isEvent, empty: isEmpty };
function getDecimalPlaces(e) {
  var t = "".concat(e).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  return t ? Math.max(0, (t[1] ? t[1].length : 0) - (t[2] ? +t[2] : 0)) : 0;
}
function round(e, t) {
  if (1 > t) {
    var n = getDecimalPlaces(t);
    return parseFloat(e.toFixed(n));
  }
  return Math.round(e / t) * t;
}
var RangeTouch = function() {
  function e(t, n) {
    _classCallCheck(this, e), is.element(t) ? this.element = t : is.string(t) && (this.element = document.querySelector(t)), is.element(this.element) && is.empty(this.element.rangeTouch) && (this.config = _objectSpread2({}, defaults, {}, n), this.init());
  }
  return _createClass(e, [{ key: "init", value: function() {
    e.enabled && (this.config.addCSS && (this.element.style.userSelect = "none", this.element.style.webKitUserSelect = "none", this.element.style.touchAction = "manipulation"), this.listeners(true), this.element.rangeTouch = this);
  } }, { key: "destroy", value: function() {
    e.enabled && (this.config.addCSS && (this.element.style.userSelect = "", this.element.style.webKitUserSelect = "", this.element.style.touchAction = ""), this.listeners(false), this.element.rangeTouch = null);
  } }, { key: "listeners", value: function(e2) {
    var t = this, n = e2 ? "addEventListener" : "removeEventListener";
    ["touchstart", "touchmove", "touchend"].forEach(function(e3) {
      t.element[n](e3, function(e4) {
        return t.set(e4);
      }, false);
    });
  } }, { key: "get", value: function(t) {
    if (!e.enabled || !is.event(t))
      return null;
    var n, r = t.target, i = t.changedTouches[0], o = parseFloat(r.getAttribute("min")) || 0, s = parseFloat(r.getAttribute("max")) || 100, u2 = parseFloat(r.getAttribute("step")) || 1, c = r.getBoundingClientRect(), a = 100 / c.width * (this.config.thumbWidth / 2) / 100;
    return 0 > (n = 100 / c.width * (i.clientX - c.left)) ? n = 0 : 100 < n && (n = 100), 50 > n ? n -= (100 - 2 * n) * a : 50 < n && (n += 2 * (n - 50) * a), o + round(n / 100 * (s - o), u2);
  } }, { key: "set", value: function(t) {
    e.enabled && is.event(t) && !t.target.disabled && (t.preventDefault(), t.target.value = this.get(t), trigger(t.target, t.type === "touchend" ? "change" : "input"));
  } }], [{ key: "setup", value: function(t) {
    var n = 1 < arguments.length && arguments[1] !== void 0 ? arguments[1] : {}, r = null;
    if (is.empty(t) || is.string(t) ? r = Array.from(document.querySelectorAll(is.string(t) ? t : 'input[type="range"]')) : is.element(t) ? r = [t] : is.nodeList(t) ? r = Array.from(t) : is.array(t) && (r = t.filter(is.element)), is.empty(r))
      return null;
    var i = _objectSpread2({}, defaults, {}, n);
    if (is.string(t) && i.watch) {
      var o = new MutationObserver(function(n2) {
        Array.from(n2).forEach(function(n3) {
          Array.from(n3.addedNodes).forEach(function(n4) {
            is.element(n4) && matches(n4, t) && new e(n4, i);
          });
        });
      });
      o.observe(document.body, { childList: true, subtree: true });
    }
    return r.map(function(t2) {
      return new e(t2, n);
    });
  } }, { key: "enabled", get: function() {
    return "ontouchstart" in document.documentElement;
  } }]), e;
}();
const primary = "_primary_1lrm9_1";
const white = "_white_1lrm9_5";
const container$1 = "_container_1lrm9_9";
const timelineContainer = "_timelineContainer_1lrm9_27";
const timelineBuffer = "_timelineBuffer_1lrm9_34";
const timeline = "_timeline_1lrm9_27";
const range = "_range_1lrm9_48";
var styles$c = {
  primary,
  white,
  container: container$1,
  timelineContainer,
  timelineBuffer,
  timeline,
  range
};
const _tmpl$$p = /* @__PURE__ */ template$1(`<div><div><div></div><div></div></div><input dir="ltr" type="range" data-testid="input_range"></div>`);
const InputRange = (props) => {
  const [value, setValue] = createSignal(0);
  onMount(() => {
    const rangeTouch = new RangeTouch(props.ref);
    onCleanup(() => rangeTouch.destroy());
  });
  createEffect(() => {
    var _a;
    setValue((_a = props.value) != null ? _a : 0);
  });
  const handleInputChange = (event) => {
    const {
      value: inputValue
    } = event.currentTarget;
    setValue(Number(inputValue));
    props.onValueChange(Number(inputValue));
  };
  const getPercentString = (percent = 0) => {
    return `${percent}%`;
  };
  const getColorSchema = () => styles$c[props.theme || "primary"];
  return (() => {
    const _el$ = _tmpl$$p.cloneNode(true), _el$2 = _el$.firstChild, _el$3 = _el$2.firstChild, _el$4 = _el$3.nextSibling, _el$5 = _el$2.nextSibling;
    _el$5.$$input = handleInputChange;
    const _ref$ = props.ref;
    typeof _ref$ === "function" ? _ref$(_el$5) : props.ref = _el$5;
    createRenderEffect((_p$) => {
      const _v$ = `${getColorSchema()} ${styles$c.container}`, _v$2 = styles$c.timelineContainer, _v$3 = styles$c.timelineBuffer, _v$4 = getPercentString(props.valueBuffer), _v$5 = styles$c.timeline, _v$6 = getPercentString(value()), _v$7 = styles$c.range, _v$8 = props.min, _v$9 = props.max, _v$10 = props.step, _v$11 = value();
      _v$ !== _p$._v$ && (_el$.className = _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && (_el$2.className = _p$._v$2 = _v$2);
      _v$3 !== _p$._v$3 && (_el$3.className = _p$._v$3 = _v$3);
      _v$4 !== _p$._v$4 && _el$3.style.setProperty("width", _p$._v$4 = _v$4);
      _v$5 !== _p$._v$5 && (_el$4.className = _p$._v$5 = _v$5);
      _v$6 !== _p$._v$6 && _el$4.style.setProperty("width", _p$._v$6 = _v$6);
      _v$7 !== _p$._v$7 && (_el$5.className = _p$._v$7 = _v$7);
      _v$8 !== _p$._v$8 && setAttribute(_el$5, "min", _p$._v$8 = _v$8);
      _v$9 !== _p$._v$9 && setAttribute(_el$5, "max", _p$._v$9 = _v$9);
      _v$10 !== _p$._v$10 && setAttribute(_el$5, "step", _p$._v$10 = _v$10);
      _v$11 !== _p$._v$11 && (_el$5.value = _p$._v$11 = _v$11);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0,
      _v$3: void 0,
      _v$4: void 0,
      _v$5: void 0,
      _v$6: void 0,
      _v$7: void 0,
      _v$8: void 0,
      _v$9: void 0,
      _v$10: void 0,
      _v$11: void 0
    });
    return _el$;
  })();
};
delegateEvents(["input"]);
const _tmpl$$o = /* @__PURE__ */ template$1(`<div data-testid="seek_tooltip" class="absolute bottom-full transform -translate-y-1 tracking-wide text-white text-sm font-normal ordinal tabular-nums bg-gray-900 bg-opacity-90 rounded-md pointer-events-none px-2 py-1"></div>`);
const SeekToolTip = (props) => {
  return createComponent(Show, {
    get when() {
      return props.show;
    },
    get children() {
      const _el$ = _tmpl$$o.cloneNode(true);
      const _ref$ = props.ref;
      typeof _ref$ === "function" ? _ref$(_el$) : props.ref = _el$;
      insert(_el$, () => props.children);
      createRenderEffect(() => _el$.style.setProperty("left", `${props.position}%`));
      return _el$;
    }
  });
};
const _tmpl$$n = /* @__PURE__ */ template$1(`<div class="w-full"></div>`);
const Seekbar = (props) => {
  const [mousePosPercentage, setMousePosPercentage] = createSignal(0);
  const [tooltipPosPercentage, setTooltipPosPercentage] = createSignal(0);
  const [showTooltip, setShowTooltip] = createSignal(false);
  let tooltipRef;
  let inputRangeRef;
  const handleMouseMove = (ev) => {
    const rect = ev.target.getBoundingClientRect();
    if (!tooltipRef)
      return;
    const tooltipWidth = tooltipRef.getBoundingClientRect().width;
    const inputRangeWidth = inputRangeRef.getBoundingClientRect().width;
    const x = ev.clientX - rect.left;
    const percentage = x / inputRangeWidth * 100;
    const offset2 = tooltipWidth / 2 / inputRangeWidth * 100;
    const min2 = tooltipWidth / 2;
    const max2 = inputRangeWidth - tooltipWidth / 2;
    if (x >= max2) {
      if (percentage <= 100) {
        setMousePosPercentage(percentage);
      } else {
        setMousePosPercentage(100);
      }
      setTooltipPosPercentage(100 * (inputRangeWidth - tooltipWidth) / inputRangeWidth);
    } else if (x <= min2) {
      setTooltipPosPercentage(0);
      if (x >= 0) {
        setMousePosPercentage(percentage);
      } else {
        setMousePosPercentage(0);
      }
    } else {
      setTooltipPosPercentage(Math.abs(offset2 - percentage));
      setMousePosPercentage(percentage);
    }
  };
  const onValueChange = (value) => {
    const updatedValue = mousePosPercentage() === 0 ? value : mousePosPercentage();
    props.onValueChange(updatedValue);
  };
  const currentTimeValue = () => ConversionUtils.secondsToHMS(mousePosPercentage() / 100 * props.duration);
  return (() => {
    const _el$ = _tmpl$$n.cloneNode(true);
    _el$.addEventListener("mouseleave", () => setShowTooltip(false));
    _el$.addEventListener("mouseenter", () => setShowTooltip(true));
    _el$.$$mousemove = handleMouseMove;
    insert(_el$, createComponent(SeekToolTip, {
      get show() {
        return memo(() => !!!BrowserUtils.isMobile, true)() && showTooltip();
      },
      ref(r$) {
        const _ref$ = tooltipRef;
        typeof _ref$ === "function" ? _ref$(r$) : tooltipRef = r$;
      },
      get position() {
        return tooltipPosPercentage();
      },
      get children() {
        return currentTimeValue();
      }
    }), null);
    insert(_el$, createComponent(InputRange, {
      ref(r$) {
        const _ref$2 = inputRangeRef;
        typeof _ref$2 === "function" ? _ref$2(r$) : inputRangeRef = r$;
      },
      onValueChange,
      get valueBuffer() {
        return props.valueBuffer;
      },
      get value() {
        return props.value;
      },
      step: 1e-3,
      theme: "primary"
    }), null);
    return _el$;
  })();
};
delegateEvents(["mousemove"]);
const VolumeBar = (props) => {
  const value = () => props.muted ? 0 : props.value;
  return createComponent(InputRange, {
    get onValueChange() {
      return props.onVolumeChange;
    },
    get value() {
      return value();
    },
    step: 0.01,
    theme: "white"
  });
};
const _tmpl$$m = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.84-5 6.7v2.07c4-.91 7-4.49 7-8.77 0-4.28-3-7.86-7-8.77M16.5 12c0-1.77-1-3.29-2.5-4.03V16c1.5-.71 2.5-2.24 2.5-4M3 9v6h4l5 5V4L7 9H3Z"/></svg>`);
var VolumeHighIcon = (props = {}) => {
  const _el$ = _tmpl$$m.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const _tmpl$$l = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M5 9v6h4l5 5V4L9 9m9.5 3c0-1.77-1-3.29-2.5-4.03V16c1.5-.71 2.5-2.24 2.5-4Z"/></svg>`);
var VolumeMediumIcon = (props = {}) => {
  const _el$ = _tmpl$$l.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const _tmpl$$k = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4 9.91 6.09 12 8.18M4.27 3 3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.26c-.67.51-1.42.93-2.25 1.17v2.07c1.38-.32 2.63-.95 3.68-1.81L19.73 21 21 19.73l-9-9M19 12c0 .94-.2 1.82-.54 2.64l1.51 1.51A8.916 8.916 0 0 0 21 12c0-4.28-3-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71m-2.5 0c0-1.77-1-3.29-2.5-4.03v2.21l2.45 2.45c.05-.2.05-.42.05-.63Z"/></svg>`);
var VolumeOffIcon = (props = {}) => {
  const _el$ = _tmpl$$k.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const VolumeButton = (props) => {
  return createComponent(TooltipIconButton, {
    testId: "volume_button",
    onClick: () => props.onPress(),
    get translateLabelKey() {
      return props.label;
    },
    get children() {
      return createComponent(Switch, {
        get fallback() {
          return createComponent(VolumeMediumIcon, {
            "data-testid": "volume_medium"
          });
        },
        get children() {
          return [createComponent(Match, {
            get when() {
              return props.muted || props.volume === 0;
            },
            get children() {
              return createComponent(VolumeOffIcon, {
                "data-testid": "volume_off"
              });
            }
          }), createComponent(Match, {
            get when() {
              return props.volume >= 40;
            },
            get children() {
              return createComponent(VolumeHighIcon, {
                "data-testid": "volume_high"
              });
            }
          })];
        }
      });
    }
  });
};
const _tmpl$$j = /* @__PURE__ */ template$1(`<div data-testid="container_volume_bar" class="flex items-center ml-1 transition-all"></div>`), _tmpl$2$4 = /* @__PURE__ */ template$1(`<div data-testid="volume_control" class="flex items-center h-10"></div>`);
const VolumeControl = (props) => {
  const [isHover, setIsHover] = createSignal(false);
  const buttonLabel = () => props.muted ? "button.unmute" : "button.mute";
  const supportVolumeBar = () => {
    return !BrowserUtils.isMobile && !BrowserUtils.isIOS;
  };
  return (() => {
    const _el$ = _tmpl$2$4.cloneNode(true);
    _el$.addEventListener("mouseleave", () => setIsHover(false));
    _el$.addEventListener("mouseenter", () => setIsHover(true));
    insert(_el$, createComponent(VolumeButton, {
      get label() {
        return buttonLabel();
      },
      get volume() {
        return props.volume;
      },
      get muted() {
        return props.muted;
      },
      get onPress() {
        return props.toggleMute;
      }
    }), null);
    insert(_el$, createComponent(Show, {
      get when() {
        return supportVolumeBar();
      },
      get children() {
        const _el$2 = _tmpl$$j.cloneNode(true);
        insert(_el$2, createComponent(VolumeBar, {
          get onVolumeChange() {
            return props.onVolumeChange;
          },
          get value() {
            return props.volume;
          },
          get muted() {
            return props.muted;
          }
        }));
        createRenderEffect((_$p) => classList(_el$2, {
          "opacity-0 w-1": !isHover(),
          "w-20": isHover()
        }, _$p));
        return _el$2;
      }
    }), null);
    return _el$;
  })();
};
function useOnClickOutside(refs, handlerOutside) {
  const listener = (event) => {
    var _a;
    let contains2 = false;
    if (refs.length === 0)
      return;
    for (const refEl of refs) {
      if ((_a = refEl()) == null ? void 0 : _a.contains(event.target)) {
        contains2 = true;
      }
    }
    if (!contains2) {
      handlerOutside();
    }
  };
  createEffect(() => {
  });
  onMount(() => {
    window.addEventListener("mousedown", listener);
    window.addEventListener("touchstart", listener);
  });
  onCleanup(() => {
    window.removeEventListener("mousedown", listener);
    window.removeEventListener("touchstart", listener);
  });
}
const popper = "_popper_g80tl_1";
var styles$b = {
  popper
};
const _tmpl$$i = /* @__PURE__ */ template$1(`<div></div>`);
const Popover = (props) => {
  const [anchor, setAnchor] = createSignal();
  const [popper2, setPopper] = createSignal();
  const popperRef = y(anchor, popper2, props.options);
  onCleanup(() => {
    var _a;
    (_a = popperRef()) == null ? void 0 : _a.destroy();
  });
  return (() => {
    const _el$ = _tmpl$$i.cloneNode(true);
    setAnchor(_el$);
    insert(_el$, () => props.children, null);
    insert(_el$, createComponent(Show, {
      get when() {
        return props.isOpen();
      },
      get children() {
        const _el$2 = _tmpl$$i.cloneNode(true);
        setPopper(_el$2);
        insert(_el$2, () => props.popoverBody);
        createRenderEffect(() => _el$2.className = styles$b.popper);
        return _el$2;
      }
    }), null);
    return _el$;
  })();
};
const _tmpl$$h = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M12 15.5A3.5 3.5 0 0 1 8.5 12 3.5 3.5 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5 3.5 3.5 0 0 1-3.5 3.5m7.43-2.53c.04-.32.07-.64.07-.97 0-.33-.03-.66-.07-1l2.11-1.63c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.31-.61-.22l-2.49 1c-.52-.39-1.06-.73-1.69-.98l-.37-2.65A.506.506 0 0 0 14 2h-4c-.25 0-.46.18-.5.42l-.37 2.65c-.63.25-1.17.59-1.69.98l-2.49-1c-.22-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64L4.57 11c-.04.34-.07.67-.07 1 0 .33.03.65.07.97l-2.11 1.66c-.19.15-.25.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1.01c.52.4 1.06.74 1.69.99l.37 2.65c.04.24.25.42.5.42h4c.25 0 .46-.18.5-.42l.37-2.65c.63-.26 1.17-.59 1.69-.99l2.49 1.01c.22.08.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.66Z"/></svg>`);
var SettingsIcon = (props = {}) => {
  const _el$ = _tmpl$$h.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const SettingsButton = (props) => {
  return createComponent(TooltipIconButton, {
    testId: "settings_button",
    ref(r$) {
      const _ref$ = props.ref;
      typeof _ref$ === "function" ? _ref$(r$) : props.ref = r$;
    },
    get onClick() {
      return props.onPress;
    },
    translateLabelKey: "button.settings",
    get isActive() {
      return props.isActive;
    },
    get children() {
      return createComponent(SettingsIcon, {});
    }
  });
};
const _tmpl$$g = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"/></svg>`);
var ChevronRightIcon = (props = {}) => {
  const _el$ = _tmpl$$g.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const button$1 = "_button_1ib7d_1";
const label$1 = "_label_1ib7d_5";
const active_label = "_active_label_1ib7d_9";
const icon$2 = "_icon_1ib7d_13";
var styles$a = {
  button: button$1,
  label: label$1,
  active_label,
  icon: icon$2
};
const _tmpl$$f = /* @__PURE__ */ template$1(`<button><span></span><div><span></span></div></button>`);
const MenuOption = (props) => {
  const handleOpenSubmenu = () => {
    props.onPress();
  };
  return (() => {
    const _el$ = _tmpl$$f.cloneNode(true), _el$2 = _el$.firstChild, _el$3 = _el$2.nextSibling, _el$4 = _el$3.firstChild;
    _el$.$$click = handleOpenSubmenu;
    insert(_el$2, () => props.label);
    insert(_el$3, () => props.activeLabel, _el$4);
    insert(_el$4, createComponent(ChevronRightIcon, {}));
    createRenderEffect((_p$) => {
      const _v$ = props.testId, _v$2 = styles$a.button, _v$3 = styles$a.label, _v$4 = styles$a.active_label, _v$5 = styles$a.icon;
      _v$ !== _p$._v$ && setAttribute(_el$, "data-testid", _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && (_el$.className = _p$._v$2 = _v$2);
      _v$3 !== _p$._v$3 && (_el$2.className = _p$._v$3 = _v$3);
      _v$4 !== _p$._v$4 && (_el$3.className = _p$._v$4 = _v$4);
      _v$5 !== _p$._v$5 && (_el$4.className = _p$._v$5 = _v$5);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0,
      _v$3: void 0,
      _v$4: void 0,
      _v$5: void 0
    });
    return _el$;
  })();
};
delegateEvents(["click"]);
const _tmpl$$e = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M21 7 9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7Z"/></svg>`);
var OutlineCheckIcon = (props = {}) => {
  const _el$ = _tmpl$$e.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const _tmpl$$d = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M15.41 16.58 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.42Z"/></svg>`);
var ChevronLeftIcon = (props = {}) => {
  const _el$ = _tmpl$$d.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const label = "_label_xx3r3_1";
const icon$1 = "_icon_xx3r3_5";
var styles$9 = {
  label,
  icon: icon$1
};
const _tmpl$$c = /* @__PURE__ */ template$1(`<label><span></span><input class="sr-only" type="radio"><span></span></label>`), _tmpl$2$3 = /* @__PURE__ */ template$1(`<button class="px-4 py-2 flex items-center border-b border-gray-500 border-opacity-95 w-full transition-colors hover:bg-gray-700"><span class="text-white text-2xl -ml-2 mr-2"></span><span class="font-bold"></span></button>`);
const SubMenuOption = (props) => {
  return (() => {
    const _el$ = _tmpl$$c.cloneNode(true), _el$2 = _el$.firstChild, _el$3 = _el$2.nextSibling, _el$4 = _el$3.nextSibling;
    insert(_el$2, createComponent(Show, {
      get when() {
        return props.option.active;
      },
      get children() {
        return createComponent(OutlineCheckIcon, {});
      }
    }));
    _el$3.$$input = (e) => props.onChange(e.currentTarget.value);
    insert(_el$4, () => props.option.label || props.option.key);
    createRenderEffect((_p$) => {
      const _v$ = `submenu_option_label_${props.id}`, _v$2 = styles$9.label, _v$3 = styles$9.icon, _v$4 = `submenu_option_input_${props.id}`, _v$5 = props.option.label, _v$6 = props.option.key, _v$7 = props.option.active;
      _v$ !== _p$._v$ && setAttribute(_el$, "data-testid", _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && (_el$.className = _p$._v$2 = _v$2);
      _v$3 !== _p$._v$3 && (_el$2.className = _p$._v$3 = _v$3);
      _v$4 !== _p$._v$4 && setAttribute(_el$3, "data-testid", _p$._v$4 = _v$4);
      _v$5 !== _p$._v$5 && setAttribute(_el$3, "name", _p$._v$5 = _v$5);
      _v$6 !== _p$._v$6 && (_el$3.value = _p$._v$6 = _v$6);
      _v$7 !== _p$._v$7 && (_el$3.checked = _p$._v$7 = _v$7);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0,
      _v$3: void 0,
      _v$4: void 0,
      _v$5: void 0,
      _v$6: void 0,
      _v$7: void 0
    });
    return _el$;
  })();
};
const SubMenu = (props) => {
  const handleChange = (value) => {
    const selected = props.options.find((option) => option.key === value);
    if (selected) {
      props.onChange(selected);
      props.onClose();
    }
  };
  return [(() => {
    const _el$5 = _tmpl$2$3.cloneNode(true), _el$6 = _el$5.firstChild, _el$7 = _el$6.nextSibling;
    addEventListener(_el$5, "click", props.onClose, true);
    insert(_el$6, createComponent(ChevronLeftIcon, {}));
    insert(_el$7, () => props.label);
    return _el$5;
  })(), createComponent(For, {
    get each() {
      return props.options;
    },
    children: (option, index) => createComponent(SubMenuOption, {
      get id() {
        return String(index());
      },
      option,
      onChange: handleChange
    })
  })];
};
delegateEvents(["input", "click"]);
const menu_container = "_menu_container_15ydi_1";
var styles$8 = {
  menu_container
};
const menu_height = "_menu_height_w2zvu_1";
var styles$7 = {
  menu_height
};
const _tmpl$$b = /* @__PURE__ */ template$1(`<div></div>`);
const LimitMenuHeight = (props) => {
  const {
    height
  } = useVxdkState();
  const getPopoverHeight = () => {
    var _a;
    const bottomMarginSizeInRem = 6;
    const bottomMarginSizeInPixel = ConversionUtils.convertRemToPixels(bottomMarginSizeInRem);
    const videoHeight = (_a = height()) != null ? _a : 0;
    return `${videoHeight - bottomMarginSizeInPixel}px`;
  };
  return (() => {
    const _el$ = _tmpl$$b.cloneNode(true);
    insert(_el$, () => props.children);
    createRenderEffect((_p$) => {
      const _v$ = styles$7.menu_height, _v$2 = getPopoverHeight();
      _v$ !== _p$._v$ && (_el$.className = _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && _el$.style.setProperty("height", _p$._v$2 = _v$2);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0
    });
    return _el$;
  })();
};
const _tmpl$$a = /* @__PURE__ */ template$1(`<div data-testid="settings_menu"></div>`);
const Menu = (props) => {
  const {
    goto,
    goBack,
    navigation,
    inMainMenu,
    inSubMenu
  } = useSettingsMenu();
  const [t] = useI18n();
  const {
    levelOptions,
    onQualityChange,
    levelLabel,
    onPlaybackRateChange,
    rateOptions,
    rateValue
  } = useSettings();
  const rateLabel = () => {
    return rateValue() === 1 ? "Normal" : String(rateValue());
  };
  return createComponent(LimitMenuHeight, {
    get children() {
      const _el$ = _tmpl$$a.cloneNode(true);
      const _ref$ = props.ref;
      typeof _ref$ === "function" ? _ref$(_el$) : props.ref = _el$;
      insert(_el$, createComponent(Show, {
        get when() {
          return inMainMenu();
        },
        get children() {
          return [createComponent(MenuOption, {
            testId: "settings_menu_speed",
            get label() {
              return t("settings_menu.option.speed");
            },
            onPress: () => goto(SettingsNavigation.speed),
            get activeLabel() {
              return rateLabel();
            }
          }), createComponent(MenuOption, {
            testId: "settings_menu_quality",
            get label() {
              return t("settings_menu.option.quality");
            },
            onPress: () => goto(SettingsNavigation.quality),
            get activeLabel() {
              return levelLabel();
            }
          })];
        }
      }), null);
      insert(_el$, createComponent(Show, {
        get when() {
          return inSubMenu();
        },
        get children() {
          return createComponent(Switch, {
            get children() {
              return [createComponent(Match, {
                get when() {
                  return navigation() === SettingsNavigation.speed;
                },
                get children() {
                  return createComponent(SubMenu, {
                    testId: "settings_submenu_speed",
                    get label() {
                      return t("settings_menu.option.speed");
                    },
                    get options() {
                      return rateOptions();
                    },
                    onChange: (option) => onPlaybackRateChange(option.key),
                    onClose: goBack
                  });
                }
              }), createComponent(Match, {
                get when() {
                  return navigation() === SettingsNavigation.quality;
                },
                get children() {
                  return createComponent(SubMenu, {
                    testId: "settings_submenu_quality",
                    get label() {
                      return t("settings_menu.option.quality");
                    },
                    get options() {
                      return levelOptions();
                    },
                    onChange: (option) => onQualityChange(option.key),
                    onClose: goBack
                  });
                }
              })];
            }
          });
        }
      }), null);
      createRenderEffect(() => _el$.className = styles$8.menu_container);
      return _el$;
    }
  });
};
const Settings = () => {
  const {
    toggleMenu,
    isOpen
  } = useSettingsMenu();
  const [menuRef, setMenuRef] = createSignal();
  const [buttonRef, setButtonRef] = createSignal();
  const offset2 = {
    name: "offset",
    options: {
      offset: [-15, 26]
    }
  };
  const onClose = () => {
    if (isOpen()) {
      toggleMenu();
    }
  };
  useOnClickOutside([menuRef, buttonRef], onClose);
  return createComponent(Popover, {
    isOpen,
    get popoverBody() {
      return createComponent(Menu, {
        ref: setMenuRef
      });
    },
    options: {
      placement: "top",
      modifiers: [offset2]
    },
    get children() {
      return createComponent(SettingsButton, {
        ref: setButtonRef,
        onPress: toggleMenu,
        get isActive() {
          return isOpen();
        }
      });
    }
  });
};
const control = "_control_d64kw_1";
const backdrop$1 = "_backdrop_d64kw_5";
const control_container = "_control_container_d64kw_12";
const control_bar = "_control_bar_d64kw_16";
var styles$6 = {
  control,
  backdrop: backdrop$1,
  control_container,
  control_bar
};
const _tmpl$$9 = /* @__PURE__ */ template$1(`<div data-testid="control_bar"><div></div><div><div><div class="flex mb-1"></div><div class="flex items-center"><div class="flex flex-1 items-center"></div><div class="flex flex-wrap justify-between items-center"></div></div></div></div></div>`);
const DefaultControlBar = () => {
  const {
    state
  } = useVxdkContext();
  const vxdk = useVxdk();
  const togglePlay = () => {
    if (state.playing) {
      vxdk.pause();
    } else {
      vxdk.play();
    }
  };
  const toggleMute = () => {
    vxdk.toggleMute();
  };
  const onVolumeChange = (value) => {
    vxdk.setVolume(value / 100);
  };
  const onSeekChange = (value) => {
    vxdk.seekToPercentage(value);
  };
  const durationTime = () => ConversionUtils.secondsToHMS(state.duration);
  const currentTime = () => ConversionUtils.secondsToHMS(state.currentTime);
  const progress = createMemo(() => ConversionUtils.timeDurationToPercent(state.currentTime, state.duration));
  const volumePercentage = () => state.volume * 100;
  const shouldDisplay = () => (state.focused || !state.playing) && !state.waitingForUser;
  const videoInstance = () => vxdk.getRootElement().querySelector("video");
  onMount(() => {
    var _a;
    (_a = videoInstance()) == null ? void 0 : _a.addEventListener("click", (event) => {
      if (event.target === videoInstance()) {
        togglePlay();
      }
    });
  });
  onCleanup(() => {
    var _a;
    (_a = videoInstance()) == null ? void 0 : _a.removeEventListener("click", () => {
    });
  });
  return (() => {
    const _el$ = _tmpl$$9.cloneNode(true), _el$2 = _el$.firstChild, _el$3 = _el$2.nextSibling, _el$4 = _el$3.firstChild, _el$5 = _el$4.firstChild, _el$6 = _el$5.nextSibling, _el$7 = _el$6.firstChild, _el$8 = _el$7.nextSibling;
    insert(_el$5, createComponent(Seekbar, {
      get duration() {
        return state.duration;
      },
      get value() {
        return progress();
      },
      get valueBuffer() {
        return state.bufferedPercentage * 100;
      },
      onValueChange: onSeekChange
    }));
    insert(_el$7, createComponent(PlayButton, {
      get isPlaying() {
        return state.playing;
      },
      onPress: togglePlay
    }), null);
    insert(_el$7, createComponent(Spacer, {}), null);
    insert(_el$7, createComponent(TimeDisplay, {
      get currentTime() {
        return currentTime();
      },
      get duration() {
        return durationTime();
      }
    }), null);
    insert(_el$7, createComponent(Spacer, {}), null);
    insert(_el$7, createComponent(VolumeControl, {
      get volume() {
        return volumePercentage();
      },
      get muted() {
        return state.muted;
      },
      onVolumeChange,
      toggleMute
    }), null);
    insert(_el$8, createComponent(SettingsMenuProvider, {
      get children() {
        return createComponent(Settings, {});
      }
    }), null);
    insert(_el$8, createComponent(ControlBarPlugins, {}), null);
    createRenderEffect((_p$) => {
      const _v$ = styles$6.control, _v$2 = shouldDisplay(), _v$3 = styles$6.backdrop, _v$4 = styles$6.control_container, _v$5 = {
        "translate-y-4 invisible": !shouldDisplay()
      }, _v$6 = styles$6.control_bar;
      _v$ !== _p$._v$ && (_el$.className = _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && _el$.classList.toggle("opacity-100", _p$._v$2 = _v$2);
      _v$3 !== _p$._v$3 && (_el$2.className = _p$._v$3 = _v$3);
      _v$4 !== _p$._v$4 && (_el$3.className = _p$._v$4 = _v$4);
      _p$._v$5 = classList(_el$3, _v$5, _p$._v$5);
      _v$6 !== _p$._v$6 && (_el$4.className = _p$._v$6 = _v$6);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0,
      _v$3: void 0,
      _v$4: void 0,
      _v$5: void 0,
      _v$6: void 0
    });
    return _el$;
  })();
};
const _tmpl$$8 = template$1(`<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M7.41 8.58 12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42Z"/></svg>`);
var ChevronDownIcon = (props = {}) => {
  const _el$ = _tmpl$$8.cloneNode(true);
  spread(_el$, props, true);
  return _el$;
};
const select_container = "_select_container_wvuiy_1";
const select = "_select_wvuiy_1";
const icon = "_icon_wvuiy_9";
var styles$5 = {
  select_container,
  select,
  icon
};
const _tmpl$$7 = /* @__PURE__ */ template$1(`<div><select></select><i></i></div>`), _tmpl$2$2 = /* @__PURE__ */ template$1(`<option></option>`);
const Select = (props) => {
  return (() => {
    const _el$ = _tmpl$$7.cloneNode(true), _el$2 = _el$.firstChild, _el$3 = _el$2.nextSibling;
    _el$2.$$input = (e) => props.onChange(e.currentTarget.value);
    insert(_el$2, createComponent(For, {
      get each() {
        return props.options;
      },
      children: (option) => (() => {
        const _el$4 = _tmpl$2$2.cloneNode(true);
        insert(_el$4, () => option.label);
        createRenderEffect(() => _el$4.value = option.key);
        return _el$4;
      })()
    }));
    insert(_el$3, createComponent(Icon, {
      icon: ChevronDownIcon,
      size: 20
    }));
    createRenderEffect((_p$) => {
      const _v$ = styles$5.select_container, _v$2 = props.testId, _v$3 = props.id, _v$4 = styles$5.select, _v$5 = props.value, _v$6 = styles$5.icon;
      _v$ !== _p$._v$ && (_el$.className = _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && setAttribute(_el$2, "data-testid", _p$._v$2 = _v$2);
      _v$3 !== _p$._v$3 && setAttribute(_el$2, "id", _p$._v$3 = _v$3);
      _v$4 !== _p$._v$4 && (_el$2.className = _p$._v$4 = _v$4);
      _v$5 !== _p$._v$5 && (_el$2.value = _p$._v$5 = _v$5);
      _v$6 !== _p$._v$6 && (_el$3.className = _p$._v$6 = _v$6);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0,
      _v$3: void 0,
      _v$4: void 0,
      _v$5: void 0,
      _v$6: void 0
    });
    return _el$;
  })();
};
delegateEvents(["input"]);
const _tmpl$$6 = /* @__PURE__ */ template$1(`<label class="grid gap-1 text-xs"></label>`);
const FieldSelect = (props) => {
  return (() => {
    const _el$ = _tmpl$$6.cloneNode(true);
    insert(_el$, () => props.label, null);
    insert(_el$, createComponent(Select, {
      get testId() {
        return props.testId;
      },
      get options() {
        return props.options;
      },
      get value() {
        return props.value;
      },
      get onChange() {
        return props.onChange;
      }
    }), null);
    return _el$;
  })();
};
const backdrop = "_backdrop_admxw_1";
const content = "_content_admxw_5";
const modal_title = "_modal_title_admxw_9";
var styles$4 = {
  backdrop,
  content,
  modal_title
};
const _tmpl$$5 = /* @__PURE__ */ template$1(`<div></div>`), _tmpl$2$1 = /* @__PURE__ */ template$1(`<div><h2></h2></div>`), _tmpl$3 = /* @__PURE__ */ template$1(`<div data-testid="modal_content"><div></div></div>`);
const ModalBackdrop = (props) => {
  const [backdropRef, setBackdropRef] = createSignal();
  const onClickSelf = (event) => {
    const isSelf = backdropRef() === event.target;
    if (isSelf) {
      props.onClick();
    }
  };
  return (() => {
    const _el$ = _tmpl$$5.cloneNode(true);
    _el$.$$click = onClickSelf;
    setBackdropRef(_el$);
    insert(_el$, () => props.children);
    createRenderEffect((_p$) => {
      const _v$ = props.testId, _v$2 = styles$4.backdrop;
      _v$ !== _p$._v$ && setAttribute(_el$, "data-testid", _p$._v$ = _v$);
      _v$2 !== _p$._v$2 && (_el$.className = _p$._v$2 = _v$2);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0
    });
    return _el$;
  })();
};
const ModalHeader = (props) => {
  return (() => {
    const _el$2 = _tmpl$2$1.cloneNode(true), _el$3 = _el$2.firstChild;
    insert(_el$3, () => props.headerTitle);
    createRenderEffect(() => _el$3.className = styles$4.modal_title);
    return _el$2;
  })();
};
const Modal = (props) => {
  return createComponent(Portal, {
    get children() {
      return createComponent(ModalBackdrop, {
        testId: "modal_backdrop",
        get onClick() {
          return props.onClose;
        },
        get children() {
          const _el$4 = _tmpl$3.cloneNode(true), _el$5 = _el$4.firstChild;
          insert(_el$4, createComponent(Show, {
            get when() {
              return props.headerTitle;
            },
            get children() {
              return createComponent(ModalHeader, {
                get headerTitle() {
                  return props.headerTitle;
                }
              });
            }
          }), _el$5);
          insert(_el$5, () => props.children);
          createRenderEffect(() => _el$4.className = styles$4.content);
          return _el$4;
        }
      });
    }
  });
};
delegateEvents(["click"]);
const _tmpl$$4 = /* @__PURE__ */ template$1(`<div data-testid="settings_mobile" class="grid gap-3"></div>`), _tmpl$2 = /* @__PURE__ */ template$1(`<div class="mt-4 flex justify-end"></div>`);
const SettingsMobile = () => {
  const [isOpen, setIsOpen] = createSignal(false);
  const [t] = useI18n();
  const {
    levelOptions,
    onQualityChange,
    levelValue,
    onPlaybackRateChange,
    rateOptions,
    rateValue
  } = useSettings();
  const toggleOpen = () => {
    setIsOpen(!isOpen());
  };
  return [createComponent(SettingsButton, {
    onPress: toggleOpen
  }), createComponent(Show, {
    get when() {
      return isOpen();
    },
    get children() {
      return createComponent(Modal, {
        onClose: () => setIsOpen(false),
        get headerTitle() {
          return t("settings_menu.title");
        },
        get children() {
          return [(() => {
            const _el$ = _tmpl$$4.cloneNode(true);
            insert(_el$, createComponent(FieldSelect, {
              testId: "select_speed",
              get label() {
                return t("settings_menu.option.speed");
              },
              get options() {
                return rateOptions();
              },
              get value() {
                return rateValue();
              },
              onChange: onPlaybackRateChange
            }), null);
            insert(_el$, createComponent(Show, {
              get when() {
                return !BrowserUtils.isIOS;
              },
              get children() {
                return createComponent(FieldSelect, {
                  testId: "select_quality",
                  get label() {
                    return t("settings_menu.option.quality");
                  },
                  get options() {
                    return levelOptions();
                  },
                  get value() {
                    return levelValue();
                  },
                  onChange: onQualityChange
                });
              }
            }), null);
            return _el$;
          })(), (() => {
            const _el$2 = _tmpl$2.cloneNode(true);
            insert(_el$2, createComponent(Button, {
              testId: "settings_mobile_button_ok",
              className: "p-2 text-sm",
              onClick: toggleOpen,
              children: "OK"
            }));
            return _el$2;
          })()];
        }
      });
    }
  })];
};
const container = "_container_1i24f_1";
const is_hidden = "_is_hidden_1i24f_5";
const transition_ended = "_transition_ended_1i24f_9";
const wrapper = "_wrapper_1i24f_13";
const overlay = "_overlay_1i24f_17";
const center_control = "_center_control_1i24f_21";
const right_control = "_right_control_1i24f_25";
const bottom_control = "_bottom_control_1i24f_29";
const seekbar_control = "_seekbar_control_1i24f_37";
var styles$3 = {
  container,
  is_hidden,
  transition_ended,
  wrapper,
  overlay,
  center_control,
  right_control,
  bottom_control,
  seekbar_control
};
const _tmpl$$3 = /* @__PURE__ */ template$1(`<div data-testid="small_control_bar"><div><div></div><div></div><div></div><div><div></div><div></div></div><div></div></div></div>`);
const SmallScreenControlBar = () => {
  const {
    state
  } = useVxdkContext();
  const vxdk = useVxdk();
  const [shouldDisplay, setShouldDisplay] = createSignal(false);
  const [transitionEnded, setTransitionEnded] = createSignal(false);
  const togglePlay = () => {
    if (state.playing) {
      vxdk.pause();
    } else {
      vxdk.play();
    }
  };
  const isPlaying = () => state.playing;
  const isPaused = () => state.paused;
  const onUserInteractionTimeOut = () => {
    if (isPlaying()) {
      setShouldDisplay(false);
    }
  };
  let timer;
  const onTouchEvent = () => {
    if (!transitionEnded() && isPlaying() || isPaused()) {
      clearTimeout(timer);
      setShouldDisplay(true);
      timer = setTimeout(onUserInteractionTimeOut, 2500);
    }
  };
  createEffect(() => {
    if (state.playing) {
      setShouldDisplay(false);
    }
  });
  onMount(() => {
    vxdk.getRootElement().addEventListener("touchstart", onTouchEvent);
  });
  onCleanup(() => {
    vxdk.getRootElement().removeEventListener("touchstart", onTouchEvent);
  });
  const handleClickOverlayToHidenContainer = () => {
    if (transitionEnded()) {
      setShouldDisplay(false);
    }
  };
  const toggleMute = () => {
    vxdk.toggleMute();
  };
  const onVolumeChange = (value) => {
    vxdk.setVolume(value / 100);
  };
  const onSeekChange = (value) => {
    vxdk.seekTo(state.duration / 100 * value);
  };
  const durationTime = () => ConversionUtils.secondsToHMS(state.duration);
  const currentTime = () => ConversionUtils.secondsToHMS(state.currentTime);
  const progress = createMemo(() => ConversionUtils.timeDurationToPercent(state.currentTime, state.duration));
  const volumePercentage = () => state.volume * 100;
  createEffect(() => {
    if ((state.ended || state.paused) && !state.waitingForUser) {
      setShouldDisplay(true);
    }
  });
  createEffect(() => {
    const shouldDisplayState = shouldDisplay();
    setTimeout(() => {
      setTransitionEnded(shouldDisplayState);
    }, 200);
  });
  return (() => {
    const _el$ = _tmpl$$3.cloneNode(true), _el$2 = _el$.firstChild, _el$3 = _el$2.firstChild, _el$4 = _el$3.nextSibling, _el$5 = _el$4.nextSibling, _el$6 = _el$5.nextSibling, _el$7 = _el$6.firstChild, _el$8 = _el$7.nextSibling, _el$9 = _el$6.nextSibling;
    _el$3.$$click = handleClickOverlayToHidenContainer;
    insert(_el$4, createComponent(SettingsMobile, {}), null);
    insert(_el$4, createComponent(Spacer, {}), null);
    insert(_el$4, createComponent(VolumeControl, {
      get volume() {
        return volumePercentage();
      },
      get muted() {
        return state.muted;
      },
      onVolumeChange,
      toggleMute
    }), null);
    insert(_el$5, createComponent(PlayButton, {
      get isPlaying() {
        return state.playing;
      },
      onPress: togglePlay,
      size: 50
    }));
    insert(_el$7, createComponent(TimeDisplay, {
      get currentTime() {
        return currentTime();
      },
      get duration() {
        return durationTime();
      }
    }));
    insert(_el$8, createComponent(ControlBarPlugins, {}));
    insert(_el$9, createComponent(Seekbar, {
      get duration() {
        return state.duration;
      },
      get value() {
        return progress();
      },
      get valueBuffer() {
        return state.bufferedPercentage * 100;
      },
      onValueChange: onSeekChange
    }));
    createRenderEffect((_p$) => {
      const _v$ = styles$3.container, _v$2 = {
        [styles$3.is_hidden]: !shouldDisplay(),
        [styles$3.transition_ended]: !transitionEnded()
      }, _v$3 = styles$3.wrapper, _v$4 = styles$3.overlay, _v$5 = styles$3.right_control, _v$6 = styles$3.center_control, _v$7 = styles$3.bottom_control, _v$8 = styles$3.seekbar_control;
      _v$ !== _p$._v$ && (_el$.className = _p$._v$ = _v$);
      _p$._v$2 = classList(_el$, _v$2, _p$._v$2);
      _v$3 !== _p$._v$3 && (_el$2.className = _p$._v$3 = _v$3);
      _v$4 !== _p$._v$4 && (_el$3.className = _p$._v$4 = _v$4);
      _v$5 !== _p$._v$5 && (_el$4.className = _p$._v$5 = _v$5);
      _v$6 !== _p$._v$6 && (_el$5.className = _p$._v$6 = _v$6);
      _v$7 !== _p$._v$7 && (_el$6.className = _p$._v$7 = _v$7);
      _v$8 !== _p$._v$8 && (_el$9.className = _p$._v$8 = _v$8);
      return _p$;
    }, {
      _v$: void 0,
      _v$2: void 0,
      _v$3: void 0,
      _v$4: void 0,
      _v$5: void 0,
      _v$6: void 0,
      _v$7: void 0,
      _v$8: void 0
    });
    return _el$;
  })();
};
delegateEvents(["click"]);
const ControlBar = () => {
  return createComponent(Show, {
    get when() {
      return !BrowserUtils.isMobile;
    },
    get fallback() {
      return createComponent(SmallScreenControlBar, {});
    },
    get children() {
      return createComponent(DefaultControlBar, {});
    }
  });
};
const spinner = "_spinner_10oqq_1";
var styles$2 = {
  spinner,
  "spinner-large": "_spinner-large_10oqq_31",
  "spinner-slow": "_spinner-slow_10oqq_37",
  "spinner-blue": "_spinner-blue_10oqq_41"
};
const _tmpl$$2 = /* @__PURE__ */ template$1(`<div class="flex justify-center items-center absolute inset-0 z-10"><div></div></div>`);
const Loading = () => {
  const {
    buffering
  } = useVxdkState();
  return createComponent(Show, {
    get when() {
      return buffering();
    },
    get children() {
      const _el$ = _tmpl$$2.cloneNode(true), _el$2 = _el$.firstChild;
      createRenderEffect(() => _el$2.className = styles$2.spinner);
      return _el$;
    }
  });
};
const _tmpl$$1 = /* @__PURE__ */ template$1(`<div></div>`);
class DefaultUI extends UIContainerPlugin {
  constructor() {
    super(...arguments);
    __publicField(this, "name", "default_ui");
    __publicField(this, "render", () => {
      const {
        state
      } = useVxdkContext();
      return [createComponent(Loading, {}), createComponent(Show, {
        get when() {
          return state.options.showUI;
        },
        get fallback() {
          return _tmpl$$1.cloneNode(true);
        },
        get children() {
          return createComponent(ControlBar, {});
        }
      })];
    });
  }
}
const startview = "_startview_17j0x_1";
const button = "_button_17j0x_5";
const poster = "_poster_17j0x_10";
var styles$1 = {
  startview,
  button,
  poster
};
const _tmpl$ = /* @__PURE__ */ template$1(`<button data-testid="start_view_play"><div></div></button>`);
class StartView extends UIContainerPlugin {
  constructor() {
    super(...arguments);
    __publicField(this, "name", "start_view_ui");
    __publicField(this, "render", () => {
      const vxdk = useVxdk();
      const {
        waitingForUser,
        options
      } = useVxdkState();
      return createComponent(Show, {
        get when() {
          return waitingForUser();
        },
        get children() {
          const _el$ = _tmpl$.cloneNode(true), _el$2 = _el$.firstChild;
          _el$.$$click = () => vxdk.play();
          insert(_el$, createComponent(PlayIcon, {
            get ["class"]() {
              return styles$1.button;
            },
            style: {
              height: 50,
              width: 50
            }
          }), null);
          createRenderEffect((_p$) => {
            const _v$ = styles$1.startview, _v$2 = styles$1.poster, _v$3 = `url(${options().poster})`;
            _v$ !== _p$._v$ && (_el$.className = _p$._v$ = _v$);
            _v$2 !== _p$._v$2 && (_el$2.className = _p$._v$2 = _v$2);
            _v$3 !== _p$._v$3 && _el$2.style.setProperty("background-image", _p$._v$3 = _v$3);
            return _p$;
          }, {
            _v$: void 0,
            _v$2: void 0,
            _v$3: void 0
          });
          return _el$;
        }
      });
    });
  }
}
delegateEvents(["click"]);
var PluginRegistry;
((PluginRegistry2) => {
  const playbackRegistry = [HTML5Player];
  const playbackLoaderRegistry = [
    HlsJsPlayerLoader,
    YoutubePlayerLoader
  ];
  const pluginLoaderRegistry = [
    PipPluginLoader,
    KeyboardNavigationPluginLoader,
    LoopPluginLoader,
    AutoPlayPluginLoader,
    AirPlayPluginLoader,
    PersistOptionsPluginLoader
  ];
  const pluginRegistry = [
    DefaultUI,
    StartView,
    LoadPlugin,
    FullscreenPlugin,
    DimensionsPlugin,
    StatePlugin
  ];
  async function loadPlayback(context, isSupportedArgs) {
    await _loadAddToRegistry(context, playbackLoaderRegistry, playbackRegistry, isSupportedArgs);
  }
  PluginRegistry2.loadPlayback = loadPlayback;
  async function loadPlugins(context, isSupportedArgs) {
    await _loadAddToRegistry(context, pluginLoaderRegistry, pluginRegistry, isSupportedArgs);
  }
  PluginRegistry2.loadPlugins = loadPlugins;
  async function _loadAddToRegistry(context, loaders, registry, isSupportedArgs) {
    const promises = [];
    for (const loader of loaders) {
      if (loader.isSupported(context, isSupportedArgs)) {
        const moduleInstance = loader.get(context);
        promises.push(moduleInstance);
      }
    }
    const modules = await Promise.all(promises);
    modules.forEach((module) => {
      if (!registry.includes(module)) {
        registry.unshift(module);
      }
    });
  }
  function getPlugins() {
    return pluginRegistry;
  }
  PluginRegistry2.getPlugins = getPlugins;
  function getPlayback(source) {
    const playback = playbackRegistry.find((playback2) => playback2.canPlay(source));
    if (!playback) {
      throw new Error(`No playback found for source ${source}`);
    }
    return playback;
  }
  PluginRegistry2.getPlayback = getPlayback;
  function registerPlugin(plugin) {
    pluginRegistry.unshift(plugin);
  }
  PluginRegistry2.registerPlugin = registerPlugin;
  function registerPluginLoader(loader) {
    pluginLoaderRegistry.unshift(loader);
  }
  PluginRegistry2.registerPluginLoader = registerPluginLoader;
  function registerPlayback(playback) {
    playbackRegistry.unshift(playback);
  }
  PluginRegistry2.registerPlayback = registerPlayback;
  function registerPlaybackLoader(loader) {
    playbackLoaderRegistry.unshift(loader);
  }
  PluginRegistry2.registerPlaybackLoader = registerPlaybackLoader;
})(PluginRegistry || (PluginRegistry = {}));
const version = "1.1.8";
function getVersion() {
  return version;
}
class Event$1 {
  constructor() {
    __publicField(this, "listeners", []);
    __publicField(this, "listenersOncer", []);
    __publicField(this, "previousData");
  }
  static create() {
    return new Event$1();
  }
  on(listener) {
    this.listeners.push(listener);
    return {
      dispose: () => this.off(listener)
    };
  }
  onRateLimited(listener, ms = 500) {
    this.listeners.push(FunctionUtils.throttle(listener, ms));
    return {
      dispose: () => this.off(listener)
    };
  }
  once(listener) {
    this.listenersOncer.push(listener);
  }
  off(listener) {
    const callbackIndex = this.listeners.indexOf(listener);
    if (callbackIndex > -1)
      this.listeners.splice(callbackIndex, 1);
  }
  emit(data) {
    this.listeners.forEach((listener) => listener(data));
    if (this.listenersOncer.length > 0) {
      const toCall = this.listenersOncer;
      this.listenersOncer = [];
      toCall.forEach((listener) => listener(data));
    }
    this.previousData = data;
  }
  emitIfChanged(data) {
    if (Array.isArray(data)) {
      throw new Error("Arrays are not supported");
    }
    if (!this.previousData && data) {
      this.emit(data);
      return;
    }
    if (ObjectUtils.isObject(data)) {
      if (!ObjectUtils.shallowEqual(data, this.previousData)) {
        this.emit(data);
      }
      return;
    }
    if (data !== this.previousData) {
      this.emit(data);
    }
  }
  detach() {
    this.listeners = [];
    this.listenersOncer = [];
  }
  pipe(te) {
    return this.on((e) => te.emit(e));
  }
}
class EventController {
  constructor(events) {
    __publicField(this, "_events");
    __publicField(this, "emit", (name, data) => {
      this._events[name].emit(data);
    });
    __publicField(this, "emitIfChanged", (name, data) => {
      this._events[name].emitIfChanged(data);
    });
    __publicField(this, "off", (name, listener) => {
      this._events[name].off(listener);
    });
    __publicField(this, "on", (name, listener) => {
      return this._events[name].on(listener);
    });
    __publicField(this, "once", (name, listener) => {
      return this._events[name].once(listener);
    });
    __publicField(this, "unregisterEvents", () => {
      for (const key of Object.entries(this._events)) {
        key[1].detach();
      }
    });
    this._events = events;
  }
  registerEvents(eventNames) {
    eventNames.forEach((eventName) => {
      this._events[eventName] = Event$1.create();
    });
  }
}
function createVxdkEvents() {
  const events = {};
  Object.values(CONTEXT_EVENT).forEach((value) => {
    events[value] = Event$1.create();
  });
  Object.values(PLAYBACK_EVENT).forEach((value) => {
    events[value] = Event$1.create();
  });
  return events;
}
const _OptionsController = class {
  constructor(_options) {
    this._options = _options;
  }
  static create(options) {
    const mergedOptions = __spreadValues(__spreadValues({}, _OptionsController.defaults), options);
    return new _OptionsController(mergedOptions);
  }
  get options() {
    return this._options;
  }
  get source() {
    return this._options.source;
  }
  get thumbnails() {
    return this._options.thumbnails;
  }
  get poster() {
    return this._options.poster;
  }
  get muted() {
    return this._options.muted;
  }
  get loop() {
    return this._options.loop;
  }
  get crossOrigin() {
    return this._options.crossOrigin;
  }
  get showUI() {
    return this._options.showUI;
  }
  get playsInline() {
    return this._options.playsInline;
  }
  get preload() {
    return this._options.preload;
  }
  get language() {
    return this._options.language;
  }
  get debug() {
    return this._options.debug;
  }
  get aspectRatio() {
    return this._options.aspectRatio;
  }
  get keyboardNavigation() {
    return this._options.keyboardNavigation;
  }
  get controls() {
    return this._options.controls;
  }
  get nativeControls() {
    return this._options.nativeControls;
  }
  get autoPlay() {
    return this._options.autoPlay;
  }
  get volume() {
    return this._options.volume;
  }
  get startTime() {
    return this._options.startTime;
  }
  get plugins() {
    return this._options.plugins;
  }
  get playbackAdapter() {
    return this._options.playbackAdapter;
  }
  get closedCaptions() {
    return this._options.closedCaptions;
  }
};
let OptionsController = _OptionsController;
__publicField(OptionsController, "defaults", {
  volume: 0.5,
  debug: false,
  aspectRatio: 16 / 9,
  controls: true,
  loop: false,
  showUI: true,
  autoPlay: false,
  playsInline: true,
  crossOrigin: "anonymous",
  nativeControls: false,
  preload: "metadata",
  plugins: {},
  keyboardNavigation: "focus",
  closedCaptions: []
});
class UIController {
  constructor($selector, controller) {
    __publicField(this, "name", "ui_controller");
    const [plugins, setPlugins] = createStore({
      containerPlugins: [],
      controlBarPlugins: []
    });
    this._uiPlugins = plugins;
    this._setUiPlugins = setPlugins;
    render(() => {
      const _self$ = this;
      return createComponent(UIContainer, {
        ref(r$) {
          const _ref$ = _self$.$container;
          typeof _ref$ === "function" ? _ref$(r$) : _self$.$container = r$;
        },
        controller,
        get store() {
          return _self$._uiPlugins;
        }
      });
    }, $selector);
  }
  static create($selector, controller) {
    return new UIController($selector, controller);
  }
  registerUIPlugins(plugins) {
    const containerLocation = [];
    const controlBarLocation = [];
    plugins.forEach((plugin) => {
      if (plugin.renderLocation === UIPluginLocation.CONTAINER) {
        if (!containerLocation.includes(plugin)) {
          containerLocation.push(plugin);
        }
      }
      if (plugin.renderLocation === UIPluginLocation.CONTROL_BAR) {
        if (!controlBarLocation.includes(plugin)) {
          controlBarLocation.push(plugin);
        }
      }
    });
    const containerPlugins = this._uiPlugins.containerPlugins.concat(containerLocation.sort((a, b) => a.order - b.order).map((p2) => p2.render));
    const controlBarPlugins = this._uiPlugins.controlBarPlugins.concat(controlBarLocation.sort((a, b) => a.order - b.order).map((p2) => p2.render));
    this._setUiPlugins("containerPlugins", reconcile(containerPlugins));
    this._setUiPlugins("controlBarPlugins", reconcile(controlBarPlugins));
  }
  dispose() {
    while (this.$container.firstChild) {
      this.$container.removeChild(this.$container.firstChild);
    }
  }
}
function isNativePlaybackMessage(message) {
  return message.type === BridgeMessageType.NATIVE_PLAYBACK;
}
class IFrameBridge {
  constructor() {
    __publicField(this, "bindListeners", (callback) => {
      if (BrowserUtils.isIframe) {
        window.addEventListener("message", ({ data }) => callback(data));
      }
    });
  }
  postMessage(req) {
    if (BrowserUtils.isIframe) {
      window.parent.postMessage(req, "*");
    }
  }
}
function createBridgeEvents() {
  const events = {};
  Object.values(PLAYBACK_EVENT).forEach((value) => {
    events[value] = Event$1.create();
  });
  Object.values(BRIDGE_EVENT).forEach((value) => {
    events[value] = Event$1.create();
  });
  return events;
}
class PlatformBridgeController extends EventController {
  constructor(adapter) {
    super(createBridgeEvents());
    __publicField(this, "callbacks", {});
    __publicField(this, "_playbackState");
    this.adapter = adapter;
    this.adapter.bindListeners(this.onMessage.bind(this));
    this._playbackState = {};
  }
  static create(options) {
    const bridge = options.bridgeAdapter ? new options.bridgeAdapter() : new IFrameBridge();
    return new PlatformBridgeController(bridge);
  }
  async postPlayback(action, args) {
    return this.postRequest({
      type: BridgeMessageType.PLAYBACK,
      args,
      action
    });
  }
  getPlaybackState() {
    return this._playbackState;
  }
  async postRequest(payload) {
    return new Promise((resolve, reject) => {
      const req = __spreadValues({
        id: generateUUID()
      }, payload);
      req.id = generateUUID();
      this.callbacks[req.id] = (res) => {
        if (res.error) {
          reject(res.data);
        } else {
          resolve(res.data);
        }
      };
      this.adapter.postMessage(req);
    });
  }
  onMessage(res) {
    if (isNativePlaybackMessage(res)) {
      console.log("ONMESSSAGE", res);
      this._playbackState = res.data;
      this.emit(res.eventName);
      return;
    }
    if (!res || !res.id)
      return;
    const callback = this.callbacks[res.id];
    if (!callback)
      return;
    callback(res);
    delete this.callbacks[res.id];
  }
}
var BridgeMessageType = /* @__PURE__ */ ((BridgeMessageType2) => {
  BridgeMessageType2["PLAYBACK"] = "PLAYBACK";
  BridgeMessageType2["STORAGE"] = "STORAGE";
  BridgeMessageType2["NATIVE_PLAYBACK"] = "NATIVE_PLAYBACK";
  return BridgeMessageType2;
})(BridgeMessageType || {});
class LocalStorage {
  getItem(key) {
    try {
      const value = localStorage.getItem(key);
      if (!value)
        return null;
      let data = value;
      try {
        data = JSON.parse(value);
      } catch (err) {
        data = value;
      }
      return data;
    } catch (error) {
      return null;
    }
  }
  setItem(key, value) {
    if (!key)
      throw Error("No key provided");
    localStorage.setItem(key, JSON.stringify(value));
  }
  getAllKeys() {
    return Object.keys(localStorage);
  }
  removeItem(key) {
    localStorage.removeItem(key);
  }
  get length() {
    return localStorage.length;
  }
  clear() {
    localStorage.clear();
  }
}
class StorageController {
  constructor(_bridge) {
    __publicField(this, "_adapter");
    __publicField(this, "_bridge");
    __publicField(this, "_namespace", "vxdk");
    this._adapter = new LocalStorage();
    this._bridge = _bridge;
  }
  buildKey(key) {
    return this._namespace + "-" + key;
  }
  static create(bridge) {
    return new StorageController(bridge);
  }
  async bridgeRequest(action, key, value) {
    const args = [];
    if (key)
      args.push(key);
    if (value)
      args.push(value);
    return await this._bridge.postRequest({
      type: BridgeMessageType.STORAGE,
      action,
      args
    });
  }
  async load() {
    const keys = await this.bridgeRequest("getAllKeys");
    if (!keys)
      return;
    for (const key of keys) {
      const value = await this.bridgeRequest("getItem", key);
      this._initialSetItem(key, value);
    }
  }
  _initialSetItem(key, value) {
    this._adapter.setItem(key, value);
  }
  getItem(key) {
    return this._adapter.getItem(this.buildKey(key));
  }
  setItem(key, value) {
    this._adapter.setItem(this.buildKey(key), value);
    this.bridgeRequest("setItem", this.buildKey(key), value);
  }
  removeItem(key) {
    this._adapter.removeItem(this.buildKey(key));
    this.bridgeRequest("removeItem", this.buildKey(key));
  }
  clear() {
    this._adapter.clear();
    this.bridgeRequest("clear");
  }
  getAllKeys() {
    return this._adapter.getAllKeys();
  }
  get length() {
    return this._adapter.length;
  }
}
class Context extends EventController {
  constructor($selector, options) {
    const optionsController = OptionsController.create(options);
    super(createVxdkEvents());
    __publicField(this, "_playback");
    __publicField(this, "bridge");
    __publicField(this, "id");
    __publicField(this, "_controller");
    __publicField(this, "_ui");
    __publicField(this, "_storage");
    __publicField(this, "_plugins", []);
    __publicField(this, "_options");
    __publicField(this, "_loadModules", async () => {
      this._playback = await this._loadPlayback();
      this.emit(CONTEXT_EVENT.PLAYBACK_ADAPTER_LOADED);
      this._plugins = await this._loadPlugins();
      this.emit(CONTEXT_EVENT.PLUGINS_LOADED);
      this._loadStorage();
      this.emit(CONTEXT_EVENT.STORAGE_LOADED);
      this._connect();
      this._ui.registerUIPlugins(this._uiPlugins);
      this.emit(CONTEXT_EVENT.READY);
    });
    __publicField(this, "_loadStorage", async () => {
      await this.storage.load();
    });
    __publicField(this, "_loadPlugins", async () => {
      await PluginRegistry.loadPlugins(this);
      const pluginClasses = PluginRegistry.getPlugins();
      const plugins = pluginClasses.map((pluginClass) => new pluginClass(this));
      return plugins;
    });
    __publicField(this, "_loadPlayback", async () => {
      await PluginRegistry.loadPlayback(this);
      const playbackClass = PluginRegistry.getPlayback(this.options.source);
      const playback = new playbackClass(this);
      return playback;
    });
    __publicField(this, "setOptions", (options) => {
      this._options = OptionsController.create(__spreadValues(__spreadValues({}, this._options), options));
      this.emit(CONTEXT_EVENT.OPTIONS_CHANGED);
      this._reboot();
    });
    __publicField(this, "_connect", () => {
      this.playback.connectedCallback();
      Object.values(this.plugins).forEach((ext) => ext.connectedCallback());
      this.emit(CONTEXT_EVENT.PLUGINS_CONNECTED);
    });
    __publicField(this, "_disconnect", () => {
      this.playback.disconnectedCallback();
      Object.values(this.plugins).forEach((ext) => ext.disconnectedCallback());
      this.emit(CONTEXT_EVENT.PLUGINS_DISCONNECTED);
    });
    __publicField(this, "_reboot", () => {
      this._disconnect();
      FunctionUtils.defer(() => this._connect());
    });
    __publicField(this, "destroy", () => {
      this.emit(CONTEXT_EVENT.DESTROY);
      this._disconnect();
      this._ui.dispose();
      FunctionUtils.defer(() => this.unregisterEvents());
    });
    __publicField(this, "getPluginByName", (name) => {
      const plugin = this._plugins.find((plugin2) => {
        return plugin2.name === name;
      });
      if (!plugin) {
        throw new VxdkError(`No plugin with ${name} found.`);
      }
      return plugin;
    });
    __publicField(this, "getPlugin", (pluginClass) => {
      const plugin = this._plugins.find((plugin2) => {
        return plugin2 instanceof pluginClass;
      });
      if (!plugin) {
        throw new VxdkError(`No plugin with ${pluginClass.name} found`);
      }
      return plugin;
    });
    this.emit(CONTEXT_EVENT.INITIALIZING);
    this.id = generateUUID();
    this._options = optionsController;
    this.bridge = PlatformBridgeController.create(options);
    this._storage = StorageController.create(this.bridge);
    this._controller = Controller.create(this);
    this._ui = UIController.create($selector, this._controller);
    this._loadModules();
  }
  static init(element, options) {
    return new Context(element, options);
  }
  get options() {
    return this._options;
  }
  get storage() {
    return this._storage;
  }
  get controller() {
    return this._controller;
  }
  get playback() {
    return this._playback;
  }
  get $uiContainer() {
    return this._ui.$container;
  }
  get plugins() {
    return this._plugins;
  }
  get _uiPlugins() {
    return this._plugins.filter((plugin) => plugin.type === PluginType.UI_PLUGIN);
  }
  get state() {
    return this.getPlugin(StatePlugin).state;
  }
  get version() {
    return getVersion();
  }
}
var styles = "";
const globalWindow = Window;
globalWindow.VxdkInstances = {};
const Vxdk = {
  init: (el, options) => {
    const context = Context.init(el, options);
    globalWindow.VxdkInstances[context.id] = context.controller;
    return context.controller;
  },
  version: getVersion(),
  getById: (id) => {
    return globalWindow.VxdkInstances[id];
  },
  get: () => {
    return globalWindow.VxdkInstances[Object.keys(globalWindow.VxdkInstances)[0]];
  },
  registerPlugin: PluginRegistry.registerPlugin,
  registerPlayback: PluginRegistry.registerPlayback,
  registerPlaybackLoader: PluginRegistry.registerPlaybackLoader,
  registerPluginLoader: PluginRegistry.registerPluginLoader
};
globalWindow.Vxdk = Vxdk;
export { BrowserUtils as B, Context as C, FunctionUtils as F, HTML5Player as H, MimetypeUtils as M, PLAYBACK_EVENT as P, VXDKDefaultState as V, PlaybackType as a, Playback as b, commonjsGlobal as c, ConversionUtils as d, CONTEXT_EVENT as e, Controller as f, getDefaultExportFromCjs as g, Plugin as h, Vxdk as i };
